import { LoadingOutlined, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import BranchStatusIndicator from "./BranchStatusIndicator";
import { Button, Input, Menu, Popover } from "antd";
import { NotificationInstance } from "antd/lib/notification/interface";
import React from "react";
import { pushInitialCommit, Repo, getBranches } from "../data/Repos";
import { createSession, SessionInfo } from "../data/SolverSession";
import "./NewSessionButton.scss";
import NoBranchesModal from "./NoBranchesModal";
import ResponsiveTooltip from "./ResponsiveTooltip";
import { usePlatform } from "../data/PlatformContext";

interface NewSessionButtonProps {
    repoOrg: string;
    repoName: string;
    repo: Repo;
    onSessionCreated: (sessionInfo: SessionInfo) => Promise<void>;
    notification: NotificationInstance;
    disabled?: boolean;
    branches: string[];
    isLoading?: boolean;
    onBranchesUpdated?: (branches: string[]) => void;
}

const NewSessionButton: React.FC<NewSessionButtonProps> = ({
    repoOrg,
    repoName,
    repo,
    onSessionCreated,
    notification,
    disabled,
    branches,
    isLoading = false,
    onBranchesUpdated,
}) => {
    const { scrollbarStableBothClasses } = usePlatform();

    const [createInProgress, setCreateInProgress] = React.useState<boolean>(false);
    const [isNewSessionPopoverOpen, setIsNewSessionPopoverOpen] = React.useState<boolean>(false);
    const [creatingInitialCommit, setCreatingInitialCommit] = React.useState<boolean>(false);
    const [showNoBranchesModal, setShowNoBranchesModal] = React.useState<boolean>(false);
    const [branchFilter, setBranchFilter] = React.useState<string>("");

    const filteredBranches = React.useMemo(() => {
        return branches.filter((branch) => branch.toLowerCase().includes(branchFilter.toLowerCase()));
    }, [branches, branchFilter]);

    const [isFetchingBranches, setIsFetchingBranches] = React.useState(false);
    const [branchFetchComplete, setBranchFetchComplete] = React.useState(false);

    // Trigger branch fetch when popover opens (if we already have branches)
    React.useEffect(() => {
        if (!isNewSessionPopoverOpen || !repo || isLoading || branches.length === 0) {
            return;
        }

        const refreshBranches = async () => {
            if (!repo) return;

            setIsFetchingBranches(true);
            try {
                const latestBranches = await getBranches(repo.org, repo.name, 1000);
                if (onBranchesUpdated) {
                    onBranchesUpdated(latestBranches);
                }
                setBranchFetchComplete(true);
            } catch (error) {
                console.error("Failed to fetch latest branches:", error);
                notification.error({
                    message: "Failed to refresh branches",
                    description: error instanceof Error ? error.message : "An unknown error occurred",
                    placement: "bottomRight",
                });
            } finally {
                setIsFetchingBranches(false);
            }
        };

        refreshBranches();
    }, [isNewSessionPopoverOpen, repo, isLoading, branches.length, notification, onBranchesUpdated]);

    // Reset states when popover closes
    React.useEffect(() => {
        if (!isNewSessionPopoverOpen) {
            setBranchFetchComplete(false);
        }
    }, [isNewSessionPopoverOpen]);

    React.useEffect(() => {
        if (branches.length > 0) {
            setShowNoBranchesModal(false);
        } else if (repo && !isLoading && branchFetchComplete) {
            setShowNoBranchesModal(true);
        }
    }, [branches, isLoading, repo, branchFetchComplete]);

    const createNewSession = async (branch: string) => {
        setCreateInProgress(true);
        try {
            const result = await createSession(repoOrg, repoName, branch);
            if (result?.session) {
                await onSessionCreated(result.session.getInfo());
            } else {
                notification.error({
                    message: "Failed to create session",
                    description: `Could not create a new session from branch ${branch}`,
                    placement: "bottomRight",
                });
            }
        } catch (error: unknown) {
            notification.error({
                message: "Failed to create session",
                description: error instanceof Error ? error.toString() : "Unknown error",
                placement: "bottomRight",
            });
        } finally {
            setCreateInProgress(false);
            setIsNewSessionPopoverOpen(false);
        }
    };

    const buildNewSessionList = () => {
        const handleMenuClick = ({ key }: { key: string }) => {
            if (key !== "no-results") {
                createNewSession(key);
            }
        };

        if (isLoading) {
            return (
                <div className="new-session-loading-branches">
                    Loading branches...
                    <LoadingOutlined />
                </div>
            );
        }

        return (
            <div className="new-session-dropdown-wrapper">
                <div className="new-session-instructions">
                    Select a branch to use as the base revision of the new session
                </div>
                <Input
                    className="new-session-dropdown-input"
                    placeholder="Search branches"
                    value={branchFilter}
                    addonBefore={<SearchOutlined />}
                    onChange={(e) => setBranchFilter(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" && filteredBranches.length === 1) {
                            createNewSession(filteredBranches[0]);
                        }
                    }}
                />
                {branches.length > 0 && (
                    <BranchStatusIndicator isFetching={isFetchingBranches} fetchComplete={branchFetchComplete} />
                )}
                <Menu
                    onClick={handleMenuClick}
                    className={`new-session-dropdown-menu ${scrollbarStableBothClasses}`}
                    selectable={false}
                >
                    {filteredBranches.length === 0 ? (
                        <Menu.Item key="no-results" disabled>
                            No matching branches
                        </Menu.Item>
                    ) : (
                        <>
                            {repo.default_branch && filteredBranches.includes(repo.default_branch) && (
                                <>
                                    <Menu.Item key={repo.default_branch}>
                                        <div className="new-session-dropdown-menu-item-inner">
                                            <div>{repo.default_branch}</div>
                                            <div className="new-session-default-branch-badge">default</div>
                                        </div>
                                    </Menu.Item>
                                    <Menu.Divider />
                                </>
                            )}
                            {filteredBranches
                                .filter((branch) => branch !== repo.default_branch)
                                .map((branch) => (
                                    <Menu.Item key={branch}>
                                        <div className="new-session-dropdown-menu-item-inner">
                                            <div>{branch}</div>
                                        </div>
                                    </Menu.Item>
                                ))}
                        </>
                    )}
                </Menu>
            </div>
        );
    };

    const handleCreateInitialCommit = async () => {
        setCreatingInitialCommit(true);
        try {
            await pushInitialCommit(repoOrg, repoName);
            setShowNoBranchesModal(false);
            notification.success({
                message: "Initial commit created",
                description: `You can now Solve in ${repoName}`,
                placement: "bottomRight",
            });
        } catch (error) {
            notification.error({
                message: "Failed to create initial commit",
                description: error instanceof Error ? error.message : "An unknown error occurred",
                placement: "bottomRight",
            });
        } finally {
            setCreatingInitialCommit(false);
        }
    };

    if (!repoOrg || !repoName || disabled) {
        return (
            <ResponsiveTooltip title="No branches available" arrow={false} placement="right">
                <Button icon={<PlusCircleOutlined />} disabled />
            </ResponsiveTooltip>
        );
    }

    return (
        <>
            <Popover
                placement="bottomLeft"
                trigger="click"
                content={buildNewSessionList()}
                arrow={false}
                open={isNewSessionPopoverOpen}
                onOpenChange={(open) => setIsNewSessionPopoverOpen(open)}
            >
                <ResponsiveTooltip title="Create new session" arrow={false} placement="right">
                    <Button icon={<PlusCircleOutlined />} loading={createInProgress} />
                </ResponsiveTooltip>
            </Popover>
            <NoBranchesModal
                isOpen={showNoBranchesModal}
                onClose={() => setShowNoBranchesModal(false)}
                onCreateInitialCommit={handleCreateInitialCommit}
                isCreating={creatingInitialCommit}
            />
        </>
    );
};

export default NewSessionButton;
