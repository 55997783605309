import React, { createContext, useContext, useEffect, useState } from "react";

import { useSolverInterfaceContext } from "./SolverInterface";
import { solverInterfaceApiAxios } from "./SolverInterfaceConstants";
import {
    SolverInterfaceEvent,
    SolverInterfaceEventType,
    TutorialAction,
    TutorialNuggetEvent,
} from "./SolverInterfaceEvent";

interface TutorialActionContextType {
    currentTutorialAction: TutorialAction | undefined;
    closeTutorialAction: (tutorialAction: TutorialAction, followUp?: TutorialAction) => void;
}

const NON_ACKLOWLEDGED_TUTORIAL_ACTIONS: TutorialAction[] = [TutorialAction.HIGHLIGHT_SUBSCRIPTION_MENU_OPTION];

const TutorialActionContext = createContext<TutorialActionContextType | undefined>(undefined);

export const TutorialActionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [tutorialActionsQueue, setTutorialActionsQueue] = useState<TutorialAction[]>([]);

    const currentTutorialAction = tutorialActionsQueue.length > 0 ? tutorialActionsQueue[0] : undefined;

    const { addSolverInterfaceEventObserver, removeSolverInterfaceEventObserver } = useSolverInterfaceContext();

    useEffect(() => {
        const handle = addSolverInterfaceEventObserver(
            SolverInterfaceEventType.TUTORIAL_NUGGET,
            (event: SolverInterfaceEvent) => {
                const tutorialNuggetEvent = event as TutorialNuggetEvent;

                const tutorialAction = tutorialNuggetEvent.tutorial_action;

                setTutorialActionsQueue((prev) => [...prev, tutorialAction]);
            }
        );

        return () => removeSolverInterfaceEventObserver(handle);
    }, []);

    useEffect(() => {
        if (!currentTutorialAction) return;
        if (NON_ACKLOWLEDGED_TUTORIAL_ACTIONS.includes(currentTutorialAction)) return;

        solverInterfaceApiAxios
            .patch(`/tutorial/${currentTutorialAction}`, {
                action: currentTutorialAction,
            })
            .catch((e) => {
                console.error("Failed to acknowledge tutorial action", e);
            });
    }, [currentTutorialAction]);

    const closeTutorialAction = (tutorialAction: TutorialAction, followUp?: TutorialAction) => {
        setTutorialActionsQueue((prev) => {
            const filteredPrev = prev.filter((action) => action !== tutorialAction);

            return followUp ? [followUp, ...filteredPrev] : filteredPrev;
        });
    };

    return (
        <TutorialActionContext.Provider
            value={{
                currentTutorialAction,
                closeTutorialAction,
            }}
        >
            {children}
        </TutorialActionContext.Provider>
    );
};

export const useTutorialActions = () => {
    const context = useContext(TutorialActionContext);
    if (context === undefined) {
        throw new Error("useTutorialActions must be used within a TutorialActionsProvider");
    }
    return context;
};
