import { LoadingOutlined } from "@ant-design/icons";
import { Select } from "antd";
import React from "react";
import { Repo } from "../data/Repos";
import BranchStatusIndicator from "./BranchStatusIndicator";
import "./BranchDropdownSelector.scss";

interface BranchDropdownSelectorProps {
    repo: Repo | null;
    branches: string[];
    isLoading?: boolean;
    onBranchSelect: (branch: string) => void;
    instructions?: string;
    value?: string;
    isFetchingBranches?: boolean;
    branchFetchComplete?: boolean;
}

const BranchDropdownSelector: React.FC<BranchDropdownSelectorProps> = ({
    repo,
    branches,
    isLoading = false,
    onBranchSelect,
    instructions = "Select a branch",
    value,
    isFetchingBranches = false,
    branchFetchComplete = false,
}) => {
    const options = React.useMemo(() => {
        const defaultBranch = repo?.default_branch;
        const options = [];

        // Add default branch first if it exists
        if (defaultBranch && branches.includes(defaultBranch)) {
            options.push({
                label: (
                    <div className="branch-selector-option">
                        <span>{defaultBranch}</span>
                        <span className="branch-selector-default-badge">default</span>
                    </div>
                ),
                value: defaultBranch,
            });
        }

        // Add remaining branches in API order
        branches
            .filter((branch) => branch !== defaultBranch)
            .forEach((branch) =>
                options.push({
                    label: (
                        <div className="branch-selector-option">
                            <span>{branch}</span>
                        </div>
                    ),
                    value: branch,
                })
            );

        return options;
    }, [branches, repo?.default_branch]);

    if (isLoading) {
        return (
            <div className="branch-selector-loading">
                Loading branches...
                <LoadingOutlined />
            </div>
        );
    }

    return (
        <div className="branch-selector-wrapper">
            <div className="branch-selector-instructions">{instructions}</div>
            {branches.length > 0 && (
                <BranchStatusIndicator
                    isFetching={isFetchingBranches || false}
                    fetchComplete={branchFetchComplete || false}
                />
            )}
            <Select
                className="branch-selector-select"
                placeholder="Search or select a branch"
                loading={isLoading}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                    String(option?.label || "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                }
                options={options}
                onChange={onBranchSelect}
                notFoundContent="No matching branches"
            />
        </div>
    );
};

export default BranchDropdownSelector;
