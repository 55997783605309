import { Typography, Card } from "antd";

import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { formatDistanceToNow } from "date-fns";

import ResponsiveTooltip from "./ResponsiveTooltip";

import { User } from "../data/User";
import AvatarVariantFactory from "../data/AvatarVariantFactory";

import "./SessionCard.scss";
import { Project } from "../data/SolverProjects";

import { DeleteOutlined } from "@ant-design/icons";

interface ProjectCardProps {
    currentUser?: User;
    project: Project;
    active: boolean;
    onDelete: () => void;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ currentUser, project, active, onDelete }) => {
    const [timeAgoMessage, setTimeAgoMessage] = useState<string>(
        formatDistanceToNow(new Date(project.created_at * 1000), { addSuffix: true })
    );

    useEffect(() => {
        const interval = setInterval(
            () => setTimeAgoMessage(formatDistanceToNow(new Date(project.created_at * 1000), { addSuffix: true })),
            5000
        );
        return () => clearInterval(interval);
    }, [project.created_at]);

    const buildTitleArea = () => {
        // TODO: API should return non nullable branch name and base revision eventually
        const branch_name = project.user_branch_name ? project.user_branch_name : "main";
        const base_revision = project.base_revision ? project.base_revision.slice(0, 7) : "0000000";
        let tooltipText = `${branch_name} @ ${base_revision}`;
        return (
            <div className="session-card-title-area">
                <div className="session-card-title-content">
                    <div className="session-card-title">{buildTitle()}</div>
                    <div className="session-card-branch-area">
                        <ResponsiveTooltip title={tooltipText} arrow={false} placement="topLeft">
                            <small className="session-card-branch-name">{branch_name}</small>
                        </ResponsiveTooltip>
                    </div>
                </div>
            </div>
        );
    };

    const buildTitle = () => {
        const titleTextclass = classNames({
            "session-card-title-text-inactive": !active,
            "session-card-title-text-active": active,
        });

        return (
            <ResponsiveTooltip title={project.name} placement="top" arrow={false}>
                <Typography.Text className={titleTextclass}>{project.name}</Typography.Text>
            </ResponsiveTooltip>
        );
    };

    const buildCardDescription = () => {
        const avatarUrl = AvatarVariantFactory.createURLVariant(project.user_avatar_url, project.provider, 40);

        return (
            <div className="session-card-description">
                <span className="session-card-author">
                    <img className="session-card-author-icon" src={avatarUrl} alt={project.user_name} />
                    <small>{project.user_name}</small>
                </span>
                <small className="session-card-timestamp">Updated {timeAgoMessage}</small>
            </div>
        );
    };

    const buildDeleteButton = () => {
        // TODO: check if planning session is deletable instead of just checking for the 'planning' execution state
        //  update tool tip based if deletion is disable based on planning or executing
        const canDelete = project.execution_status === "planning" || project.execution_status === "ready";
        const tooltipMessage = canDelete ? "Delete" : "Cannot delete project in this state";
        const buttonClass = classNames({
            "session-card-hover": true,
            "session-card-hover-disabled": !canDelete,
        });
        return (
            <div className="session-card-owner-actions">
                <ResponsiveTooltip title={tooltipMessage} arrow={false} placement="right">
                    <div
                        className={buttonClass}
                        onClick={(e) => {
                            if (canDelete) {
                                onDelete();
                            }
                            e.stopPropagation();
                        }}
                    >
                        <DeleteOutlined />
                    </div>
                </ResponsiveTooltip>
            </div>
        );
    };

    const cardClasses = classNames({
        "session-card": true,
        "session-card-active": active,
    });

    return (
        <Card size="small" className={cardClasses} title={buildTitleArea()}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "8px" }}>
                {buildCardDescription()}
                {buildDeleteButton()}
            </div>
        </Card>
    );
};

export default ProjectCard;
