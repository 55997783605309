import { useMemo } from "react";

import { ChangeData, getChangeKey } from "react-diff-view";

import { Comment, getRelevantPath } from "../components/Utils";
import { PlanningAnswer } from "./SolverProjects";

export const PROMPT_MAX_LENGTH = 5_000;

export const usePrompt = (
    currentBody: string,
    comments: Comment[],
    planningAnswers: PlanningAnswer[]
): {
    promptExceedsMaxLengthMessage: string;
    // Test whether a prompt body exceeds the max length, taking into account
    // comments.
    doesTestPromptExceedMaxLength: (body: string) => boolean;
} => {
    const formattedCommentsLength = useMemo(() => {
        return Prompt.formatPrompt("", comments).length;
    }, [comments]);

    const promptLength = Prompt.computePromptLength(currentBody.length, formattedCommentsLength, planningAnswers);

    const promptExceedsMaxLength = promptLength > PROMPT_MAX_LENGTH;

    let promptExceedsMaxLengthMessage = "";
    if (promptExceedsMaxLength) {
        promptExceedsMaxLengthMessage = `Input is too long - ${promptLength}/${PROMPT_MAX_LENGTH}.`;

        if (/```diff\s/.test(currentBody)) {
            promptExceedsMaxLengthMessage += " It looks like it may include comments.";
        } else if (comments.length > 0) {
            if (currentBody.trim() === "") {
                promptExceedsMaxLengthMessage += " This is due to comments.";
            } else {
                promptExceedsMaxLengthMessage += " This is partially due to comments.";
            }
        } else if (planningAnswers.length > 0) {
            if (currentBody.trim() === "") {
                promptExceedsMaxLengthMessage += " This is due to planning questions.";
            } else {
                promptExceedsMaxLengthMessage += " This is partially due to planning questions.";
            }
        }
    }

    const doesTestPromptExceedMaxLength = (body: string) => {
        return Prompt.computePromptLength(body.length, formattedCommentsLength, planningAnswers) > PROMPT_MAX_LENGTH;
    };

    return { promptExceedsMaxLengthMessage, doesTestPromptExceedMaxLength };
};

type TrimmedCommentChanges = {
    trimmedChanges: ChangeData[];
    didTrimToHunkStart: boolean;
    didTrimToHunkEnd: boolean;
};

export class Prompt {
    static PROMPT_COMMENTS_PREAMBLE = "You've received feedback on your changes. Here are the engineer's comments:\n\n";
    static PROMPT_COMMENTS_POSTAMBLE = "\nAdditional instruction from the engineer: ";

    static formatPrompt = (body: string, comments: Comment[]): string => {
        let formattedPrompt = "";
        if (comments.length > 0) {
            formattedPrompt += this.PROMPT_COMMENTS_PREAMBLE;
            formattedPrompt += comments.map((comment) => this.formatCodeCommentPrompt(comment)).join("\n\n");

            if (body.trim() !== "") {
                formattedPrompt += this.PROMPT_COMMENTS_POSTAMBLE;
            }
        }

        formattedPrompt += body;

        return formattedPrompt;
    };

    static computePromptLength = (
        bodyLength: number,
        formattedCommentsLength: number,
        planningAnswers: PlanningAnswer[]
    ): number => {
        let promptLength = bodyLength;

        if (formattedCommentsLength > 0) {
            promptLength +=
                this.PROMPT_COMMENTS_PREAMBLE.length + formattedCommentsLength + this.PROMPT_COMMENTS_POSTAMBLE.length;
        }

        // Add the length of the planning questions/answers.
        promptLength += planningAnswers.reduce((acc, answer) => acc + answer.question.length + answer.answer.length, 0);

        return promptLength;
    };

    private static formatCodeCommentPrompt = (comment: Comment): string => {
        const commentLine = comment.change;
        const lineNumber = commentLine.type === "normal" ? commentLine.newLineNumber : commentLine.lineNumber;

        let formatted = "";

        formatted += `${getRelevantPath(comment.fileData)}:${lineNumber}\n`;

        const trimmedCommentChanges = this.trimCommentHunk(comment);

        // Code diff, with 5 lines of context before and after the comment line.
        // ... is used to indicate that there are more lines before/after in the hunk.
        formatted += `\n\`\`\`diff\n`;

        if (!trimmedCommentChanges.didTrimToHunkStart) {
            formatted += `...\n`;
        }

        const diffLines = trimmedCommentChanges.trimmedChanges.map((change) => {
            if (change.type === "insert") {
                return `+${change.content}`;
            } else if (change.type === "delete") {
                return `-${change.content}`;
            } else {
                return ` ${change.content}`;
            }
        });

        formatted += `${diffLines.join("\n")}\n`;

        if (!trimmedCommentChanges.didTrimToHunkEnd) {
            formatted += `...\n`;
        }

        formatted += `\`\`\`\n`;

        formatted += `Comment: ${comment.text}\n`;

        return formatted;
    };

    // Truncates a Comment's hunk to show the comment line and at most 5 lines before and after it.
    private static trimCommentHunk = (comment: Comment): TrimmedCommentChanges => {
        const commentLine = comment.change;
        const hunk = comment.hunk;

        // We need to find the index of the |commentLine| in the |hunk.changes| array.
        const changeIndex = hunk.changes.findIndex(
            (change: ChangeData) => getChangeKey(change) === getChangeKey(commentLine)
        );

        // If the line isn't in this hunk, we can't continue.
        if (changeIndex === -1) {
            throw new Error("Comment line not found in hunk");
        }

        const lowLine = Math.max(changeIndex - 5, 0);
        const highLine = Math.min(changeIndex + 5, hunk.changes.length - 1);

        return {
            trimmedChanges: hunk.changes.slice(lowLine, highLine + 1),
            didTrimToHunkStart: lowLine === 0,
            didTrimToHunkEnd: highLine === hunk.changes.length - 1,
        };
    };
}
