import React, { useState } from "react";
import { Modal, Spin, Typography } from "antd";
import { DownOutlined, LoadingOutlined, UpOutlined, FileImageOutlined } from "@ant-design/icons";
import { useSolverInterfaceContext } from "../../data/SolverInterface";
import { getImageAttachment, getAttachmentContent } from "../../data/AttachmentStoreInterface";
import "./ImageAttachment.scss";

interface ImageAttachmentProps {
    attachmentId: string;
}

const ImageAttachment: React.FC<ImageAttachmentProps> = ({ attachmentId }) => {
    const [imageData, setImageData] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const { activeRepo } = useSolverInterfaceContext();

    const loadImage = async () => {
        if (isLoading || !activeRepo) return;

        setError(null);
        setIsLoading(true);

        try {
            const attachment = await getImageAttachment(activeRepo.org, activeRepo.name, attachmentId);
            const base64Content = getAttachmentContent(attachment);
            if (!base64Content) {
                throw new Error("Image content not available");
            }

            setImageData(base64Content);
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : "Unknown error";
            setError(`Failed to load image: ${errorMessage}`);
            console.error("Image loading error:", {
                attachmentId,
                error: errorMessage,
                details: error,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isFullImageVisible, setIsFullImageVisible] = useState(false);

    const handleExpand = () => {
        setIsCollapsed(false);
        if (!imageData && !isLoading) {
            loadImage();
        }
    };

    return (
        <div className="image-attachment-container">
            <div
                className="image-attachment-header"
                onClick={() => (isCollapsed ? handleExpand() : setIsCollapsed(true))}
            >
                <Typography.Text type="secondary">
                    <FileImageOutlined style={{ marginRight: 8 }} />
                    Image attachments
                </Typography.Text>
                <div className="image-attachment-collapse-icon">{isCollapsed ? <DownOutlined /> : <UpOutlined />}</div>
            </div>

            <div className={`image-attachment-content ${!isCollapsed ? "expanded" : ""}`}>
                {imageData ? (
                    <div
                        className="image-attachment-thumbnail"
                        onClick={() => setIsFullImageVisible(true)}
                        title="Click to view full image"
                    >
                        <img src={imageData} alt="Attachment" />
                    </div>
                ) : (
                    <div className="image-attachment-loading">
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    </div>
                )}
            </div>

            <Modal
                open={isFullImageVisible}
                onCancel={() => setIsFullImageVisible(false)}
                footer={null}
                width="auto"
                className="image-attachment-modal"
                centered
            >
                {imageData && <img src={imageData} alt="Attachment" className="image-attachment-full" />}
            </Modal>

            {error && <div className="image-attachment-error">{error}</div>}
        </div>
    );
};

export default ImageAttachment;
