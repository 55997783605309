import {
    LoadingOutlined,
    CheckCircleOutlined,
    ContainerOutlined,
    ClockCircleOutlined,
    ExclamationCircleOutlined,
    DeleteOutlined,
    EyeOutlined,
    EyeInvisibleOutlined,
    MenuOutlined,
    ExportOutlined,
    QuestionCircleOutlined,
} from "@ant-design/icons";
import { Typography, Card, Dropdown, Modal, Form, Input, Switch, notification } from "antd";

import React, { useEffect, useState, MouseEvent, SyntheticEvent } from "react";
import classNames from "classnames";
import { formatDistanceToNow } from "date-fns";
import { useExecutionSettingsContext } from "../data/ExecutionSettings";

import ResponsiveTooltip from "./ResponsiveTooltip";

import AvatarVariantFactory from "../data/AvatarVariantFactory";
import { Session, sessionIsLoading, SessionStatus, useTurns } from "../data/SolverSession";
import { SessionVisibility, User } from "../data/User";

import { solverInterfaceApiAxios } from "../data/SolverInterfaceConstants";
import axios from "axios";
import "./SessionCard.scss";

interface ExportImageFormData {
    name: string;
    description?: string;
    is_public: boolean;
}

interface SessionCardProps {
    currentUser?: User;
    session: Session;
    active: boolean;
    onDelete: () => void;
    onUpdateVisibility: (visibility: SessionVisibility) => Promise<boolean>;
    onExportSuccess?: () => void;
}

const SessionCard: React.FC<SessionCardProps> = ({
    currentUser,
    session,
    active,
    onDelete,
    onUpdateVisibility,
    onExportSuccess,
}) => {
    const [timeAgoMessage, setTimeAgoMessage] = useState<string>(
        formatDistanceToNow(new Date(session.modify_timestamp * 1000), { addSuffix: true })
    );
    const [exportModalVisible, setExportModalVisible] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [exportError, setExportError] = useState<string | null>(null);
    const [form] = Form.useForm<ExportImageFormData>();
    const [api, contextHolder] = notification.useNotification();
    const { refreshAvailableImages } = useExecutionSettingsContext();
    const turns = useTurns();

    useEffect(() => {
        const interval = setInterval(
            () =>
                setTimeAgoMessage(formatDistanceToNow(new Date(session.modify_timestamp * 1000), { addSuffix: true })),
            5000
        );
        return () => clearInterval(interval);
    }, [session.modify_timestamp]);
    const getStatusIcon = () => {
        switch (session.status) {
            case SessionStatus.READY:
                return <CheckCircleOutlined className="session-status-icon" />;
            case SessionStatus.PENDING:
                return <ClockCircleOutlined className="session-status-icon" />;
            case SessionStatus.SOLVING:
            case SessionStatus.SUBMITTING_CANCEL:
            case SessionStatus.SUBMITTING_SOLVE:
                return <LoadingOutlined className="session-status-icon session-status-icon-loading" />;
            default:
                return <ExclamationCircleOutlined className="session-status-icon" />;
        }
    };

    const getStatusTooltip = () => {
        switch (session.status) {
            case SessionStatus.READY:
                return "Ready to solve";
            case SessionStatus.PENDING:
                return "Pending";
            case SessionStatus.SUBMITTING_SOLVE:
                return "Submitting";
            case SessionStatus.SOLVING:
                return "Solving";
            case SessionStatus.SUBMITTING_CANCEL:
                return "Cancelling";
            default:
                return "Unknown status";
        }
    };

    const buildTitleArea = () => {
        return (
            <div className="session-card-title-area">
                <div className="session-card-title-content">
                    <div className="session-card-title">{buildTitle()}</div>
                    <div className="session-card-branch-area">
                        <ResponsiveTooltip
                            title={`${session.branch_name} @ ${session.base_revision.slice(0, 7)}`}
                            arrow={false}
                            placement="topLeft"
                        >
                            <small className="session-card-branch-name">{session.branch_name}</small>
                        </ResponsiveTooltip>
                        {session.remote_branch_name && (
                            <>
                                &larr;
                                <ResponsiveTooltip title={session.remote_branch_name} arrow={false} placement="topLeft">
                                    <small className="session-card-branch-name">{session.remote_branch_name}</small>
                                </ResponsiveTooltip>
                            </>
                        )}
                    </div>
                </div>
                {buildMenuIcon()}
            </div>
        );
    };

    const buildTitle = () => {
        const titleTextclass = classNames({
            "session-card-title-text-inactive": !active,
            "session-card-title-text-active": active,
        });

        return (
            <ResponsiveTooltip title={session.title} placement="top" arrow={false}>
                <Typography.Text className={titleTextclass}>{session.title}</Typography.Text>
            </ResponsiveTooltip>
        );
    };

    const handleExportSubmit = async () => {
        try {
            setIsExporting(true);
            setExportError(null);

            const values = await form.validateFields();
            const { org, repo, session_id } = session.getInfo();
            await solverInterfaceApiAxios.post(`/${org}/${repo}/${session_id}/execution/image/export`, values);
            setExportModalVisible(false);
            form.resetFields();
            api.success({
                message: "Export Successful",
                description: "The execution image has been exported successfully.",
                placement: "bottomRight",
            });
            await refreshAvailableImages();
            if (onExportSuccess) {
                onExportSuccess();
            }
        } catch (error) {
            console.error("Export failed:", error);
            if (axios.isAxiosError(error) && error.response?.status === 404) {
                setExportError(
                    "No image available for this session. An image will be available after code was executed in the session."
                );
            } else {
                setExportError("Export failed.");
            }
        } finally {
            setIsExporting(false);
        }
    };

    const buildMenuIcon = () => {
        if (currentUser?.id !== session.user_id) {
            return null;
        }

        const menuItems = [
            {
                key: "visibility",
                label: "Visibility",
                icon: session.visibility === SessionVisibility.PRIVATE ? <EyeInvisibleOutlined /> : <EyeOutlined />,
                children: [
                    {
                        key: SessionVisibility.PRIVATE,
                        label: "Private",
                        className:
                            session.visibility === SessionVisibility.PRIVATE ? "ant-dropdown-menu-item-selected" : "",
                    },
                    {
                        key: SessionVisibility.PUBLIC_READ_ONLY,
                        label: "Public (Read Only)",
                        className:
                            session.visibility === SessionVisibility.PUBLIC_READ_ONLY
                                ? "ant-dropdown-menu-item-selected"
                                : "",
                    },
                    {
                        key: SessionVisibility.PUBLIC_READ_WRITE,
                        label: "Public (Read/Write)",
                        className:
                            session.visibility === SessionVisibility.PUBLIC_READ_WRITE
                                ? "ant-dropdown-menu-item-selected"
                                : "",
                    },
                ],
            },
            {
                key: "export",
                label: "Export Execution Image",
                icon: <ExportOutlined />,
                onClick: () => setExportModalVisible(true),
                disabled: !turns?.length,
            },
        ];

        return (
            <>
                <div className="session-card-owner-actions">
                    <Dropdown
                        disabled={!session.allowModification(currentUser?.id)}
                        menu={{
                            items: menuItems,
                            onClick: (info) => {
                                info.domEvent.stopPropagation();
                                const { keyPath } = info;
                                if (keyPath.length === 2 && keyPath[1] === "visibility") {
                                    handleVisibilityChange(keyPath[0] as SessionVisibility);
                                }
                            },
                        }}
                        trigger={["click"]}
                    >
                        <div className="session-card-action-button" onClick={(e: MouseEvent) => e.stopPropagation()}>
                            <MenuOutlined />
                        </div>
                    </Dropdown>
                </div>

                <Modal
                    title="Export Execution Image"
                    open={exportModalVisible}
                    confirmLoading={isExporting}
                    okText="Export"
                    onOk={handleExportSubmit}
                    onCancel={() => {
                        setExportModalVisible(false);
                        setExportError(null);
                        form.resetFields();
                    }}
                    maskClosable={false}
                >
                    {exportError && (
                        <Typography.Text type="danger" style={{ display: "block", marginBottom: "16px" }}>
                            {exportError}
                        </Typography.Text>
                    )}
                    <Form
                        form={form}
                        layout="vertical"
                        initialValues={{ is_public: false }}
                        onClick={(e: SyntheticEvent) => e.stopPropagation()}
                    >
                        <Form.Item
                            name="name"
                            label={
                                <span>
                                    Name{" "}
                                    <ResponsiveTooltip title="A name for your exported image">
                                        <QuestionCircleOutlined />
                                    </ResponsiveTooltip>
                                </span>
                            }
                            rules={[{ required: true, message: "Please input a name!" }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="description"
                            label={
                                <span>
                                    Description{" "}
                                    <ResponsiveTooltip title="A description for your exported image">
                                        <QuestionCircleOutlined />
                                    </ResponsiveTooltip>
                                </span>
                            }
                        >
                            <Input.TextArea />
                        </Form.Item>
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            <span>Public</span>
                            <ResponsiveTooltip title="Public images can be used by all users with access to the repo">
                                <QuestionCircleOutlined />
                            </ResponsiveTooltip>
                            <Form.Item name="is_public" valuePropName="checked" style={{ margin: 0 }}>
                                <Switch />
                            </Form.Item>
                        </div>
                    </Form>
                </Modal>
            </>
        );
    };

    const buildCardDescription = () => {
        const avatarUrl = AvatarVariantFactory.createURLVariant(session.user_avatar_url, session.auth_type, 40);

        return (
            <div className="session-card-description">
                <span className="session-card-author">
                    <img className="session-card-author-icon" src={avatarUrl} alt={session.user_name} />
                    <small>{session.user_name}</small>
                </span>
                <small className="session-card-timestamp">Updated {timeAgoMessage}</small>
            </div>
        );
    };

    const handleVisibilityChange = async (value: SessionVisibility) => {
        const success = await onUpdateVisibility(value);
        if (!success) {
            console.error("Failed to update visibility");
        }
    };

    const buildActionButton = () => {
        const isTaskSession = session.project_task_id !== null;
        const sessionIsQuiescent = !sessionIsLoading(session.status);
        const canDelete = sessionIsQuiescent && !isTaskSession;

        const buttonClass = classNames({
            "session-card-hover": true,
            "session-card-hover-disabled": !canDelete,
        });

        return currentUser?.id === session.user_id
            ? buildOwnerActions(canDelete, isTaskSession, sessionIsQuiescent, buttonClass)
            : null;
    };

    const buildOwnerActions = (
        canDelete: boolean,
        isTaskSession: boolean,
        sessionIsQuiescent: boolean,
        buttonClass: string
    ) => {
        let tooltipMessage = "Delete";
        if (isTaskSession) {
            tooltipMessage = "Delete task session in project";
        } else if (!sessionIsQuiescent) {
            tooltipMessage = "Cannot delete while solving";
        }

        return (
            <div className="session-card-owner-actions">
                <ResponsiveTooltip title={tooltipMessage} arrow={false} placement="right">
                    <div
                        className={buttonClass}
                        onClick={(e) => {
                            if (canDelete) {
                                onDelete();
                            }

                            e.stopPropagation();
                        }}
                    >
                        <DeleteOutlined />
                    </div>
                </ResponsiveTooltip>
            </div>
        );
    };

    const cardClasses = classNames({
        "session-card": true,
        "session-card-active": active,
    });

    return (
        <>
            {contextHolder}
            <Card size="small" className={cardClasses} title={buildTitleArea()}>
                <div className="session-card-body">
                    <ResponsiveTooltip title={getStatusTooltip()} placement="right">
                        {getStatusIcon()}
                    </ResponsiveTooltip>
                    {buildCardDescription()}
                    {buildActionButton()}
                </div>
            </Card>
        </>
    );
};

export default SessionCard;
