import React, { createContext, useEffect } from "react";

import { useSolverInterfaceContext, ViewMode } from "./SolverInterface";
import { ProjectInfo, useLoadProject } from "./SolverProjects";
import { SessionInfo, useLoadSession } from "./SolverSession";
import { Repo } from "./Repos";

interface AppPath {
    repo: string | undefined;
    session: string | undefined;
    project: string | undefined;
    viewMode: ViewMode;
    plan: string | undefined;
}

const parseAppPath = (path: string, search: string): AppPath => {
    const parts = path.split("/").filter((part) => part.length > 0);
    const searchParams = new URLSearchParams(search);
    const plan = searchParams.get("plan") || undefined;
    let repo = undefined;
    let viewMode = ViewMode.SESSIONS; // Default view mode
    let session = undefined;
    let project = undefined;
    if (parts.length > 0) {
        if (parts.length > 1) {
            repo = `${parts[0]}/${parts[1]}`;
        } else {
            repo = parts[0];
        }
    }

    // Parse view mode and identifiers
    if (parts.length > 2) {
        if (parts[2] === ViewMode.PROJECTS) {
            viewMode = ViewMode.PROJECTS;
            project = parts.length > 3 ? parts[3] : undefined;
        } else if (parts[2] === ViewMode.SESSIONS) {
            viewMode = ViewMode.SESSIONS;
            session = parts.length > 3 ? parts[3] : undefined;
        } else {
            // Legacy URL format: /{org}/{repo}/{session_id}
            session = parts[2];
        }
    }

    return { repo, session, project, viewMode, plan };
};

interface NavigationContextType {}

const nullNavigationContext: NavigationContextType = {};

const NavigationContext = createContext<NavigationContextType>(nullNavigationContext);

const NavigationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { repos, setActiveRepo, setViewMode, loadingAdditionalRepos, onRepoNotFound } = useSolverInterfaceContext();
    const loadSession = useLoadSession();
    const loadProject = useLoadProject();

    useEffect(() => {
        const onPopState = () => {
            const pathConstituents = parseAppPath(document.location.pathname, document.location.search);

            const repoFullName = pathConstituents.repo;

            if (loadingAdditionalRepos) return;

            const repo = repos.find((repo: Repo) => repo.full_name === repoFullName);
            if (repo) {
                setActiveRepo(repo, NavigationBehavior.NONE);

                if (pathConstituents.viewMode === ViewMode.SESSIONS) {
                    const sessionInfo: SessionInfo | undefined = pathConstituents.session
                        ? {
                              org: repo.org,
                              repo: repo.name,
                              session_id: pathConstituents.session,
                          }
                        : undefined;

                    setViewMode(ViewMode.SESSIONS);
                    loadSession(sessionInfo, NavigationBehavior.NONE);
                } else if (pathConstituents.viewMode === ViewMode.PROJECTS) {
                    const projectInfo: ProjectInfo | undefined = pathConstituents.project
                        ? {
                              org: repo.org,
                              repo: repo.name,
                              project_id: pathConstituents.project,
                          }
                        : undefined;

                    setViewMode(ViewMode.PROJECTS);
                    loadProject(projectInfo, NavigationBehavior.NONE);
                } else {
                    loadSession(undefined, NavigationBehavior.NONE);
                }
            } else if (repoFullName !== undefined) {
                onRepoNotFound(repoFullName);
            }
        };

        window.addEventListener("popstate", onPopState);
        return () => {
            window.removeEventListener("popstate", onPopState);
        };
    }, [repos]);

    return <NavigationContext.Provider value={{}}>{children}</NavigationContext.Provider>;
};

const useNavigationContext = () => {
    return React.useContext(NavigationContext);
};

enum NavigationBehavior {
    NONE, // Do not change the URL
    PUSH, // Push a new URL to the history
    REPLACE, // Replace the current URL in the history
}

const navigateToRepo = (repo: string | undefined, navigationBehavior: NavigationBehavior) => {
    // TODO: in these functions, should we do nothing if the URL we would go to is the same as the current URL?

    switch (navigationBehavior) {
        case NavigationBehavior.NONE:
            break;
        case NavigationBehavior.PUSH:
            window.history.pushState({}, "", `${window.origin}/${repo}`);
            break;
        case NavigationBehavior.REPLACE:
            window.history.replaceState({}, "", `${window.origin}/${repo}`);
            break;
    }
};

const navigateToSessions = (repo: string | undefined, navigationBehavior: NavigationBehavior) => {
    if (repo === undefined) {
        navigateToRoot(navigationBehavior);
        return;
    }

    switch (navigationBehavior) {
        case NavigationBehavior.NONE:
            break;
        case NavigationBehavior.PUSH:
            window.history.pushState({}, "", `${window.origin}/${repo}/sessions`);
            break;
        case NavigationBehavior.REPLACE:
            window.history.replaceState({}, "", `${window.origin}/${repo}/sessions`);
            break;
    }
};

const navigateToSession = (session_id: string | undefined, navigationBehavior: NavigationBehavior) => {
    const pathConstituents: AppPath = parseAppPath(window.location.pathname, document.location.search);

    if (session_id === undefined) {
        navigateToSessions(pathConstituents.repo, navigationBehavior);
        return;
    }

    switch (navigationBehavior) {
        case NavigationBehavior.NONE:
            break;
        case NavigationBehavior.PUSH:
            window.history.pushState({}, "", `${window.origin}/${pathConstituents.repo}/sessions/${session_id}`);
            break;
        case NavigationBehavior.REPLACE:
            window.history.replaceState({}, "", `${window.origin}/${pathConstituents.repo}/sessions/${session_id}`);
            break;
    }
};

const navigateToProjects = (repo: string | undefined, navigationBehavior: NavigationBehavior) => {
    if (repo === undefined) {
        navigateToRoot(navigationBehavior);
        return;
    }

    switch (navigationBehavior) {
        case NavigationBehavior.NONE:
            break;
        case NavigationBehavior.PUSH:
            window.history.pushState({}, "", `${window.origin}/${repo}/projects`);
            break;
        case NavigationBehavior.REPLACE:
            window.history.replaceState({}, "", `${window.origin}/${repo}/projects`);
            break;
    }
};

const navigateToProject = (project_id: string | undefined, navigationBehavior: NavigationBehavior) => {
    const pathConstituents: AppPath = parseAppPath(window.location.pathname, document.location.search);

    if (project_id === undefined) {
        navigateToProjects(pathConstituents.repo, navigationBehavior);
        return;
    }

    switch (navigationBehavior) {
        case NavigationBehavior.NONE:
            break;
        case NavigationBehavior.PUSH:
            window.history.pushState({}, "", `${window.origin}/${pathConstituents.repo}/projects/${project_id}`);
            break;
        case NavigationBehavior.REPLACE:
            window.history.replaceState({}, "", `${window.origin}/${pathConstituents.repo}/projects/${project_id}`);
            break;
    }
};

const navigateToRoot = (navigationBehavior: NavigationBehavior) => {
    switch (navigationBehavior) {
        case NavigationBehavior.NONE:
            break;
        case NavigationBehavior.PUSH:
            window.history.pushState({}, "", window.origin);
            break;
        case NavigationBehavior.REPLACE:
            window.history.replaceState({}, "", window.origin);
            break;
    }
};

export type { NavigationContextType, AppPath };

export {
    NavigationBehavior,
    parseAppPath,
    NavigationProvider,
    useNavigationContext,
    navigateToRepo,
    navigateToSession,
    navigateToSessions,
    navigateToProject,
    navigateToProjects,
    navigateToRoot,
};
