import React, { useEffect } from "react";
import { Tooltip as AntTooltip, Button } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";

import { useTutorialActions } from "../data/TutorialActionContext";

import { TutorialAction } from "../data/SolverInterfaceEvent";

import "./GlowingTooltip.scss";

export type TutorialActionConfig = {
    action: TutorialAction;
    followUp?: TutorialAction;
    title: React.ReactNode | (() => React.ReactNode);
    suppress?: boolean;
};

export type GlowingTooltipProps = {
    children: React.ReactNode;
    configs: TutorialActionConfig[];
    placement?: TooltipPlacement;
};

export const GlowingTooltip: React.FC<GlowingTooltipProps> = ({ children, configs, placement }) => {
    const { currentTutorialAction, closeTutorialAction } = useTutorialActions();

    const currentConfig = configs.find((config) => config.action === currentTutorialAction);

    const isOpen = !!currentConfig;

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape" && isOpen) {
                close();
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        return () => document.removeEventListener("keydown", handleKeyDown);
    }, [isOpen]);

    useEffect(() => {
        if (currentConfig && currentConfig.suppress) {
            close();
        }
    }, [currentConfig]);

    const close = () => {
        if (!currentConfig) return;

        const { action, followUp } = currentConfig;

        closeTutorialAction(action, followUp);
    };

    const buildTooltipContent = () => {
        if (!currentConfig) return null;

        const { title } = currentConfig;

        let titleContent;
        if (typeof title === "string") {
            titleContent = title;
        } else if (title instanceof Function) {
            titleContent = title();
        }

        return (
            <div className="glowing-tooltip-content">
                {titleContent}
                <Button
                    className="glowing-tooltip-got-it"
                    onClick={(e) => {
                        e.stopPropagation();
                        close();
                    }}
                >
                    Got it
                </Button>
            </div>
        );
    };

    return (
        <AntTooltip color="#3f5d70" open={isOpen} title={buildTooltipContent()} placement={placement}>
            {isOpen ? (
                <div
                    className="glow"
                    onClick={(e) => {
                        e.stopPropagation();
                        close();
                    }}
                >
                    {children}
                </div>
            ) : (
                children
            )}
        </AntTooltip>
    );
};

export default GlowingTooltip;
