import { Button, Input, List } from "antd";
import React, { useState } from "react";

import { OpenQuestion } from "../../data/SolverProjects";

import "./TechPlan.scss";

export interface OpenQuestionsProps {
    questions: OpenQuestion[];
    onQuestionAnswered: (questionIndex: number, question: string, answer: string, isUnanswering: boolean) => void;
    initialAnswers?: string[];
    title?: string;
    disabled?: boolean;
}

export const OpenQuestions: React.FC<OpenQuestionsProps> = ({
    questions,
    onQuestionAnswered,
    title,
    disabled = false,
}) => {
    const [answers, setAnswers] = useState<string[]>(new Array(questions.length).fill(""));
    const [savedAnswers, setSavedAnswers] = useState<string[]>(new Array(questions.length).fill(""));
    const [answeredIndexes, setAnsweredIndexes] = useState<Set<number>>(new Set());

    const handleAnswerChange = (index: number, value: string) => {
        setAnswers((prevAnswers) => prevAnswers.map((oldValue, i) => (i === index ? value : oldValue)));

        // If this was previously answered, mark as unanswered as soon as editing starts
        if (answeredIndexes.has(index)) {
            onQuestionAnswered(index, questions[index].question, "", true);
        }

        setAnsweredIndexes((prev) => {
            const newIndices = new Set(prev);
            newIndices.delete(index);
            return newIndices;
        });
    };

    const handleSubmitAnswer = (index: number) => {
        const answerText = answers[index];
        // Only save if there's actual content AND it's different from the last saved answer
        if (answerText !== "" && answerText !== savedAnswers[index]) {
            const newSavedAnswers = [...savedAnswers];
            newSavedAnswers[index] = answerText;
            setSavedAnswers(newSavedAnswers);

            setAnsweredIndexes((prev) => new Set(prev).add(index));
            onQuestionAnswered(index, questions[index].question, answerText, false);
        }
    };

    // New handler for clicking potential answer buttons
    const handlePotentialAnswerClick = (index: number, answer: string) => {
        // Update local state immediately
        setAnswers((prevAnswers) => prevAnswers.map((oldValue, i) => (i === index ? answer : oldValue)));
        const newSavedAnswers = [...savedAnswers];
        newSavedAnswers[index] = answer;
        setSavedAnswers(newSavedAnswers);
        setAnsweredIndexes((prev) => new Set(prev).add(index));

        // Call the main callback
        onQuestionAnswered(index, questions[index].question, answer, false);
    };

    if (questions.length === 0) {
        return null;
    }

    return (
        <div className="tech-plan-questions">
            {title && <div className="tech-plan-task-section-title">{title}</div>}
            <List
                dataSource={questions}
                renderItem={(question, index) => (
                    <div key={index} className="tech-plan-question">
                        <div className="tech-plan-question-text">{question.question}</div>
                        {/* Render potential answer buttons if they exist */}
                        {question.potential_answers && question.potential_answers.length > 0 && (
                            <div className="tech-plan-potential-answers">
                                {question.potential_answers.map((answer, ansIndex) => (
                                    <Button
                                        className="tech-plan-potential-answer-button"
                                        key={ansIndex}
                                        onClick={() => handlePotentialAnswerClick(index, answer)}
                                        disabled={disabled}
                                    >
                                        {answer}
                                    </Button>
                                ))}
                            </div>
                        )}
                        <Input.TextArea
                            className={`tech-plan-answer-input ${answeredIndexes.has(index) ? "answered" : ""}`}
                            value={answers[index]}
                            onChange={(e) => handleAnswerChange(index, e.target.value)}
                            placeholder="Enter your answer..."
                            autoSize={{ minRows: 2, maxRows: 6 }}
                            disabled={disabled}
                            onBlur={() => handleSubmitAnswer(index)}
                        />
                    </div>
                )}
            />
        </div>
    );
};
