import React from "react";
import { Button, Dropdown, Statistic, Tag } from "antd";
import { BankOutlined, CaretDownOutlined, CreditCardOutlined, LoadingOutlined } from "@ant-design/icons";
import { SubscriptionData, useSubscriptionData } from "../data/SubscriptionContext";
import { round } from "lodash";

interface SubscriptionTabProps {
    loading: boolean;
    subscriptionData: SubscriptionData | null;
    closeAppMenuFn: () => void;
}

const SubscriptionTab: React.FC<SubscriptionTabProps> = ({ loading, subscriptionData, closeAppMenuFn }) => {
    const {
        getCreditPurchaseQuantity,
        setCreditPurchaseQuantity,
        openPurchaseCreditsModal,
        generateUpgradeUrl,
        generateCustomerPortalUrl,
    } = useSubscriptionData();

    const [generatingUpgradeUrl, setGeneratingUpgradeUrl] = React.useState(false);
    const [generatingCustomerPortalUrl, setGeneratingCustomerPortalUrl] = React.useState(false);

    const onUpgradeSubscriptionClicked = async () => {
        setGeneratingUpgradeUrl(true);
        try {
            const upgradeUrl = await generateUpgradeUrl();
            if (!upgradeUrl) return;

            window.location.href = upgradeUrl;
        } catch (error) {
            console.error("Error generating upgrade URL:", error);
        } finally {
            setGeneratingUpgradeUrl(false);
        }
    };

    const onManageSubscriptionClicked = async () => {
        setGeneratingCustomerPortalUrl(true);
        try {
            const customerPortalUrl = await generateCustomerPortalUrl();
            if (!customerPortalUrl) return;

            window.location.href = customerPortalUrl;
        } catch (error) {
            console.error("Error generating customer portal URL:", error);
        } finally {
            setGeneratingCustomerPortalUrl(false);
        }
    };

    if (loading) {
        return <LoadingOutlined />;
    }

    if (!subscriptionData) {
        return null;
    }

    const buildSubscriptionButton = () => {
        if (subscriptionData.is_free) {
            return (
                <Button
                    type="primary"
                    icon={<CreditCardOutlined />}
                    className="subscription-action-button"
                    loading={generatingUpgradeUrl}
                    disabled={generatingUpgradeUrl}
                    onClick={onUpgradeSubscriptionClicked}
                >
                    Upgrade to Pro
                </Button>
            );
        } else {
            return (
                <Button
                    type="primary"
                    icon={<BankOutlined />}
                    className="subscription-action-button"
                    loading={generatingCustomerPortalUrl}
                    disabled={generatingCustomerPortalUrl}
                    onClick={onManageSubscriptionClicked}
                >
                    Manage Subscription
                </Button>
            );
        }
    };

    return (
        <>
            <div className="subscription-stats">
                <div className="subscription-stat-item">
                    <Statistic
                        title={`${subscriptionData.subscription_name} Credits`}
                        value={round(subscriptionData.remaining_subscription_usage / 100, 2)}
                        prefix={<span style={{ fontSize: "80%" }}>ⓢ</span>}
                    />
                    <div className="settings-button-container">{buildSubscriptionButton()}</div>
                </div>

                <div className={`subscription-stat-item${subscriptionData.is_free ? " disabled" : ""}`}>
                    <Statistic
                        title={
                            <span>
                                Top-up Credits
                                {subscriptionData.is_free && (
                                    <Tag className="preview-badge" style={{ marginLeft: "8px" }}>
                                        Pro
                                    </Tag>
                                )}
                            </span>
                        }
                        value={round(subscriptionData.remaining_usage_credits / 100, 2)}
                        prefix={<span style={{ fontSize: "80%" }}>ⓢ</span>}
                    />
                    <div className="settings-button-container">
                        <Dropdown.Button
                            type="primary"
                            icon={<CaretDownOutlined />}
                            className="subscription-dropdown-button"
                            disabled={subscriptionData.is_free}
                            menu={{
                                items: [
                                    {
                                        key: "500",
                                        label: "500 credits",
                                        onClick: () => setCreditPurchaseQuantity(500),
                                    },
                                    {
                                        key: "1000",
                                        label: "1000 credits",
                                        onClick: () => setCreditPurchaseQuantity(1000),
                                    },
                                    {
                                        key: "1500",
                                        label: "1500 credits",
                                        onClick: () => setCreditPurchaseQuantity(1500),
                                    },
                                    {
                                        key: "2000",
                                        label: "2000 credits",
                                        onClick: () => setCreditPurchaseQuantity(2000),
                                    },
                                    {
                                        key: "2500",
                                        label: "2500 credits",
                                        onClick: () => setCreditPurchaseQuantity(2500),
                                    },
                                ],
                            }}
                            onClick={() => {
                                closeAppMenuFn();
                                openPurchaseCreditsModal();
                            }}
                        >
                            <CreditCardOutlined />
                            Purchase ({getCreditPurchaseQuantity()})
                        </Dropdown.Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SubscriptionTab;
