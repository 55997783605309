import React, { useEffect, useState } from "react";

import { App, Layout } from "antd";
import MaintenancePage from "./components/MaintenancePage";
import ActiveSession from "./components/ActiveSession";
import ActiveProject from "./components/ActiveProject";
import Sider from "./components/Sider";
import SolverHeader from "./components/SolverHeader";
import Startup from "./components/Startup";
import Features from "./data/Features";
import { AppPath, NavigationBehavior, parseAppPath } from "./data/Navigation";
import { SolverInterfaceStatus, useSolverInterfaceContext, ViewMode } from "./data/SolverInterface";
import { useLoadSession } from "./data/SolverSession";
import { useLoadProject } from "./data/SolverProjects";
import { AuthType } from "./data/User";

const SolverApp: React.FC = () => {
    const {
        activeRepo,
        viewMode,
        setViewMode,
        currentUser,
        connectToSolverInterface,
        acceptTermsAndConditions,
        loadingAdditionalRepos,
        solverInterfaceStatus,
        login,
        logout,
    } = useSolverInterfaceContext();
    const loadSession = useLoadSession();
    const loadProject = useLoadProject();

    const [siderDrawerOpen, setSiderDrawerOpen] = useState(false);

    useEffect(() => {
        const initApp = async () => {
            const pathConstituents: AppPath = parseAppPath(document.location.pathname, document.location.search);
            setViewMode(pathConstituents.viewMode);

            if (pathConstituents.repo) {
                const [org, repo] = pathConstituents.repo.split("/");

                const connectedSuccessfully = await connectToSolverInterface({
                    requestedOrg: org,
                    requestedRepo: repo,
                });

                setViewMode(pathConstituents.viewMode);

                if (connectedSuccessfully && pathConstituents.session) {
                    loadSession({ org, repo, session_id: pathConstituents.session }, NavigationBehavior.NONE);
                } else if (connectedSuccessfully && pathConstituents.project) {
                    loadProject({ org, repo, project_id: pathConstituents.project }, NavigationBehavior.NONE);
                }
            } else {
                await connectToSolverInterface({});
            }
        };

        if (Features.isMaintenanceModeEnabled()) {
            return;
        }

        initApp();
    }, []);

    const buildMainLayout = () => {
        if (solverInterfaceStatus !== SolverInterfaceStatus.LOGGED_IN) return buildStartup();
        if (!activeRepo) return buildStartup();

        return (
            <Layout>
                <Sider drawerOpen={siderDrawerOpen} onCloseDrawer={() => setSiderDrawerOpen(false)} />
                {viewMode === ViewMode.SESSIONS && <ActiveSession />}
                {viewMode === ViewMode.PROJECTS && <ActiveProject />}
            </Layout>
        );
    };

    const buildStartup = () => {
        return (
            <Startup
                solverInterfaceStatus={solverInterfaceStatus}
                onLogin={(authType: AuthType) => login(authType)}
                onLogout={logout}
                loadingAdditionalRepos={loadingAdditionalRepos}
                haveActiveRepo={!!activeRepo}
                currentUser={currentUser}
                onAcceptTermsAndConditions={acceptTermsAndConditions}
            />
        );
    };

    if (Features.isMaintenanceModeEnabled()) {
        return <MaintenancePage />;
    }

    return (
        <App>
            <SolverHeader onOpenSessionsList={() => setSiderDrawerOpen(true)} />
            {buildMainLayout()}
        </App>
    );
};

export default SolverApp;
