import { LoadingOutlined, CheckOutlined } from "@ant-design/icons";
import React from "react";
import "./BranchStatusIndicator.scss";

export interface BranchStatusIndicatorProps {
    isFetching: boolean;
    fetchComplete: boolean;
}

const BranchStatusIndicator: React.FC<BranchStatusIndicatorProps> = ({ isFetching, fetchComplete }) => {
    if (!isFetching && !fetchComplete) {
        return null;
    }

    return (
        <div className="branch-status-indicator">
            {isFetching ? (
                <>
                    <LoadingOutlined />
                    <span>Getting latest branches</span>
                </>
            ) : fetchComplete ? (
                <>
                    <CheckOutlined />
                    <span>Branches up-to-date</span>
                </>
            ) : null}
        </div>
    );
};

export default BranchStatusIndicator;
