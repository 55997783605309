import { solverInterfaceApiAxios } from "./SolverInterfaceConstants";
import { AxiosError } from "axios";

// WeakMap to temporarily store base64 content without impacting memory/storage limits
export const attachmentContentCache = new WeakMap<Attachment, string>();

export interface Attachment {
    attachmentId: string;
    file: File;
    fileExt: string;
    createdAt: string;
    status: "uploading" | "complete" | "error";
}

/**
 * Get the base64 content for an attachment if available in cache
 * @param attachment - The attachment to get content for
 * @returns The base64 content if available, undefined otherwise
 */
export const getAttachmentContent = (attachment: Attachment): string | undefined => {
    return attachmentContentCache.get(attachment);
};

/**
 * Uploads an image attachment to the backend service
 * @param org - Organization name
 * @param repo - Repository name
 * @param file - File object to upload
 * @returns Promise with the created attachment
 */
export const uploadImageAttachment = async (org: string, repo: string, file: File): Promise<Attachment> => {
    // Validate file type and extension
    const supportedFileExtensions = ["png", "jpg", "jpeg", "gif"];
    const fileExt = file.name.split(".").pop()?.toLowerCase() || "";

    if (!file.name.includes(".")) {
        throw new Error("File must have an extension");
    }

    if (!supportedFileExtensions.includes(fileExt)) {
        throw new Error(`Unsupported file type. Supported types: ${supportedFileExtensions.join(", ")}`);
    }

    // Validate file size and MIME type
    if (!file.type.startsWith("image/")) {
        throw new Error("Invalid file type. Only image files are supported.");
    }

    if (file.size === 0) {
        throw new Error("Empty attachment content");
    }

    // Validate actual file content using magic numbers
    const { validateImageFile } = await import("../utils/imageValidation");
    const validationResult = await validateImageFile(file);
    if (!validationResult.isValid) {
        throw new Error(validationResult.error || "Invalid image file content");
    }
    try {
        // Read file and convert to base64
        const fileContents = await new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64 = reader.result as string;
                // Remove the data URL prefix (e.g., "data:image/jpeg;base64,")
                const base64Clean = base64.split(",")[1];
                resolve(base64Clean);
            };
            reader.onerror = () => {
                reject(new Error("Failed to read file contents"));
            };
            reader.readAsDataURL(file);
        });

        const requestBody = {
            fileName: file.name,
            fileContent: fileContents,
        };

        const response = await solverInterfaceApiAxios.post(`/attachments/image/${org}/${repo}`, requestBody);
        const attachmentId = response.data.attachmentId;

        const attachment: Attachment = {
            attachmentId: attachmentId,
            file,
            fileExt,
            createdAt: new Date().toISOString(),
            status: "complete",
        };

        // Store the content in our module-level WeakMap cache
        attachmentContentCache.set(attachment, fileContents);

        return attachment;
    } catch (error) {
        // Handle specific error cases from the backend
        if (error instanceof AxiosError) {
            if (error.response?.status === 400) {
                throw new Error(error.response?.data?.error || "Invalid request");
            } else if (error.response?.status === 500) {
                throw new Error("Server error while uploading attachment");
            }
        }
        // Re-throw unknown errors
        throw error;
    }
};

/**
 * Deletes an image attachment from the backend storage
 * @param org - Organization name
 * @param repo - Repository name
 * @param attachment - Attachment object to delete
 */
export const deleteImageAttachment = async (org: string, repo: string, attachment: Attachment): Promise<void> => {
    try {
        await solverInterfaceApiAxios.delete(`/attachments/image/${org}/${repo}/${attachment.attachmentId}`);

        // Backend returns success message, but we don't need to return it
        return;
    } catch (error) {
        if (error instanceof AxiosError) {
            if (error.response?.status === 404) {
                throw new Error(`Attachment not found: ${attachment.attachmentId}`);
            } else if (error.response?.status === 401) {
                throw new Error("Authentication required to delete attachment");
            } else if (error.response?.status === 500) {
                throw new Error("Server error while deleting attachment");
            }
        }
        // Re-throw unknown errors
        throw error;
    }
};

/**
 * Downloads an image attachment from the backend service
 * @param org - Organization name
 * @param repo - Repository name
 * @param attachmentId - ID of the attachment to download
 * @returns Promise with the image data URI (base64 encoded)
 * @throws Error if the attachment is not found or if there's a server error
 */
/**
 * Gets a complete Attachment object including the base64 content from the backend service
 * @param org - Organization name
 * @param repo - Repository name
 * @param attachmentId - ID of the attachment to retrieve
 * @returns Promise with the complete Attachment object
 * @throws Error if the attachment is not found or if there's a server error
 */
export const getImageAttachment = async (org: string, repo: string, attachmentId: string): Promise<Attachment> => {
    const base64Content = await downloadImageAttachment(org, repo, attachmentId);

    // Extract file info from the base64 data URI
    const mimeMatch = base64Content.match(/^data:([^;]+);/);
    const mimeType = mimeMatch ? mimeMatch[1] : "image/jpeg";
    const fileExt = mimeType.split("/")[1];

    // Create a File object from the base64 content
    const byteString = atob(base64Content.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    const file = new File([ab], `image.${fileExt}`, { type: mimeType });

    const attachment: Attachment = {
        attachmentId,
        file,
        fileExt,
        createdAt: new Date().toISOString(),
        status: "complete",
    };

    // Store the content in our module-level WeakMap cache
    // The base64Content is already a complete data URI from downloadImageAttachment
    attachmentContentCache.set(attachment, base64Content);

    return attachment;
};

export const downloadImageAttachment = async (org: string, repo: string, attachmentId: string): Promise<string> => {
    try {
        const response = await solverInterfaceApiAxios.get(`/attachments/image/${org}/${repo}/${attachmentId}`);
        if (response.data && response.data.image) {
            // Parse the custom format from the API
            const imageData = response.data.image;

            // First extract and validate the media type
            const mediaTypeMatch = imageData.match(/media_type:(.*?);/);
            const contentType = mediaTypeMatch ? mediaTypeMatch[1] : "image/png";
            if (!mediaTypeMatch) {
                console.warn("Media type not found in response, using default image/png");
            }

            // Then extract and validate the base64 data
            const base64Index = imageData.indexOf("base64_data,");
            if (base64Index === -1) {
                throw new Error("Invalid image data format: missing base64_data marker");
            }

            const base64Data = imageData.substring(base64Index + "base64_data,".length);

            // Validate the extracted base64 data
            try {
                atob(base64Data); // Test if it's valid base64
            } catch (e) {
                console.error("Base64 validation failed:", e);
                throw new Error("Invalid base64 data in response");
            }

            // Create a proper data URI
            const dataUri = `data:${contentType};base64,${base64Data}`;
            return dataUri;
        }
        throw new Error("Invalid response format");
    } catch (error) {
        console.error("Download error:", {
            type: error instanceof AxiosError ? "AxiosError" : typeof error,
            status: error instanceof AxiosError ? error.response?.status : undefined,
            message: error instanceof Error ? error.message : String(error),
            response: error instanceof AxiosError ? error.response?.data : undefined,
        });

        if (error instanceof AxiosError) {
            if (error.response?.status === 404) {
                throw new Error(`Attachment not found: ${attachmentId}`);
            }
            throw new Error(
                `Failed to download image (${error.response?.status}): ${
                    error.response?.data?.message || error.message
                }`
            );
        }
        // Re-throw unknown errors with more context
        throw new Error(
            `Unexpected error downloading image: ${error instanceof Error ? error.message : String(error)}`
        );
    }
};
