import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";

import { CaretRightOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Collapse, CollapseProps } from "antd";
import classNames from "classnames";
import { ChangeData, Diff, getChangeKey, GutterOptions, HunkData, ViewType } from "react-diff-view";
import CustomAudioRecorder from "./CustomAudioRecorder";

import PromptEditor, { FocusableRef } from "./PromptEditor";
import SolverMarkdown from "./SolverMarkdown";
import { Comment, DiffHeader, FileInfo, getRelevantPath, HeaderLines, highlightSyntax, renderHunk } from "./Utils";

import { usePlatform } from "../data/PlatformContext";

import "prism-themes/themes/prism-vsc-dark-plus.css";
import styleConstants from "../constants.module.scss";
import "./CustomAudioRecorder.scss";
import "./DiffCard.scss";

const COLLAPSE_KEY = "0";

const getHunkKey = (hunk: HunkData) => {
    return `${hunk.oldStart}-${hunk.oldLines}-${hunk.newStart}-${hunk.newLines}`;
};

export const ImmutableDiffCard: React.FC<{
    fileInfo: FileInfo;
    postimage?: string;
    highlightRange?: { start: number; end: number };
    expandCodeFn?: (start: number, end: number) => void;
    onSubmitComment?: (comment: Comment) => void;
    onRetractComment?: (comment: Comment) => void;
    submittedComments?: Comment[];
    collapsible?: boolean;
    viewType?: ViewType;
    className?: string;
}> = ({
    fileInfo,
    postimage,
    highlightRange,
    expandCodeFn,
    onSubmitComment,
    onRetractComment,
    submittedComments,
    collapsible,
    viewType = "unified",
    className,
}) => {
    const { isMobileDevice } = usePlatform();

    const fileData = fileInfo.fileData;
    const filename = getRelevantPath(fileData);
    const hunks = fileData.hunks || [];

    // These memos map change keys back to their respective hunks.
    const hunkKeysByChangeKey: Map<string, string> = useMemo(() => {
        const hunkKeyMap = new Map<string, string>();

        hunks.forEach((hunk) => {
            hunk.changes.forEach((change) => {
                const changeKey = getChangeKey(change);
                hunkKeyMap.set(changeKey, getHunkKey(hunk));
            });
        });

        return hunkKeyMap;
    }, [hunks]);

    const hunksByHunkKey: Map<string, HunkData> = useMemo(() => {
        const hunkMap = new Map<string, HunkData>();

        hunks.forEach((hunk) => {
            hunkMap.set(getHunkKey(hunk), hunk);
        });

        return hunkMap;
    }, [hunks]);

    const tokens = useMemo(() => highlightSyntax(hunks, fileInfo.source, filename), [hunks, filename, fileInfo.source]);

    const [numberOfExpansions, setNumberOfExpansions] = useState(0);

    const onExpandCode = (start: number, end: number) => {
        if (!expandCodeFn) return;
        if (!fileInfo.source) return;

        expandCodeFn(start, end);

        setNumberOfExpansions((prev) => prev + 1);
    };

    const [isExpanded, setIsExpanded] = useState(!isMobileDevice);

    useLayoutEffect(() => {
        const updateCollapseState = () => {
            setIsExpanded(window.innerWidth > parseInt(styleConstants.SMALL_SCREEN_WIDTH));
        };

        updateCollapseState();

        window.addEventListener("resize", updateCollapseState);

        return () => {
            window.removeEventListener("resize", updateCollapseState);
        };
    }, []);

    const [commentsByChangeKey, setCommentsByChangeKey] = useState<Map<string, string>>(new Map());
    const newestPromptEditorRef = useRef<FocusableRef>(null);
    const isClickingInsideComment = useRef<boolean>(false);
    // Focuses the newest prompt editor when a new comment is started
    // or when a comment is clicked into editing mode.
    useEffect(() => {
        if (newestPromptEditorRef.current) {
            newestPromptEditorRef.current.focus();
        }
    }, [submittedComments, commentsByChangeKey]);

    const getWidgets = (hunks: HunkData[]) => {
        const changes: ChangeData[] = [];
        hunks.forEach((hunk) => {
            changes.push(...hunk.changes);
        });

        return changes.reduce((widgets, change) => {
            const changeKey = getChangeKey(change);

            return {
                ...widgets,
                [changeKey]: buildCommentWidget(changeKey, change),
            };
        }, {});
    };

    const buildCommentWidget = (changeKey: string, change: ChangeData) => {
        const submittedComment = submittedComments?.find((comment) => getChangeKey(comment.change) === changeKey);
        if (submittedComment) {
            return buildCommentPreview(submittedComment, changeKey);
        } else if (commentsByChangeKey.has(changeKey)) {
            return buildCommentEditor(change, changeKey);
        }

        return null;
    };

    const buildCommentPreview = (comment: Comment, changeKey: string) => {
        return (
            <div
                className="code-comment-container"
                onClick={(e) => {
                    onRetractComment?.(comment);

                    setCommentsByChangeKey((prevCommentsByChangeKey) => {
                        return new Map(prevCommentsByChangeKey.set(changeKey, comment.text));
                    });

                    e.stopPropagation();
                }}
            >
                <SolverMarkdown text={comment.text} />
                <div className="code-comment-button-container">
                    <Button
                        onClick={(e) => {
                            onRetractComment?.(comment);

                            // Avoids triggering the onClick event of the parent container.
                            e.stopPropagation();
                        }}
                    >
                        Delete
                    </Button>
                </div>
            </div>
        );
    };

    const buildCommentEditor = (change: ChangeData, changeKey: string) => {
        let placeholder = "";
        if (change.type === "normal") {
            placeholder = `Comment on line ${change.newLineNumber}`;
        } else if (change.type === "insert") {
            placeholder = `Comment on added line ${change.lineNumber}`;
        } else {
            placeholder = `Comment on deleted line ${change.lineNumber}`;
        }

        return (
            <div
                className="code-comment-container"
                onClick={(e) => {
                    newestPromptEditorRef.current?.focus();
                    e.stopPropagation();
                }}
                onMouseDown={() => {
                    isClickingInsideComment.current = true;
                }}
                onMouseUp={() => {
                    setTimeout(() => {
                        isClickingInsideComment.current = false;
                    }, 0);
                }}
            >
                <div className="comment-editor-container">
                    <PromptEditor
                        ref={newestPromptEditorRef}
                        value={commentsByChangeKey.get(changeKey) || ""}
                        onChange={(value) => {
                            setCommentsByChangeKey((prevCommentsByChangeKey) => {
                                return new Map(prevCommentsByChangeKey.set(changeKey, value || ""));
                            });
                        }}
                        placeholder={placeholder}
                        onKeyDown={(e) => {
                            if ((e.ctrlKey || e.metaKey) && e.key === "Enter") {
                                submitComment(change, changeKey);
                                e.preventDefault();
                            }
                        }}
                        onBlur={() => {
                            if (!isClickingInsideComment.current) {
                                submitComment(change, changeKey);
                            }
                        }}
                    />
                </div>
                <div className="code-comment-button-container">
                    {onSubmitComment && (
                        <CustomAudioRecorder
                            onTranscriptionComplete={(text: string) => {
                                setCommentsByChangeKey((prevCommentsByChangeKey) => {
                                    return new Map(prevCommentsByChangeKey.set(changeKey, text));
                                });
                            }}
                        />
                    )}
                    <Button
                        onClick={() => {
                            setCommentsByChangeKey((prevCommentsByChangeKey) => {
                                prevCommentsByChangeKey.delete(changeKey);
                                return new Map(prevCommentsByChangeKey);
                            });
                        }}
                    >
                        Delete
                    </Button>
                </div>
            </div>
        );
    };

    const submitComment = (change: ChangeData, changeKey: string) => {
        if (!onSubmitComment) return;

        const currentText = commentsByChangeKey.get(changeKey);
        if (!currentText) return;

        const hunkKey: string | undefined = hunkKeysByChangeKey.get(changeKey);
        if (!hunkKey) {
            console.warn("No hunk key found for change key: ", changeKey);
            return;
        }
        const hunk: HunkData | undefined = hunksByHunkKey.get(hunkKey);
        if (!hunk) {
            console.warn("No hunk found for hunk key: ", hunkKey);
            return;
        }

        onSubmitComment({
            text: currentText,
            change,
            hunk,
            fileData: fileData,
        });
        setCommentsByChangeKey((prevCommentsByChangeKey) => {
            prevCommentsByChangeKey.delete(changeKey);
            return new Map(prevCommentsByChangeKey);
        });
    };

    const changeInRange = (change: ChangeData, range?: { start: number; end: number }) => {
        if (!range) {
            return false;
        }

        if (change.type === "insert" || change.type === "delete") {
            if (change.lineNumber && change.lineNumber >= range.start && change.lineNumber <= range.end) {
                return true;
            }
        } else if (change.type === "normal") {
            if (change.oldLineNumber && change.oldLineNumber >= range.start && change.oldLineNumber <= range.end) {
                return true;
            }
            if (change.newLineNumber && change.newLineNumber >= range.start && change.newLineNumber <= range.end) {
                return true;
            }
        }
        return false;
    };

    const renderGutter = (options: GutterOptions) => {
        const changeIsInRange = changeInRange(options.change, highlightRange);

        const gutterClasses = classNames({
            "diff-gutter-highlight": changeIsInRange,
            "diff-gutter-highlight-new": changeIsInRange && options.side === "new",
        });

        if (options.side === "old") {
            return <a className={gutterClasses}>{options.renderDefault()}</a>;
        } else {
            return (
                <a className={classNames(gutterClasses, "diff-gutter-commentable")}>
                    {options.renderDefault()}
                    {buildGutterButton(options.change, options.inHoverState)}
                </a>
            );
        }
    };

    const buildGutterButton = (change: ChangeData, isHoveringGutter: boolean) => {
        const changeKey = getChangeKey(change);

        const submittedComment = submittedComments?.find((comment) => getChangeKey(comment.change) === changeKey);

        if (isHoveringGutter && onSubmitComment && !commentsByChangeKey.has(changeKey) && !submittedComment) {
            return (
                <button
                    className="diff-gutter-comment-button"
                    onClick={(e) => {
                        if (e.currentTarget.closest(".message-collapsed")) {
                            return;
                        }

                        setCommentsByChangeKey((prevCommentsByChangeKey) => {
                            return new Map(prevCommentsByChangeKey.set(changeKey, ""));
                        });

                        e.stopPropagation();
                    }}
                >
                    {<PlusOutlined />}
                </button>
            );
        }

        return null;
    };

    const buildDiff = () => {
        if (fileInfo.isLargeChange) {
            return (
                <div className="diff-too-large">
                    This diff is too large to display. Download the Session's patch to view these changes.
                </div>
            );
        }

        return (
            <Diff
                key={`${fileData.oldRevision}-${fileData.newRevision}-${viewType}`}
                gutterType="anchor"
                viewType={viewType}
                diffType={fileData.type}
                widgets={getWidgets(hunks)}
                renderGutter={renderGutter}
                hunks={hunks}
                tokens={tokens}
            >
                {(hunks) =>
                    hunks.flatMap((hunk, idx, hunks) => {
                        return renderHunk(hunk, idx, hunks, null, fileInfo.fileLines, {
                            expansionFn: onExpandCode,
                            expansionLines: numberOfExpansions < 2 ? 5 : 25,
                        });
                    })
                }
            </Diff>
        );
    };

    const buildDiffCard = (): CollapseProps["items"] => {
        return [
            {
                key: COLLAPSE_KEY,
                label: <DiffHeader fileData={fileData} />,
                children: buildDiff(),
                extra: <HeaderLines fileInfo={fileInfo} postimage={postimage} />,
                className: "diff-card",
                style: { padding: "0px" },
            },
        ];
    };

    if (collapsible) {
        return (
            <Collapse
                className={classNames(className, "diff-card-container")}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                items={buildDiffCard()}
                onChange={() => {
                    setIsExpanded((prev) => !prev);
                }}
                activeKey={isExpanded ? [COLLAPSE_KEY] : []}
            />
        );
    } else {
        return (
            <Card
                title={<DiffHeader fileData={fileData} />}
                extra={<HeaderLines fileInfo={fileInfo} postimage={postimage} />}
                className={classNames(className, "diff-card")}
            >
                {buildDiff()}
            </Card>
        );
    }
};

export default ImmutableDiffCard;
