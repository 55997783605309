import React, { useState } from "react";

import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";

import { AuthType, vcsProviderRepoConfigUrl } from "../data/User";

import "./NewRepoWizard.scss";

interface NewRepoWizardProps {
    authType: AuthType;
}

interface NewRepoOption {
    key: string;
    title: string;
    detail: string;
    action: React.ReactNode;
    hint?: string;
    backable?: boolean;
}

const NewRepoWizard: React.FC<NewRepoWizardProps> = ({ authType }) => {
    const [hasClickedCreate, setHasClickedCreate] = useState(false);

    const handleCreateRepo = (e: React.MouseEvent) => {
        e.stopPropagation();
        setHasClickedCreate(true);
    };

    const buildOption = (option: NewRepoOption) => {
        return (
            <div key={option.key} className="new-repo-wizard-option">
                <Typography.Title level={4}>{option.title}</Typography.Title>
                <Typography.Text className="new-repo-wizard-option-description">{option.detail}</Typography.Text>
                {option.action}
                {option.hint && <Typography.Text className="new-repo-wizard-hint">{option.hint}</Typography.Text>}
                {option.backable && (
                    <Button onClick={() => setHasClickedCreate(false)} size="small">
                        Back to other options
                    </Button>
                )}
            </div>
        );
    };

    const buildOptions = () => {
        let options: NewRepoOption[] = [];
        if (!hasClickedCreate) {
            options = [
                {
                    key: "connect-existing",
                    title: "Existing Repository",
                    detail: "Connect an existing GitHub repository with Solver",
                    action: (
                        <Button
                            type="primary"
                            href={vcsProviderRepoConfigUrl(authType)}
                            target="_blank"
                            rel="noreferrer"
                            icon={<PlusCircleOutlined />}
                        >
                            Connect with Repository
                        </Button>
                    ),
                },
                {
                    key: "create-new",
                    title: "New Repository",
                    detail: "Create a new GitHub repository to use with Solver",
                    action: (
                        <Button
                            href="https://github.com/new"
                            target="_blank"
                            rel="noreferrer"
                            icon={<PlusCircleOutlined />}
                            onClick={handleCreateRepo}
                        >
                            Create New Repository
                        </Button>
                    ),
                    hint: "Opens in a new tab — keep this window open",
                },
            ];
        } else {
            options = [
                {
                    key: "connect-after-create",
                    title: "Great! Now let's connect Solver",
                    detail: "Once you've created your repository, click below to connect it with Solver",
                    action: (
                        <Button
                            type="primary"
                            size="large"
                            href={vcsProviderRepoConfigUrl(authType)}
                            target="_blank"
                            rel="noreferrer"
                            icon={<PlusCircleOutlined />}
                        >
                            Connect Repository with Solver
                        </Button>
                    ),
                    hint: "After connecting, you'll be able to select your repository",
                    backable: true,
                },
            ];
        }

        return options.map((option) => buildOption(option));
    };

    return <div className="new-repo-wizard-options">{buildOptions()}</div>;
};

export default NewRepoWizard;
