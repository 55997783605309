import { FlagOutlined } from "@ant-design/icons";
import { Button } from "antd";
import type { NotificationInstance } from "antd/es/notification/interface";
import React, { useState } from "react";
import { SessionInfo } from "../data/SolverSession";
import ReportModal from "./ReportModal";
import ResponsiveTooltip from "./ResponsiveTooltip";

interface ReportButtonProps {
    sessionInfo: SessionInfo;
    className: string;
    notification: NotificationInstance;
    label?: string;
    origin?: string;
}

const ReportButton: React.FC<ReportButtonProps> = ({ sessionInfo, className, notification, label, origin }) => {
    const [reportModalOpen, setReportModalOpen] = useState(false);

    return (
        <>
            <ResponsiveTooltip title={label ? undefined : "Report an issue"} arrow={false}>
                <Button className={className} icon={<FlagOutlined />} onClick={() => setReportModalOpen(true)}>
                    {label}
                </Button>
            </ResponsiveTooltip>
            <ReportModal
                sessionInfo={sessionInfo}
                modalOpen={reportModalOpen}
                onOpenChange={setReportModalOpen}
                notification={notification}
                origin={origin}
            />
        </>
    );
};

export default ReportButton;
