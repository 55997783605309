"use client";
import { Tag, Typography } from "antd";
import React from "react";

import { RetrievalContent, RelevantFile } from "../../data/TurnEventContent";

import "./RelevantFilesCard.scss";

const fileExtensionColorMap: { [key: string]: string } = {
    ".ts": "cyan",
    ".tsx": "cyan",
    ".js": "gold",
    ".jsx": "purple",
    ".py": "green",
    ".java": "red",
    ".cpp": "magenta",
    ".html": "volcano",
    ".css": "geekblue",
    ".json": "lime",
    ".md": "orange",
    ".txt": "default",
};

function getFileExtension(filePath: string): string | null {
    const pathSegments = filePath.split("/");
    const fileName = pathSegments.pop() || "";
    const ext = fileName.includes(".") ? fileName.split(".").pop() : "";
    if (!ext) return null;
    return ext.length > 8 ? `${ext.substring(0, 8)}...` : `.${ext}`;
}

function getFileExtensionAsTag(filePath: string) {
    const ext = getFileExtension(filePath);
    const color = fileExtensionColorMap[ext ?? ""] || "default";
    return (
        <Tag color={color} className="relevant-files-extension-tag">
            {ext ?? "?"}
        </Tag>
    );
}

const CollapsedRelevanceRow: React.FC<{ file: RelevantFile }> = ({ file }) => {
    return (
        <tr>
            <td className="relevant-files-table-cell relevant-files-table-extension-cell">
                {getFileExtensionAsTag(file.path)}
            </td>
            <td className="relevant-files-table-cell relevant-files-table-path-cell">{file.path}</td>
        </tr>
    );
};

interface RetrievalCardProps {
    retrievalContent: RetrievalContent;
}

const RetrievalCard: React.FC<RetrievalCardProps> = ({ retrievalContent }) => {
    const files = retrievalContent.files;

    const buildFilesMessage = () => {
        const filesMessage =
            files.length === 0
                ? "No relevant files found"
                : `Found ${files.length} relevant file${files.length === 1 ? "" : "s"}`;

        return <Typography.Paragraph type="secondary">{filesMessage}</Typography.Paragraph>;
    };

    const buildFilesTable = () => {
        return (
            <table className="relevant-files-table">
                <tbody>
                    {files
                        .sort((a, b) => b.score - a.score)
                        .map((file) => (
                            <CollapsedRelevanceRow key={file.path} file={file} />
                        ))}
                </tbody>
            </table>
        );
    };

    return (
        <>
            {!retrievalContent.pending && buildFilesMessage()}
            {!retrievalContent.pending && buildFilesTable()}
        </>
    );
};

export default RetrievalCard;
