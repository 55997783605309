import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { solverInterfaceApiAxios } from "./SolverInterfaceConstants";
import { loadStripe } from "@stripe/stripe-js";
import { Modal } from "antd";
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { useSolverInterfaceContext } from "./SolverInterface";

const STRIPE_KEY = window.env?.["REACT_APP_STRIPE_KEY"] || process.env?.["REACT_APP_STRIPE_KEY"] || "";
const stripePromise = loadStripe(STRIPE_KEY);

const CheckoutForm: React.FC<{ open: boolean; creditQuantity: number; onClose: () => void }> = ({
    open,
    creditQuantity,
    onClose,
}) => {
    const fetchClientSecret = useCallback(async () => {
        let response = await solverInterfaceApiAxios.post("/credit_purchase", {
            quantity: creditQuantity,
            return_url: window.location.href,
        });

        return await response.data.client_secret;
    }, [creditQuantity]);

    const options = { fetchClientSecret: fetchClientSecret, onComplete: onClose };
    return (
        <Modal open={open} onCancel={onClose} footer={null} centered>
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </Modal>
    );
};

const DEFAULT_NUM_STEPS = 24;

export interface SubscriptionData {
    remaining_subscription_usage: number;
    remaining_usage_credits: number;
    subscription_name: string;
    is_free: boolean;
}

type SubscriptionContextType = {
    isLoading: boolean;
    numSteps: number;
    setNumSteps: (steps: number) => void;
    subscriptionData: SubscriptionData | null;
    refreshSubscriptionData: () => Promise<void>;
    openPurchaseCreditsModal: () => void;
    setCreditPurchaseQuantity: (quantity: number) => void;
    getCreditPurchaseQuantity: () => number;
    generateUpgradeUrl: () => Promise<string | undefined>;
    generateCustomerPortalUrl: () => Promise<string | undefined>;
};

const nullSubscriptionContext: SubscriptionContextType = {
    isLoading: true,
    numSteps: DEFAULT_NUM_STEPS,
    setNumSteps: () => {},
    subscriptionData: null,
    refreshSubscriptionData: async () => {},
    openPurchaseCreditsModal: () => {},
    setCreditPurchaseQuantity: () => {},
    getCreditPurchaseQuantity: () => 500,
    generateUpgradeUrl: async () => "",
    generateCustomerPortalUrl: async () => "",
};

const SubscriptionContext = createContext<SubscriptionContextType>(nullSubscriptionContext);

export const SubscriptionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { loggedIn } = useSolverInterfaceContext();

    const [isLoading, setIsLoading] = useState(true);
    const [subscriptionData, setSubscriptionData] = useState<SubscriptionData | null>(null);
    const [numStepsState, setNumStepsState] = useState<number>(DEFAULT_NUM_STEPS);
    const [purchaseCreditsModalOpen, setPurchaseCreditsModalOpen] = useState(false);
    const [creditQuantity, setCreditQuantity] = useState(500);

    // Initialize numSteps from localStorage
    useEffect(() => {
        const savedSteps = localStorage.getItem("solverNumSteps");
        if (savedSteps) {
            setNumStepsState(parseInt(savedSteps));
        }
    }, []);

    const setNumSteps = (steps: number) => {
        setNumStepsState(steps);
        localStorage.setItem("solverNumSteps", steps.toString());
    };

    const fetchSubscriptionData = async () => {
        if (!loggedIn) return;

        setIsLoading(true);
        await solverInterfaceApiAxios
            .get("/subscription_usage")
            .then((response) => {
                if (response.status === 200) {
                    setSubscriptionData(response.data);
                } else {
                    setSubscriptionData(null);
                }
            })
            .catch((error) => console.error("Error fetching subscription data:", error))
            .finally(() => setIsLoading(false));
    };

    // Initial fetch on mount
    useEffect(() => {
        fetchSubscriptionData();
    }, []);

    const generateUpgradeUrl = async () => {
        const response = await solverInterfaceApiAxios.get("/update_subscription", {
            params: { return_url: window.location.href },
        });

        if (response.status === 200) {
            return response.data.url as string;
        } else {
            console.warn("No upgrade URL available");
            return undefined;
        }
    };

    const generateCustomerPortalUrl = async () => {
        const response = await solverInterfaceApiAxios.get("/customer_portal", {
            params: { return_url: window.location.href },
        });

        if (response.status === 200) {
            return response.data.url as string;
        } else {
            console.warn("No portal URL available");
            return undefined;
        }
    };

    const value = {
        isLoading,
        numSteps: numStepsState,
        setNumSteps,
        subscriptionData,
        refreshSubscriptionData: fetchSubscriptionData,
        openPurchaseCreditsModal: () => setPurchaseCreditsModalOpen(true),
        setCreditPurchaseQuantity: setCreditQuantity,
        getCreditPurchaseQuantity: () => creditQuantity,
        generateUpgradeUrl,
        generateCustomerPortalUrl,
    };

    return (
        <SubscriptionContext.Provider value={value}>
            <CheckoutForm
                key={creditQuantity}
                open={purchaseCreditsModalOpen}
                creditQuantity={creditQuantity}
                onClose={() => setPurchaseCreditsModalOpen(false)}
            />
            {children}
        </SubscriptionContext.Provider>
    );
};

export const useSubscriptionData = () => {
    const context = useContext(SubscriptionContext);
    if (context === undefined) {
        throw new Error("useSubscriptionData must be used within a SubscriptionProvider");
    }
    return context;
};
