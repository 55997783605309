import { Space, Typography, Collapse, Tag } from "antd";
import React from "react";
import "./ApiIntegrationsTab.scss";
import ApiKeyManagement from "./ApiKeyManagement";

const { Panel } = Collapse;

const { Title, Paragraph } = Typography;

const ApiIntegrationsTab: React.FC = () => {
    const buildSlackButton = () => {
        const slackServiceUrl = window.env?.REACT_APP_SLACK_SERVICE_URL || process.env.REACT_APP_SLACK_SERVICE_URL;

        if (!slackServiceUrl) {
            return (
                <Paragraph>
                    <em>Slack integration not configured</em>
                </Paragraph>
            );
        }

        const installUrl = `${slackServiceUrl}/slack/install`;

        return (
            <a href={installUrl}>
                <img
                    alt="Add to Slack"
                    height="40"
                    width="139"
                    src="https://platform.slack-edge.com/img/add_to_slack.png"
                    srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                />
            </a>
        );
    };

    return (
        <Space direction="vertical" size="large" className="api-integrations-tab">
            <div className="api-keys-section">
                <ApiKeyManagement />
            </div>
            <div className="slack-integration">
                <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                    <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
                        Slack Integration
                    </Title>
                    <Tag className="preview-badge">Preview</Tag>
                </div>
                <Paragraph>Turn your Slack discussions into real solutions with @Solver.</Paragraph>
                <Collapse ghost>
                    <Panel header="Currently in preview" key="1">
                        <Paragraph>
                            When installing, you'll see this integration is "not approved by Slack." This is because
                            we're preparing for Slack's marketplace review process, which typically takes 8+ weeks.
                        </Paragraph>
                        <Paragraph>
                            The integration is currently in preview while we work toward Slack marketplace approval.
                        </Paragraph>
                        <Paragraph>
                            Questions?{" "}
                            <a href="https://solverai.com/privacy" target="_blank">
                                Review our privacy policy
                            </a>
                            .
                        </Paragraph>
                    </Panel>
                </Collapse>
                {buildSlackButton()}
            </div>
        </Space>
    );
};

export default ApiIntegrationsTab;
