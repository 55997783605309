import React from "react";
import ReactDOM from "react-dom/client";
import { ConfigProvider } from "antd";
import { ErrorBoundary } from "react-error-boundary";

import "react-diff-view/style/index.css";

import { APP_THEME } from "./AppTheme";
import { ExecutionSettingsProvider } from "./data/ExecutionSettings";
import { LocalSettingsProvider } from "./data/LocalSettingsContext";
import { PlatformProvider } from "./data/PlatformContext";
import { NavigationProvider } from "./data/Navigation";
import { SessionBrowsingProvider } from "./data/SessionBrowsing";
import { AppMenuStateProvider } from "./data/AppMenuState";
import { SolverInterfaceContextProvider } from "./data/SolverInterface";
import { SessionProvider } from "./data/SolverSession";
import { ProjectProvider } from "./data/ProjectProvider";
import { ProjectBrowsingProvider } from "./data/ProjectBrowsing";
import { SubscriptionProvider } from "./data/SubscriptionContext";
import { TutorialActionProvider } from "./data/TutorialActionContext";

import ErrorFallback from "./ErrorFallback";
import SolverApp from "./SolverApp";

import "./index.scss";
import "./scrollbar.scss";

// initTelemetry(window.location.host);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <PlatformProvider>
                <ConfigProvider theme={APP_THEME}>
                    <LocalSettingsProvider>
                        <SolverInterfaceContextProvider>
                            <TutorialActionProvider>
                                <SubscriptionProvider>
                                    <ExecutionSettingsProvider>
                                        <AppMenuStateProvider>
                                            <SessionProvider>
                                                <SessionBrowsingProvider>
                                                    <ProjectProvider>
                                                        <ProjectBrowsingProvider>
                                                            <NavigationProvider>
                                                                <SolverApp />
                                                            </NavigationProvider>
                                                        </ProjectBrowsingProvider>
                                                    </ProjectProvider>
                                                </SessionBrowsingProvider>
                                            </SessionProvider>
                                        </AppMenuStateProvider>
                                    </ExecutionSettingsProvider>
                                </SubscriptionProvider>
                            </TutorialActionProvider>
                        </SolverInterfaceContextProvider>
                    </LocalSettingsProvider>
                </ConfigProvider>
            </PlatformProvider>
        </ErrorBoundary>
    </React.StrictMode>
);

document.addEventListener(
    "keydown",
    (e) => {
        if ((e.ctrlKey || e.metaKey) && e.key === "s") e.preventDefault();
    },
    false
);
