import { notification } from "antd";
import type { UploadFile } from "antd/es/upload/interface";
import { AxiosError, isAxiosError } from "axios";
import qs from "qs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";
import RateLimitMessage from "../components/RateLimitMessage";
import { useSubscriptionData } from "./SubscriptionContext";

import { changeSetToFileInfos, Comment, FileInfo } from "../components/Utils";
import { navigateToSession, NavigationBehavior } from "./Navigation";
import { Prompt, PROMPT_MAX_LENGTH } from "./Prompt";
import { SessionSortAttribute, SessionSortOrder } from "./SessionBrowsing";
import { useSolverInterfaceContext } from "./SolverInterface";
import { SOLVER_INTERFACE_URL_BASE, solverInterfaceApiAxios } from "./SolverInterfaceConstants";
import {
    SolverInterfaceEvent,
    SolverInterfaceEventType,
    TraceEvent as StreamTurnEvent,
    TraceEventType,
} from "./SolverInterfaceEvent";
import { PlanningAnswer } from "./SolverProjects";
import {
    AgentThoughtContent,
    BisectContent,
    BlameContent,
    CodeCoverageContent,
    CreditsUsedContent,
    DocumentationContent,
    ExecutionContent,
    LinterContent,
    MergeUserBranchContent,
    ModelBackoffContent,
    OpenFileContent,
    ProfileContent,
    ProjectTreeContent,
    RetrievalContent,
    RemoteCommitsContent,
    SolutionReviewContent,
    SolvingStoppedContent,
    SuggestMemoryStoreContent,
    TextSearchContent,
    UnknownTurnEventContent,
    WebBrowseContent,
    WebSearchContent,
    WorkspaceCreationProgressContent,
} from "./TurnEventContent";

import { AuthType, SessionVisibility, User } from "./User";

import { SolverInterfaceEventObserverHandle, useStreamConnection } from "../hooks/useStreamConnection";

export const DEFAULT_SURCHARGE = 100;

export enum CreateSessionResultCode {
    NO_ERROR = "no_error",
    REPO_NOT_CONFIGURED = "repo_not_configured",
    BRANCH_NOT_FOUND = "branch_not_found",
    FAILED_TO_CREATE_BRANCH = "failed_to_create_branch",
}

export enum SessionStatus {
    READY = "ready",
    SOLVING = "solving",
    PENDING = "pending",
    SUBMITTING_CANCEL = "submitting_cancel",
    SUBMITTING_SOLVE = "submitting_solve",
}

export type SessionInfo = {
    org: string;
    repo: string;
    session_id: string;
};

export interface SessionStub {
    session_id: string;
    user_id: string;
    user_name: string;
    user_avatar_url: string;
    auth_type: AuthType;
    status: SessionStatus;
    visibility: SessionVisibility;
    base_revision: string;
    branch_name: string;
    remote_branch_name?: string;
    repo_name: string;
    title: string;
    description: string | undefined;
    linked_issue: string | undefined;
    pending_nl_text: string | undefined;
    is_background: boolean;
    create_timestamp: number;
    modify_timestamp: number;
    planning_session_for_project_id: string | undefined;
    project_task_id: string | undefined;
    project_id: string | undefined;
    is_immutable: boolean | undefined;
}

export interface Session extends SessionStub {
    getInfo: () => SessionInfo;
    allowModification: (currentUserId?: string) => boolean;
}

export const sessionStubToSession = (sessionStub: SessionStub): Session => {
    const [org, repo] = sessionStub.repo_name.split("/");

    return {
        ...sessionStub,
        allowModification: (currentUserId?: string) => {
            if (sessionStub.is_immutable) {
                return false;
            }

            if (sessionStub.visibility === SessionVisibility.PUBLIC_READ_WRITE) {
                return true;
            }
            return sessionStub.user_id === currentUserId;
        },
        getInfo: () => ({
            org,
            repo,
            session_id: sessionStub.session_id,
        }),
    };
};

export type Turn = {
    id: string;
    idx: number;
    error: string | undefined;
    user_id: string;
    user_name: string;
    user_avatar_url: string;
    allow_undo?: boolean;
    nl_text: string;
    events: TraceEvent[];
};

export interface TraceEvent {
    id: string;
    idx: number;
    start: string;
    end: string;
    event_type: TraceEventType;
    created: number;
    // TODO: Why is this strongly typed?
    event_data?:
        | WorkspaceCreationProgressContent
        | AgentThoughtContent
        | DocumentationContent
        | UnknownTurnEventContent
        | ProfileContent
        | RetrievalContent
        | LinterContent
        | ExecutionContent
        | CodeCoverageContent
        | SolutionReviewContent
        | SolvingStoppedContent
        | CreditsUsedContent
        | WebSearchContent
        | WebBrowseContent
        | ModelBackoffContent
        | ChangeSet;
}

export interface AgentThoughtEvent extends TraceEvent {
    event_type: TraceEventType.AGENT_THOUGHT;
    event_data: AgentThoughtContent;
}

export interface WorkspaceCreationProgressEvent extends TraceEvent {
    event_type: TraceEventType.WORKSPACE_CREATION_PROGRESS;
    event_data: WorkspaceCreationProgressContent;
}

export interface CreateEvent extends TraceEvent {
    event_type: TraceEventType.CREATE;
    event_data?: ChangeSet;
}

export interface EditEvent extends TraceEvent {
    event_type: TraceEventType.EDIT;
    event_data?: ChangeSet;
}

export interface RevertEvent extends TraceEvent {
    event_type: TraceEventType.REVERT;
    event_data?: ChangeSet;
}

export interface ExecutionEditEvent extends TraceEvent {
    event_type: TraceEventType.EXECUTION_EDIT;
    event_data?: ChangeSet;
}

export interface DocumentationEvent extends TraceEvent {
    event_type: TraceEventType.DOCUMENTATION;
    event_data: DocumentationContent;
}

export interface CodeCoverageEvent extends TraceEvent {
    event_type: TraceEventType.CODE_COVERAGE;
    event_data: CodeCoverageContent;
}

export interface ExecutionEvent extends TraceEvent {
    event_type: TraceEventType.EXECUTION;
    event_data: ExecutionContent;
}

export interface ProfileEvent extends TraceEvent {
    event_type: TraceEventType.PROFILE;
    event_data: ProfileContent;
}

export interface LinterErrorsEvent extends TraceEvent {
    event_type: TraceEventType.LINT_ERRORS;
    event_data: LinterContent;
}

export interface BlameEvent extends TraceEvent {
    event_type: TraceEventType.BLAME;
    event_data: BlameContent;
}

export interface BisectEvent extends TraceEvent {
    event_type: TraceEventType.BISECT;
    event_data: BisectContent;
}

export interface CreditsUsedEvent extends TraceEvent {
    event_type: TraceEventType.CREDITS_USED;
    event_data: CreditsUsedContent;
}

export interface RetrievalEvent extends TraceEvent {
    event_type: TraceEventType.RETRIEVAL;
    event_data: RetrievalContent;
}

export interface SolutionReviewEvent extends TraceEvent {
    event_type: TraceEventType.SOLUTION_REVIEW;
    event_data: SolutionReviewContent;
}

export interface OpenFileEvent extends TraceEvent {
    event_type: TraceEventType.OPEN_FILE;
    event_data: OpenFileContent;
}

export interface RemoteCommitsEvent extends TraceEvent {
    event_type: TraceEventType.REMOTE_COMMITS;
    event_data: RemoteCommitsContent;
}

export interface MergeUserBranchEvent extends TraceEvent {
    event_type: TraceEventType.MERGE_USER_BRANCH;
    event_data: MergeUserBranchContent;
}

export interface TextSearchEvent extends TraceEvent {
    event_type: TraceEventType.TEXT_SEARCH;
    event_data: TextSearchContent;
}

export interface WebSearchEvent extends TraceEvent {
    event_type: TraceEventType.WEB_SEARCH;
    event_data: WebSearchContent;
}

export interface WebBrowseEvent extends TraceEvent {
    event_type: TraceEventType.WEB_BROWSE;
    event_data: WebBrowseContent;
}

export interface ProjectTreeEvent extends TraceEvent {
    event_type: TraceEventType.PROJECT_TREE;
    event_data: ProjectTreeContent;
}

export interface SolvingStoppedEvent extends TraceEvent {
    event_type: TraceEventType.SOLVING_STOPPED;
    event_data: SolvingStoppedContent;
}

export interface SuggestMemoryStoreEvent extends TraceEvent {
    event_type: TraceEventType.SUGGEST_MEMORY_STORE;
    event_data: SuggestMemoryStoreContent;
}

export interface ModelBackoffEvent extends TraceEvent {
    event_type: TraceEventType.MODEL_BACKOFF;
    event_data: ModelBackoffContent;
}

export const turnEventIsFetchable = (traceEventType: TraceEventType | string) => {
    return (
        traceEventType === TraceEventType.CREATE ||
        traceEventType === TraceEventType.EDIT ||
        traceEventType === TraceEventType.REVERT ||
        traceEventType === TraceEventType.EXECUTION_EDIT
    );
};

export enum EventChangeSetState {
    LOADING = "loading",
    FETCHED = "fetched",
    ERROR = "error",
}

export type ChangeSet = {
    changes: ChangedFile[];
    preimages: FileImage[];
    postimages: FileImage[];
    file_infos: FileInfo[];
};

export type EventChangeSet = {
    state: EventChangeSetState;
    changeSet: ChangeSet;
};

export type FileImage = {
    file_path: string;
    hash: string;
    contents: string;
};

export type ChangedFile = {
    patch: string;
    change_ids: string[];
    is_large_change: boolean;
    added_lines: number;
    removed_lines: number;
};

export enum LoadingSessionState {
    LOADING = "loading",
    DONE = "done",
    NOT_FOUND = "not_found",
    ERROR = "error",
}

enum CommentType {
    CHAT = "chat",
    CHANGES = "changes",
}

export type SessionContextType = {
    session: Session | undefined;
    turns: Turn[];
    sessionSurcharge: number;
    eventChangeSets: Map<string, EventChangeSet>;
    nlText: string;
    // All comments.
    comments: Comment[];
    // Comments in the Chat tab.
    chatComments: Comment[];
    // Comments in the Changes tab.
    changesComments: Comment[];
    sessionStatus: SessionStatus;
    loadingSessionState: LoadingSessionState;
    loadSession: (sessionInfo: SessionInfo | undefined, navigationBehavior: NavigationBehavior) => Promise<boolean>;
    // updateSessionStatus() and updateSession() should only be used to update
    // |sessionStatus| and |session| in reaction to a streamed event. They
    // provide a linkage between the stream of repo events and SessionContext.
    updateSessionStatus: (status: SessionStatus, turn_id: string | undefined) => void;
    updateSession: (session: Session) => void;
    // A straightfoward interface to update the session title with an API call.
    updateSessionTitle: (newTitle: string) => Promise<boolean>;
    revertToTurn: (turn_id: string | null) => Promise<void>;
    canSolve: (prompt: string, promptComments: Comment[], numSteps: number, answers?: PlanningAnswer[]) => boolean;
    solve: (prompt: string, promptComments: Comment[], numSteps: number) => Promise<void>;
    plan: (prompt: string, answers: PlanningAnswer[], numSteps: number) => Promise<void>;
    createAndSolve: (
        nl_text: string,
        org: string,
        repo: string,
        branch: string | undefined,
        numSteps: number
    ) => Promise<void>;
    canCancelSolve: boolean;
    cancelSolve: () => Promise<void>;
    addChatComment: (comment: Comment) => void;
    addChangesComment: (comment: Comment) => void;
    removeChatComment: (comment: Comment) => void;
    removeChangesComment: (comment: Comment) => void;
    canModifySession: boolean;
    fetchChangeSet: (eventId: string, start: string, end: string) => void;
    refreshTurns: () => void;
};

// A null object for the SessionContextType that provides a default value for
// |SessionContext|. In pracice, when React renders a SessionProvider and its
// subtree, this value will be overwritten by the |value| of the provider, but
// this is useful for avoiding null checks as a client of exported hooks.
const nullSessionContext: SessionContextType = {
    session: undefined,
    turns: [],
    eventChangeSets: new Map(),
    nlText: "",
    comments: [],
    chatComments: [],
    changesComments: [],
    sessionStatus: SessionStatus.READY,
    loadingSessionState: LoadingSessionState.DONE,
    loadSession: () => Promise.resolve(false),
    updateSessionStatus: () => {},
    updateSession: () => {},
    updateSessionTitle: () => Promise.resolve(false),
    revertToTurn: () => Promise.resolve(),
    canSolve: () => false,
    solve: () => Promise.resolve(),
    plan: () => Promise.resolve(),
    createAndSolve: () => Promise.resolve(),
    canCancelSolve: false,
    cancelSolve: () => Promise.resolve(),
    addChatComment: () => {},
    addChangesComment: () => {},
    removeChatComment: () => {},
    removeChangesComment: () => {},
    canModifySession: false,
    refreshTurns: () => {},
    fetchChangeSet: () => {},
    sessionSurcharge: DEFAULT_SURCHARGE,
};

const SessionContext = createContext<SessionContextType>(nullSessionContext);

const SessionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [loadingSessionState, setLoadingSessionState] = useState<LoadingSessionState>(LoadingSessionState.DONE);
    const [session, setSession] = useState<Session | undefined>(undefined);
    const [turns, setTurns] = useState<Turn[]>([]);
    const [eventChangeSets, setEventChangeSets] = useState<Map<string, EventChangeSet>>(new Map());
    const [nlText, setNLText] = useState<string>("");
    const [comments, setComments] = useState<Map<string, Comment>>(new Map());
    const [sessionStatus, setSessionStatus] = useState<SessionStatus>(SessionStatus.READY);
    const [sessionSurcharge, setSessionSurcharge] = useState(DEFAULT_SURCHARGE);
    const [api, contextHolder] = notification.useNotification();

    const { currentUser, onStreamConnectionErrorResponse, onStreamReconnectionFailed } = useSolverInterfaceContext();

    // Update surcharge based on turns (more turns = higher surcharge)
    useEffect(() => {
        if (!session) {
            setSessionSurcharge(DEFAULT_SURCHARGE);
            return;
        }

        solverInterfaceApiAxios
            .get(`/sessions/${session.repo_name}/${session.session_id}/surcharge`)
            .then((response) => {
                if (response.status === 200) {
                    setSessionSurcharge(response.data.surcharge);
                } else {
                    setSessionSurcharge(DEFAULT_SURCHARGE);
                }
            })
            .catch((error) => {
                console.error("Error fetching session surcharge:", error);
                setSessionSurcharge(DEFAULT_SURCHARGE);
            });
    }, [session?.session_id, turns.length]);

    const { connect, addSolverInterfaceEventObserver, removeSolverInterfaceEventObserver } = useStreamConnection(
        (status: number) => {
            if (status === 401 || status === 403) {
                onStreamConnectionErrorResponse(status);
            } else {
                api.error({
                    message: "Error connecting to session stream",
                    placement: "bottomRight",
                    key: "session-stream-error",
                });
            }
        },
        onStreamReconnectionFailed
    );

    useEffect(() => {
        const handle: SolverInterfaceEventObserverHandle = addSolverInterfaceEventObserver(
            SolverInterfaceEventType.TRACE_EVENT,
            (solverInterfaceEvent: SolverInterfaceEvent) => {
                const turnEvent = solverInterfaceEvent as StreamTurnEvent;

                if (turnEvent.session_id !== session?.session_id) return;

                addOrUpdateTurnEvent(turnEvent);
            }
        );

        return () => removeSolverInterfaceEventObserver(handle);
    }, [session?.session_id]);

    const addOrUpdateTurnEvent = (event: StreamTurnEvent) => {
        if (!session) {
            console.log("addOrUpdateTurnEvent called with no session loaded");
            return;
        }

        const newTurnEvent: TraceEvent = {
            id: event.id,
            idx: event.idx,
            start: event.start,
            end: event.end,
            event_type: event.event_type,
            created: Date.now() / 1000,
            event_data: event.event_data,
        };

        if (turnEventIsFetchable(newTurnEvent.event_type)) {
            // If the event already has a ChangeSet in its event_data, use it
            if (newTurnEvent.event_data) {
                setEventChangeSets((prevEventChangeSets) => {
                    return new Map(
                        prevEventChangeSets.set(newTurnEvent.id, {
                            state: EventChangeSetState.FETCHED,
                            changeSet: {
                                ...(newTurnEvent.event_data as ChangeSet),
                                file_infos: changeSetToFileInfos(newTurnEvent.event_data as ChangeSet),
                            },
                        })
                    );
                });
            } else {
                // Otherwise fetch it as before
                fetchChangeSet(newTurnEvent.id, newTurnEvent.start, newTurnEvent.end);
            }
        }

        setTurns((prevTurns) => {
            return prevTurns.map((turn) => {
                if (turn.id === event.turn_id) {
                    const prevEvents = turn.events;

                    const eventsLength = prevEvents.length;
                    if (eventsLength === 0) {
                        return { ...turn, events: [newTurnEvent] };
                    } else if (prevEvents[eventsLength - 1].idx < newTurnEvent.idx) {
                        return { ...turn, events: [...prevEvents, newTurnEvent] };
                    }

                    const newEvents = prevEvents.map((turnEvent) => {
                        if (turnEvent.id === newTurnEvent.id) {
                            return newTurnEvent;
                        } else {
                            return turnEvent;
                        }
                    });

                    return { ...turn, events: newEvents };
                } else {
                    return turn;
                }
            });
        });
    };

    const loadSession = useCallback(
        async (sessionInfo: SessionInfo | undefined, navigationBehavior: NavigationBehavior) => {
            connect(undefined);

            if (!sessionInfo) {
                clearSession();
                navigateToSession(undefined, navigationBehavior);
                return true;
            }

            navigateToSession(sessionInfo.session_id, navigationBehavior);

            setLoadingSessionState(LoadingSessionState.LOADING);

            try {
                const [newSession, turns] = await Promise.all([getSessionBase(sessionInfo), listTurns(sessionInfo)]);

                // Initialize session state
                setSession({
                    ...newSession,
                });
                setTurns(turns);
                setSessionStatus(newSession.status);
                setComments(new Map());

                // Initialize event change sets with any existing changes
                const initialChangeSets = new Map<string, EventChangeSet>();
                turns.forEach((turn) => {
                    turn.events.forEach((event) => {
                        if (turnEventIsFetchable(event.event_type) && event.event_data) {
                            initialChangeSets.set(event.id, {
                                state: EventChangeSetState.FETCHED,
                                changeSet: {
                                    ...(event.event_data as ChangeSet),
                                    file_infos: changeSetToFileInfos(event.event_data as ChangeSet),
                                },
                            });
                        }
                    });
                });
                setEventChangeSets(initialChangeSets);

                setLoadingSessionState(LoadingSessionState.DONE);
                setNLText(newSession.pending_nl_text || "");

                if (newSession.status === SessionStatus.SOLVING) {
                    connect(
                        `${SOLVER_INTERFACE_URL_BASE}/api/connect/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}`
                    );
                }

                return true;
            } catch (error) {
                if (isAxiosError(error) && error.response?.status === 404) {
                    setLoadingSessionState(LoadingSessionState.NOT_FOUND);
                } else {
                    setLoadingSessionState(LoadingSessionState.ERROR);
                }

                return false;
            }
        },
        []
    );

    const updateSessionStatus = useCallback(
        async (status: SessionStatus, turn_id: string | undefined) => {
            if (!session) {
                console.log("updateSessionStatus called with no session loaded");
                return;
            }

            setSessionStatus(status);

            if (status === SessionStatus.PENDING && turn_id) {
                const newTurn = await getTurn(session.getInfo(), turn_id);

                if (newTurn) {
                    setTurns((prevTurns) => [...prevTurns, newTurn]);
                    setNLText("");
                    setComments(new Map());
                }
            } else if (status === SessionStatus.SOLVING) {
                connect(
                    `${SOLVER_INTERFACE_URL_BASE}/api/connect/sessions/${session.getInfo().org}/${
                        session.getInfo().repo
                    }/${session.getInfo().session_id}`
                );
            }
        },
        [session]
    );

    const updateSession = useCallback(
        (newSession: Session) => {
            if (!session) {
                console.log("updateSession called with no session loaded");
                return;
            }

            setSession(newSession);
        },
        [session]
    );

    const updateSessionTitleFn = useCallback(
        async (newTitle: string) => {
            if (!session) {
                console.log("updateSessionTitle called with no session loaded");
                return Promise.resolve(false);
            }

            const success = await updateSessionTitle(session.getInfo(), newTitle);

            if (success) {
                setSession((prevSession) => {
                    if (!prevSession) {
                        console.error("updateSessionTitle called with no session loaded");
                        return prevSession;
                    }

                    return { ...prevSession, title: newTitle };
                });

                return true;
            } else {
                return false;
            }
        },
        [session]
    );

    // This, canSolve, and canCancelSolve can be merged because they have
    // logic overlap.
    // Also we check for who the current user is in other places, and
    // those places can probably use this instead.
    const canModifySession = useMemo(() => {
        if (!session) return false;
        if (session.is_immutable) return false;

        if (session.visibility === SessionVisibility.PUBLIC_READ_WRITE) {
            return true;
        }
        return session.user_id === currentUser?.id;
    }, [session, currentUser]);

    const revertToTurn = useCallback(
        (turn_id: string | null): Promise<void> => {
            if (!session) {
                console.log("revertToTurn called with no session loaded");
                return Promise.reject(new Error("No session loaded"));
            }

            if (!canModifySession) return Promise.reject(new Error("Cannot modify session"));

            const sessionInfo = session.getInfo();
            return solverInterfaceApiAxios
                .post<SessionStub>(
                    `/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}/revert`,
                    {
                        turn_id,
                        user_id: session.user_id,
                    }
                )
                .then((response) => {
                    // The backend returns the updated session
                    updateSession(sessionStubToSession(response.data));
                    setTurns((prevTurns) => {
                        // When clearing all turns, preserve the text from the first turn
                        if (turn_id === null) {
                            if (prevTurns.length > 0) {
                                setNLText(prevTurns[0].nl_text);
                            }
                            setComments(new Map());
                            return [];
                        }

                        // Find the target turn by ID
                        const targetTurn = turn_id ? prevTurns.find((t) => t.id === turn_id) : null;

                        if (!targetTurn) {
                            console.error("Target turn not found");
                            return prevTurns;
                        }

                        // Keep turns up to and including the target turn
                        const newTurns = prevTurns.filter((t) => t.idx <= targetTurn.idx);
                        // When reverting, populate with the text from the turn that was reverted (the next turn)
                        const revertedTurn = prevTurns.find((t) => t.idx === targetTurn.idx + 1);
                        if (revertedTurn) {
                            setNLText(revertedTurn.nl_text);
                        }
                        setComments(new Map());
                        return newTurns;
                    });
                })
                .catch((error) => {
                    console.log(error);
                    api.error({ message: "Failed to revert", description: error.toString(), placement: "bottomRight" });
                });
        },
        [session, updateSession, canModifySession]
    );

    const canSolve = useCallback(
        (prompt: string, promptComments: Comment[], numSteps: number, answers?: PlanningAnswer[]) => {
            if (numSteps <= 0) {
                return false;
            }

            // Need some NL.
            if (!prompt && promptComments.length === 0 && (!answers || answers.length === 0)) {
                return false;
            } else if (!session) {
                return true;
            }

            // Don't allow solving while a solve is in progress
            if (sessionStatus === SessionStatus.SOLVING || sessionStatus === SessionStatus.PENDING) {
                return false;
            }

            return session.allowModification(currentUser?.id);
        },
        [sessionStatus, session, currentUser]
    );

    const solve = useCallback(
        async (prompt: string, promptComments: Comment[], numSteps: number) => {
            if (!session) {
                console.log("solve called with no session loaded");
                return Promise.resolve();
            }

            if (!canSolve(prompt, promptComments, numSteps)) {
                return Promise.resolve();
            }

            const fullPrompt = Prompt.formatPrompt(prompt, promptComments);

            if (fullPrompt.length > PROMPT_MAX_LENGTH) {
                console.error("Prompt exceeds max length");
                return Promise.resolve();
            }

            setNLText(prompt);
            setSessionStatus(SessionStatus.SUBMITTING_SOLVE);

            const sessionInfo = session.getInfo();

            // Get attachment IDs from state in NaturalLanguageInput
            const attachments = window.sessionStorage.getItem(`attachments_${sessionInfo.session_id}`);
            const attachmentRefs = attachments
                ? JSON.parse(attachments).map((a: UploadFile) => {
                      // response is populated by the uploadImageAttachment function with the Attachment interface data
                      const attachment = a.response as { attachmentId: string };
                      return attachment.attachmentId;
                  })
                : [];

            try {
                // /solve returns 202.
                await solverInterfaceApiAxios.post<void>(
                    `/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}/solve`,
                    {
                        nl_text: fullPrompt,
                        comment_count: promptComments.length,
                        num_steps: numSteps,
                        image_attachments: attachmentRefs,
                    }
                );
            } catch (error: unknown) {
                if (error instanceof AxiosError) {
                    popToastForSolveError(error.response?.status || 500);
                }

                setSessionStatus(SessionStatus.READY);
            }
        },
        [session, canSolve]
    );

    const plan = useCallback(
        async (prompt: string, answers: PlanningAnswer[], numSteps: number) => {
            if (!session) {
                console.log("plan called with no session loaded");
                return Promise.resolve();
            }

            if (!canSolve(prompt, [], numSteps, answers)) {
                return Promise.resolve();
            }

            if (Prompt.computePromptLength(prompt.length, 0, answers) > PROMPT_MAX_LENGTH) {
                console.error("Prompt exceeds max length");
                return Promise.resolve();
            }

            setNLText(prompt);
            setSessionStatus(SessionStatus.SUBMITTING_SOLVE);

            const sessionInfo = session.getInfo();

            try {
                // Note:
                // 1. The base path is different than /sessions endpoints.
                // 2. We're using the project id.
                await solverInterfaceApiAxios.post<void>(
                    `/repos/${sessionInfo.org}/${sessionInfo.repo}/projects/${session.planning_session_for_project_id}/plan`,
                    {
                        nl_text: prompt,
                        open_question_answers: answers,
                        num_steps: numSteps,
                    }
                );
            } catch (error: unknown) {
                if (error instanceof AxiosError) {
                    popToastForSolveError(error.response?.status || 500);
                }

                setSessionStatus(SessionStatus.READY);
            }
        },
        [canSolve, session]
    );

    const createAndSolve = useCallback(
        async (nl_text: string, org: string, repo: string, branch: string | undefined, numSteps: number) => {
            if (session) {
                console.log("Create and solve called with a session loaded");
                return Promise.resolve();
            }

            if (!canSolve(nl_text, [], numSteps)) {
                return Promise.resolve();
            }

            if (nl_text.length > PROMPT_MAX_LENGTH) {
                console.error("Prompt exceeds max length");
                return Promise.resolve();
            }

            setNLText(nl_text);
            setSessionStatus(SessionStatus.SUBMITTING_SOLVE);

            // Get attachment IDs from state in NaturalLanguageInput - use a temporary key for new session
            const attachments = window.sessionStorage.getItem(`attachments_new_session`);
            const attachmentRefs = attachments
                ? JSON.parse(attachments).map((a: UploadFile) => {
                      console.log("Processing attachment (new session):", a);
                      const attachment = a.response as { attachmentId: string };
                      return attachment.attachmentId;
                  })
                : [];

            return solverInterfaceApiAxios
                .post<SessionStub>(`/sessions/${org}/${repo}/solve`, {
                    nl_text: nl_text,
                    ref: branch,
                    num_steps: numSteps,
                    image_attachments: attachmentRefs,
                })
                .then((response) => {
                    // Clean up temporary attachments storage
                    window.sessionStorage.removeItem(`attachments_new_session`);
                    loadSession(sessionStubToSession(response.data).getInfo(), NavigationBehavior.PUSH);
                })
                .catch((error) => {
                    // Clean up temporary attachments storage even on error
                    window.sessionStorage.removeItem(`attachments_new_session`);

                    if (error.response?.status === 429) {
                        loadSession(error.response.data.session_id, NavigationBehavior.PUSH);
                    }

                    popToastForSolveError(error.response?.status || 500);
                });
        },
        [session, canSolve]
    );

    const canCancelSolve = useMemo(() => {
        if (!session) return false;
        if (!session.allowModification(currentUser?.id)) return false;

        return sessionIsLoading(sessionStatus);
    }, [sessionStatus, session, currentUser]);

    const cancelSolve = useCallback(async () => {
        if (!session) {
            console.log("cancelSolve called with no session loaded");
            return Promise.resolve();
        }

        if (!canCancelSolve) {
            return Promise.resolve();
        }

        // TODO: can we not submit the turn since the solver interface can infer it?
        if (turns.length === 0) {
            console.error("cancelSolve called with no turns");
            return Promise.resolve();
        }
        const lastTurnId = turns[turns.length - 1].id;

        const sessionInfo = session.getInfo();

        try {
            const response = await solverInterfaceApiAxios.post(
                `/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}/turns/${lastTurnId}/cancel`
            );
            if (response.status === 204) {
                setSessionStatus(SessionStatus.SUBMITTING_CANCEL);
            } else {
                console.log(`Unexpected response code from cancelSolve: ${response.status}`);
            }
        } catch (error: unknown) {
            if (error instanceof AxiosError && error.response) {
                api.error({
                    message: "Error cancelling solve",
                    description: "Solve could not be cancelled.",
                    placement: "bottomRight",
                });
            } else {
                console.log(error);
            }
        }
    }, [session, canCancelSolve, turns]);

    const fetchChangeSet = useCallback(
        async (eventId: string, start: string, end: string) => {
            if (!session) {
                console.log("fetchChangeSet called with no session loaded");
                return;
            }

            if (eventChangeSets.has(eventId) && eventChangeSets.get(eventId)?.state === EventChangeSetState.FETCHED) {
                return;
            }

            setEventChangeSets((prevEventChangeSets) => {
                return new Map(
                    prevEventChangeSets.set(eventId, {
                        state: EventChangeSetState.LOADING,
                        changeSet: {
                            changes: [],
                            preimages: [],
                            postimages: [],
                            file_infos: [],
                        },
                    })
                );
            });

            let newState: EventChangeSetState;
            let newChangeSet: ChangeSet;
            try {
                newState = EventChangeSetState.FETCHED;
                newChangeSet = await getChanges(session.getInfo(), {
                    include_preimage: false,
                    include_postimage: false,
                    start,
                    end,
                });
            } catch (error) {
                newState = EventChangeSetState.ERROR;
                newChangeSet = {
                    changes: [],
                    preimages: [],
                    postimages: [],
                    file_infos: [],
                };
            }

            setEventChangeSets((prevEventChangeSets) => {
                return new Map(
                    prevEventChangeSets.set(eventId, {
                        state: newState,
                        changeSet: newChangeSet,
                    })
                );
            });
        },
        [eventChangeSets, session]
    );

    const commentsList = useMemo(() => {
        return Array.from(comments.values());
    }, [comments]);

    const chatComments = useMemo(() => {
        return Array.from(comments.entries())
            .filter(([key]) => key.startsWith(CommentType.CHAT))
            .map(([, comment]) => comment);
    }, [comments]);

    const changesComments = useMemo(() => {
        return Array.from(comments.entries())
            .filter(([key]) => key.startsWith(CommentType.CHANGES))
            .map(([, comment]) => comment);
    }, [comments]);

    const addChatComment = useCallback((comment: Comment) => {
        setComments((prevComments) => {
            return new Map(prevComments.set(getCommentKey(comment, CommentType.CHAT), comment));
        });
    }, []);

    const addChangesComment = useCallback((comment: Comment) => {
        setComments((prevComments) => {
            return new Map(prevComments.set(getCommentKey(comment, CommentType.CHANGES), comment));
        });
    }, []);

    const removeChatComment = useCallback((comment: Comment) => {
        setComments((prevComments) => {
            const newComments = new Map(prevComments);
            newComments.delete(getCommentKey(comment, CommentType.CHAT));
            return newComments;
        });
    }, []);

    const removeChangesComment = useCallback((comment: Comment) => {
        setComments((prevComments) => {
            const newComments = new Map(prevComments);
            newComments.delete(getCommentKey(comment, CommentType.CHANGES));
            return newComments;
        });
    }, []);

    const getCommentKey = (comment: Comment, commentType: CommentType) => {
        let key = `${commentType}-`;
        key += `${comment.fileData.oldPath}-${comment.fileData.newPath}-`;

        const lineNumber = comment.change.type === "normal" ? comment.change.newLineNumber : comment.change.lineNumber;

        key += `${lineNumber}`;

        return key;
    };

    const refreshTurns = useCallback(() => {
        if (!session) {
            console.log("refreshTurns called with no session loaded");
            return;
        }

        listTurns(session.getInfo()).then((turns) => {
            setTurns(turns);
        });
    }, [session]);

    const clearSession = () => {
        setLoadingSessionState(LoadingSessionState.DONE);
        setSession(undefined);
        setNLText("");
        setComments(new Map());
        setTurns([]);
        setEventChangeSets(new Map());
        setSessionStatus(SessionStatus.READY);
    };

    const { openPurchaseCreditsModal, subscriptionData } = useSubscriptionData();

    const popToastForSolveError = (status: number) => {
        if (status === 429 && subscriptionData) {
            const message = subscriptionData.is_free ? "Free Tier Usage Limit Reached" : "Pro Tier Usage Limit Reached";
            api.error({
                message: message,
                description: (
                    <RateLimitMessage
                        openPurchaseCreditsModal={openPurchaseCreditsModal}
                        subscriptionData={subscriptionData}
                    />
                ),
                placement: "bottomRight",
                duration: null,
                key: "solve-quota-exceeded",
            });
        } else {
            api.error({
                message: "Error solving",
                description: "An error occurred while solving.",
                placement: "bottomRight",
            });
        }
    };

    const value = {
        session,
        turns,
        eventChangeSets,
        nlText,
        comments: commentsList,
        chatComments,
        changesComments,
        sessionStatus,
        loadingSessionState,
        loadSession,
        updateSessionStatus,
        updateSession,
        updateSessionTitle: updateSessionTitleFn,
        revertToTurn,
        canSolve,
        solve,
        plan,
        createAndSolve,
        canCancelSolve,
        cancelSolve,
        addChatComment,
        addChangesComment,
        removeChatComment,
        removeChangesComment,
        canModifySession,
        fetchChangeSet,
        refreshTurns,
        sessionSurcharge,
    };

    return (
        <SessionContext.Provider value={value}>
            {contextHolder}
            {children}
        </SessionContext.Provider>
    );
};

const useSession = () => useContextSelector(SessionContext, (ctx) => ctx.session);
const useTurns = () => useContextSelector(SessionContext, (ctx) => ctx.turns);
const useEventChangeSets = () => useContextSelector(SessionContext, (ctx) => ctx.eventChangeSets);
const useSessionNLText = () => useContextSelector(SessionContext, (ctx) => ctx.nlText);
const useComments = () => useContextSelector(SessionContext, (ctx) => ctx.comments);
const useChatComments = () => useContextSelector(SessionContext, (ctx) => ctx.chatComments);
const useChangesComments = () => useContextSelector(SessionContext, (ctx) => ctx.changesComments);
const useSessionStatus = () => useContextSelector(SessionContext, (ctx) => ctx.sessionStatus);
const useLoadingSessionState = () => useContextSelector(SessionContext, (ctx) => ctx.loadingSessionState);
const useLoadSession = () => useContextSelector(SessionContext, (ctx) => ctx.loadSession);
const useUpdateSessionStatus = () => useContextSelector(SessionContext, (ctx) => ctx.updateSessionStatus);
const useUpdateSession = () => useContextSelector(SessionContext, (ctx) => ctx.updateSession);
const useUpdateSessionTitle = () => useContextSelector(SessionContext, (ctx) => ctx.updateSessionTitle);
const useRevertToTurn = () => useContextSelector(SessionContext, (ctx) => ctx.revertToTurn);
const useCanSolve = () => useContextSelector(SessionContext, (ctx) => ctx.canSolve);
const useSolve = () => useContextSelector(SessionContext, (ctx) => ctx.solve);
const usePlan = () => useContextSelector(SessionContext, (ctx) => ctx.plan);
const useCreateAndSolve = () => useContextSelector(SessionContext, (ctx) => ctx.createAndSolve);
const useCanCancelSolve = () => useContextSelector(SessionContext, (ctx) => ctx.canCancelSolve);
const useCancelSolve = () => useContextSelector(SessionContext, (ctx) => ctx.cancelSolve);
const useAddChatComment = () => useContextSelector(SessionContext, (ctx) => ctx.addChatComment);
const useAddChangesComment = () => useContextSelector(SessionContext, (ctx) => ctx.addChangesComment);
const useRemoveChatComment = () => useContextSelector(SessionContext, (ctx) => ctx.removeChatComment);
const useRemoveChangesComment = () => useContextSelector(SessionContext, (ctx) => ctx.removeChangesComment);
const useCanModifySession = () => useContextSelector(SessionContext, (ctx) => ctx.canModifySession);
const useFetchChangeSet = () => useContextSelector(SessionContext, (ctx) => ctx.fetchChangeSet);
const useRefreshTurns = () => useContextSelector(SessionContext, (ctx) => ctx.refreshTurns);
const useSessionSurcharge = () => useContextSelector(SessionContext, (ctx) => ctx.sessionSurcharge);

const getSessionBase = async (sessionInfo: SessionInfo): Promise<Session> => {
    return solverInterfaceApiAxios
        .get<SessionStub>(`/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}`)
        .then((response) => sessionStubToSession(response.data))
        .catch((error) => {
            console.error(error);
            throw error;
        });
};

const listTurns = async (sessionInfo: SessionInfo): Promise<Turn[]> => {
    return solverInterfaceApiAxios
        .get<Turn[]>(`/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}/turns`)
        .then((response) => {
            const turns = response.data;

            return turns;
        })
        .catch((error) => {
            console.error(error);
            throw error;
        });
};

const getTurn = async (sessionInfo: SessionInfo, turn_id: string): Promise<Turn> => {
    return solverInterfaceApiAxios
        .get<Turn>(`/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}/turns/${turn_id}`)
        .then((response) => {
            const turn = response.data;

            return turn;
        })
        .catch((error) => {
            console.log(error);
            throw error;
        });
};

const getSessions = async (
    org: string,
    repo: string,
    title_filter: string | undefined = undefined,
    show_all: boolean = false,
    status_filters: SessionStatus[] | undefined = undefined,
    sort_attribute: SessionSortAttribute = SessionSortAttribute.LAST_MODIFIED,
    sort_order: SessionSortOrder = SessionSortOrder.DESCENDING,
    page: number,
    page_size: number
): Promise<Session[]> => {
    const query = qs.stringify(
        {
            ...(title_filter && { title_filter }),
            ...{ show_all },
            ...(status_filters && { status_filters }),
            ...(sort_attribute && { sort_attribute }),
            ...(sort_order && { sort_order }),
            page,
            page_size,
        },
        { arrayFormat: "repeat" }
    );

    return await solverInterfaceApiAxios
        .get<SessionStub[]>(`${org}/${repo}/sessions?${query}`)
        .then((response) => response.data.map((sessionStub) => sessionStubToSession(sessionStub)))
        .catch((error) => {
            console.log(error);
            return [];
        });
};

const getRepoUsers = async (org: string, repo: string): Promise<User[]> => {
    return await solverInterfaceApiAxios
        .get<User[]>(`${org}/${repo}/users`)
        .then((response) => response.data)
        .catch((error) => {
            console.log(error);
            return [];
        });
};

const createSession = async (
    org: string,
    repo: string,
    ref?: string,
    pending_nl_text?: string
): Promise<{
    session: Session | undefined;
    responseCode: CreateSessionResultCode;
}> => {
    return await solverInterfaceApiAxios
        .post<SessionStub>(`${org}/${repo}/sessions`, { ref, pending_nl_text })
        .then((response) => {
            return { session: sessionStubToSession(response.data), responseCode: CreateSessionResultCode.NO_ERROR };
        })
        .catch((error) => {
            const response = error.response;
            if (response && response.data.result_code) {
                return { session: undefined, responseCode: response.data.result_code as CreateSessionResultCode };
            } else {
                console.log(error);
                throw error;
            }
        });
};

const deleteSession = async (sessionInfo: SessionInfo): Promise<boolean> => {
    return solverInterfaceApiAxios
        .delete(`/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}`)
        .then(() => true)
        .catch(() => false);
};

const cloneSession = async (sessionInfo: SessionInfo): Promise<Session> => {
    return solverInterfaceApiAxios
        .post(`/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}/clone`)
        .then((response) => sessionStubToSession(response.data))
        .catch((error) => {
            console.log(error);
            throw error;
        });
};

const updateSessionTitle = (sessionInfo: SessionInfo, title: string): Promise<boolean> => {
    return solverInterfaceApiAxios
        .patch(`/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}`, { title })
        .then(() => true)
        .catch((error) => {
            console.log(error);
            return false;
        });
};

const updateSessionVisibility = async (sessionInfo: SessionInfo, visibility: SessionVisibility): Promise<boolean> => {
    return solverInterfaceApiAxios
        .patch(`/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}`, {
            visibility,
        })
        .then(() => true)
        .catch((error) => {
            console.log(error);
            return false;
        });
};

const sendSessionReport = (
    sessionInfo: SessionInfo,
    description: string,
    email?: string | null,
    canContact: boolean = false,
    origin?: string
): Promise<number> => {
    return solverInterfaceApiAxios
        .post<string>(`/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}/report`, {
            description,
            email,
            can_contact: canContact,
            origin,
        })
        .then((response) => response.status)
        .catch((error) => {
            if (error.response) {
                return error.response.status;
            } else {
                console.log(error);
                return null;
            }
        });
};

interface GetChangesOptions {
    include_preimage: boolean;
    include_postimage: boolean;
    start?: string | undefined;
    end?: string | undefined;
}

const getChanges = async (sessionInfo: SessionInfo, options: GetChangesOptions): Promise<ChangeSet> => {
    const query = qs.stringify({ ...options });

    return solverInterfaceApiAxios
        .get<ChangeSet>(`/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}/changes?${query}`)
        .then((response) => {
            const changeSet = response.data;

            return { ...changeSet, file_infos: changeSetToFileInfos(changeSet) };
        })
        .catch((error) => {
            console.log(error);
            throw error;
        });
};

enum PushToRemoteResultCode {
    SUCCESS,
    NO_CONTENT,
    PUSH_FAILED,
}

interface PushToRemoteResponse {
    pull_request: string;
    remote_branch_name: string;
    result_code: PushToRemoteResultCode;
    error_message?: string;
}

const pushSessionToRemote = async (sessionInfo: SessionInfo): Promise<PushToRemoteResponse> => {
    return solverInterfaceApiAxios
        .post(`/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}/push`, { description: "" })
        .then((response) => {
            if (response.status === 204) {
                return {
                    pull_request: "",
                    remote_branch_name: "",
                    result_code: PushToRemoteResultCode.NO_CONTENT,
                };
            } else if (response.data.pull_request) {
                return {
                    pull_request: response.data.pull_request,
                    remote_branch_name: response.data.remote_branch_name,
                    result_code: PushToRemoteResultCode.SUCCESS,
                };
            } else {
                return {
                    pull_request: "",
                    remote_branch_name: "",
                    result_code: PushToRemoteResultCode.PUSH_FAILED,
                };
            }
        })
        .catch((error) => {
            console.log(error);
            if (error.response?.data?.message) {
                return {
                    pull_request: "",
                    remote_branch_name: "",
                    result_code: PushToRemoteResultCode.PUSH_FAILED,
                    error_message: error.response.data.message,
                };
            }
            throw error;
        });
};

interface MergeSessionBaseBranchResponse {
    did_merge_changes: boolean;
    error: string | null;
}

const mergeSessionBaseBranch = async (sessionInfo: SessionInfo): Promise<MergeSessionBaseBranchResponse> => {
    return solverInterfaceApiAxios
        .post<MergeSessionBaseBranchResponse>(
            `/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}/merge`
        )
        .then((response) => response.data)
        .catch((error) => {
            const errorMessage = error.response.data.error ? error.response.data.error : "Failed to merge base branch";

            return { did_merge_changes: false, error: errorMessage };
        });
};

interface GetSessionPullRequestResponse {
    pr_url: string;
    pr_already_exists: boolean;
    error: string | null;
}

const getSessionPullRequest = async (sessionInfo: SessionInfo): Promise<GetSessionPullRequestResponse> => {
    return solverInterfaceApiAxios
        .get<GetSessionPullRequestResponse>(
            `/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}/pr`
        )
        .then((response) => response.data)
        .catch((error) => {
            const errorMessage = error.response.data.error ? error.response.data.error : "Failed to get pull request";

            return { pr_url: "", pr_already_exists: false, error: errorMessage };
        });
};

const getPatchContents = async (sessionInfo: SessionInfo): Promise<string> => {
    return solverInterfaceApiAxios
        .get<string>(`/sessions/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}/patch`)
        .then((response) => response.data)
        .catch((error) => {
            console.log(error);
            throw error;
        });
};

const sessionIsLoading = (sessionStatus: SessionStatus) => {
    return sessionStatus !== SessionStatus.READY;
};

export {
    cloneSession,
    createSession,
    deleteSession,
    getChanges,
    getPatchContents,
    getRepoUsers,
    getSessionBase,
    getSessionPullRequest,
    getSessions,
    mergeSessionBaseBranch,
    pushSessionToRemote,
    PushToRemoteResultCode,
    sendSessionReport,
    sessionIsLoading,
    SessionProvider,
    updateSessionVisibility,
    useAddChangesComment,
    useAddChatComment,
    useCanCancelSolve,
    useCancelSolve,
    useCanModifySession,
    useCanSolve,
    useChangesComments,
    useChatComments,
    useComments,
    useCreateAndSolve,
    useEventChangeSets,
    useFetchChangeSet,
    useLoadingSessionState,
    useLoadSession,
    usePlan,
    useRefreshTurns,
    useRemoveChangesComment,
    useRemoveChatComment,
    useRevertToTurn,
    useSession,
    useSessionNLText,
    useSessionStatus,
    useSessionSurcharge,
    useSolve,
    useTurns,
    useUpdateSession,
    useUpdateSessionStatus,
    useUpdateSessionTitle,
};

export type { GetSessionPullRequestResponse, MergeSessionBaseBranchResponse, PushToRemoteResponse };
