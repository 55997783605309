import React, { createContext, useEffect, useState } from "react";

import { useSolverInterfaceContext } from "./SolverInterface";
import {
    SolverInterfaceEvent,
    SolverInterfaceEventType,
    ProjectChangedEvent,
    ProjectCreatedEvent,
    ProjectDeletedEvent,
    ProjectVisibilityEvent,
} from "./SolverInterfaceEvent";
import {
    Project,
    getProjectBase,
    getProjectExecution,
    getProjectsList,
    useLoadProject,
    useProject,
    useUpdateProject,
} from "./SolverProjects";
import { SessionVisibility } from "./User";
import { SolverInterfaceEventObserverHandle } from "../hooks/useStreamConnection";
import { getSessionBase } from "./SolverSession";
import { NavigationBehavior } from "./Navigation";

const PROJECTS_PAGE_SIZE = 20;

type ProjectBrowsingContextType = {
    projects: Project[];
    loadingProjects: boolean;
    page: number;
    haveMoreProjects: boolean;
    titleFilter: string;
    setTitleFilter: (filter: string) => void;
    sortByCreated: boolean;
    setSortByCreated: (sortByCreated: boolean) => void;
    sortAscending: boolean;
    setSortAscending: (ascending: boolean) => void;
    loadProjects: (org: string, repo: string) => void;
    loadMoreProjects: (org: string, repo: string) => void;
    resetBrowsingFilters: () => void;
};

const nullProjectBrowsingContext: ProjectBrowsingContextType = {
    projects: [],
    loadingProjects: false,
    page: 0,
    haveMoreProjects: true,
    titleFilter: "",
    setTitleFilter: () => {},
    sortByCreated: false,
    setSortByCreated: () => {},
    sortAscending: false,
    setSortAscending: () => {},
    loadProjects: () => {},
    loadMoreProjects: () => {},
    resetBrowsingFilters: () => {},
};

const ProjectBrowsingContext = createContext<ProjectBrowsingContextType>(nullProjectBrowsingContext);

const ProjectBrowsingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const activeProject = useProject();
    const updateActiveProject = useUpdateProject();
    const loadProject = useLoadProject();

    const [projects, setProjects] = useState<Project[]>([]);
    const [page, setPage] = useState<number>(0);
    const [haveMoreProjects, setHaveMoreProjects] = useState<boolean>(true);
    const [loadingProjects, setLoadingProjects] = useState<boolean>(true);

    const [titleFilter, setTitleFilter] = useState<string>("");
    const [sortByCreated, setSortByCreated] = useState<boolean>(false);
    const [sortAscending, setSortAscending] = useState<boolean>(false);

    const {
        activeRepo,
        activeRepoIsReady,
        addSolverInterfaceEventObserver,
        removeSolverInterfaceEventObserver,
        currentUser,
    } = useSolverInterfaceContext();

    useEffect(() => {
        if (!activeRepo) {
            setProjects([]);
        } else {
            loadProjects(activeRepo.org, activeRepo.name);
        }
    }, [activeRepo]);

    useEffect(() => {
        const handle: SolverInterfaceEventObserverHandle = addSolverInterfaceEventObserver(
            SolverInterfaceEventType.PROJECT_CREATED,
            async (solverInterfaceEvent: SolverInterfaceEvent) => {
                const projectCreatedEvent = solverInterfaceEvent as ProjectCreatedEvent;
                const { project_id } = projectCreatedEvent;
                const [org, repo] = projectCreatedEvent.repo_name.split("/");

                setProjects((prevProjects) => {
                    if (!prevProjects.map((p) => p.project_id).includes(project_id)) {
                        getProjectBase({ org, repo, project_id }).then((newProject) => {
                            setProjects((prevProjects) => [newProject, ...prevProjects]);
                        });
                    }
                    return prevProjects;
                });
            }
        );

        return () => removeSolverInterfaceEventObserver(handle);
    }, []);

    useEffect(() => {
        const handle: SolverInterfaceEventObserverHandle = addSolverInterfaceEventObserver(
            SolverInterfaceEventType.PROJECT_VISIBILITY,
            async (solverInterfaceEvent: SolverInterfaceEvent) => {
                const visibilityEvent = solverInterfaceEvent as ProjectVisibilityEvent;
                const { project_id, visibility } = visibilityEvent;
                const [org, repo] = visibilityEvent.repo_name.split("/");

                const projectInList = projects.find((p) => p.project_id === project_id);

                if (visibility === SessionVisibility.PRIVATE) {
                    // Remove project if it becomes private and it's not our own
                    if (projectInList && projectInList.owner !== currentUser?.id) {
                        setProjects((prevProjects) => prevProjects.filter((p) => p.project_id !== project_id));
                    }
                } else {
                    // For public projects, update visibility if we have it
                    if (projectInList) {
                        setProjects((prevProjects) =>
                            prevProjects.map((p) => (p.project_id === project_id ? { ...p, visibility } : p))
                        );
                    } else {
                        // If we don't have the project and it's now public, fetch it
                        const newProject = await getProjectBase({ org, repo, project_id });
                        setProjects((prevProjects) => [newProject, ...prevProjects]);
                    }
                }
            }
        );

        return () => removeSolverInterfaceEventObserver(handle);
    }, [projects, currentUser]);

    useEffect(() => {
        const handle: SolverInterfaceEventObserverHandle = addSolverInterfaceEventObserver(
            SolverInterfaceEventType.PROJECT_CHANGED,
            async (solverInterfaceEvent: SolverInterfaceEvent) => {
                const projectChangedEvent = solverInterfaceEvent as ProjectChangedEvent;
                const { project_id } = projectChangedEvent;
                const [org, repo] = projectChangedEvent.repo_name.split("/");

                const projectInList = projects.find((p) => p.project_id === project_id);
                const updatedActiveProject = activeProject?.project_id === project_id;

                if (updatedActiveProject) {
                    const [updatedProject, updatedPlanningSession, updatedExecutionGraph] = await Promise.all([
                        getProjectBase(activeProject.getInfo()),
                        getSessionBase({ org, repo, session_id: activeProject.planning_session_id }),
                        getProjectExecution(activeProject.getInfo()),
                    ]);

                    updateActiveProject(updatedProject, updatedPlanningSession, updatedExecutionGraph);

                    if (projectInList) {
                        setProjects((prevProjects) =>
                            prevProjects.map((p) => (p.project_id === project_id ? updatedProject : p))
                        );
                    }
                } else if (projectInList) {
                    const newProject = await getProjectBase({ org, repo, project_id });
                    setProjects((prevProjects) =>
                        prevProjects.map((p) => (p.project_id === project_id ? newProject : p))
                    );
                }
            }
        );

        return () => removeSolverInterfaceEventObserver(handle);
    }, [activeProject]);

    useEffect(() => {
        const handle: SolverInterfaceEventObserverHandle = addSolverInterfaceEventObserver(
            SolverInterfaceEventType.PROJECT_DELETED,
            async (solverInterfaceEvent: SolverInterfaceEvent) => {
                const projectId = (solverInterfaceEvent as ProjectDeletedEvent).project_id;

                setProjects((prevProjects) => prevProjects.filter((p) => p.project_id !== projectId));

                if (activeProject?.project_id === projectId) {
                    loadProject(undefined, NavigationBehavior.PUSH);
                }
            }
        );

        return () => removeSolverInterfaceEventObserver(handle);
    }, [activeProject?.project_id]);

    const loadProjects = async (org: string, repo: string) => {
        setPage(0);
        setHaveMoreProjects(true);
        setLoadingProjects(true);

        const fetchedProjects = await getProjectsList(
            org,
            repo,
            titleFilter,
            sortByCreated,
            sortAscending,
            0,
            PROJECTS_PAGE_SIZE
        );
        setProjects(fetchedProjects);
        setLoadingProjects(false);
    };

    const loadMoreProjects = async (org: string, repo: string) => {
        if (!haveMoreProjects) {
            return;
        }

        const currentPage = page;
        setPage((prevPage) => prevPage + 1);

        setLoadingProjects(true);
        const newProjects = await getProjectsList(
            org,
            repo,
            titleFilter,
            sortByCreated,
            sortAscending,
            currentPage + 1,
            PROJECTS_PAGE_SIZE
        );

        if (newProjects.length === 0) {
            setHaveMoreProjects(false);
        } else {
            setProjects((prevProjects) => [...prevProjects, ...newProjects]);
        }

        setLoadingProjects(false);
    };

    const resetBrowsingFilters = async () => {
        if (titleFilter === "" && !sortByCreated && !sortAscending) {
            return;
        }

        setTitleFilter("");
        setSortByCreated(false);
        setSortAscending(false);

        if (activeRepo && activeRepoIsReady) {
            loadProjects(activeRepo.org, activeRepo.name);
        }
    };

    const value = {
        projects,
        loadingProjects,
        page,
        haveMoreProjects,
        titleFilter,
        setTitleFilter,
        sortByCreated,
        setSortByCreated,
        sortAscending,
        setSortAscending,
        loadProjects,
        loadMoreProjects,
        resetBrowsingFilters,
    };

    return <ProjectBrowsingContext.Provider value={value}>{children}</ProjectBrowsingContext.Provider>;
};

const useProjectBrowsingContext = () => {
    return React.useContext(ProjectBrowsingContext);
};

export type { ProjectBrowsingContextType };
export { ProjectBrowsingProvider, useProjectBrowsingContext };
