import JSZip from "jszip";
import { type NotificationInstance } from "antd/lib/notification/interface";

/**
 * Interface for file information to be included in a ZIP download
 */
export interface ZipFileInfo {
    file_path: string;
    contents: string;
}

/**
 * Downloads a collection of files as a ZIP archive
 *
 * @param files Array of file information objects to be included in the ZIP
 * @param zipFilename Name of the ZIP file to be downloaded
 * @param notificationApi Optional notification API for displaying success/error messages
 * @returns Promise that resolves when the download is complete
 */
export async function downloadFilesAsZip(
    files: ZipFileInfo[],
    zipFilename: string,
    notificationApi?: NotificationInstance
): Promise<void> {
    try {
        // Check if there are any files to download
        if (files.length === 0) {
            notificationApi?.info({
                message: "No files to download",
                description: "There are no files to download.",
                placement: "bottomRight",
                duration: 5,
            });
            return;
        }

        // Create a new zip archive
        const zip = new JSZip();

        // Add each file to the zip
        files.forEach((file) => {
            zip.file(file.file_path, file.contents);
        });

        // Generate the zip file
        const blob = await zip.generateAsync({ type: "blob" });

        // Create a download link and trigger it
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = zipFilename;
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(url);

        // Show success notification if API provided
        notificationApi?.success({
            message: "Download complete",
            description: `Downloaded ${files.length} file${files.length !== 1 ? "s" : ""} as ZIP.`,
            placement: "bottomRight",
            duration: 5,
        });
    } catch (error) {
        console.error(error);
        notificationApi?.error({
            message: "Download failed",
            description: "Failed to download files as ZIP.",
            placement: "bottomRight",
        });
        throw error;
    }
}
