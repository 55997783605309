import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, notification, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Repo, getBranches } from "../data/Repos";
import { CreateProjectRequest, Project } from "../data/SolverProjects";
import { SessionVisibility } from "../data/User";
import BranchDropdownSelector from "./BranchDropdownSelector";
import "./NewProjectButton.scss";

interface NewProjectButtonProps {
    onProjectCreated: (request: CreateProjectRequest) => Promise<Project>;
    disabled?: boolean;
    branches: string[];
    isLoading?: boolean;
    repo: Repo | null;
    onBranchesUpdated?: (branches: string[]) => void;
}

const NewProjectButton: React.FC<NewProjectButtonProps> = ({
    onProjectCreated,
    disabled,
    branches,
    isLoading = false,
    repo,
    onBranchesUpdated,
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [creating, setCreating] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState<string>("");
    const [api, contextHolder] = notification.useNotification();
    const [isFetchingBranches, setIsFetchingBranches] = useState(false);
    const [branchFetchComplete, setBranchFetchComplete] = useState(false);

    useEffect(() => {
        if (!isModalVisible || !repo || !branches.length) {
            setBranchFetchComplete(false);
            return;
        }

        const refreshBranches = async () => {
            setIsFetchingBranches(true);
            try {
                const latestBranches = await getBranches(repo.org, repo.name, 1000);
                if (onBranchesUpdated) {
                    onBranchesUpdated(latestBranches);
                }
                setBranchFetchComplete(true);
            } catch (error) {
                console.error("Failed to fetch latest branches:", error);
                api.error({
                    message: "Failed to refresh branches",
                    description: error instanceof Error ? error.message : "An unknown error occurred",
                    placement: "bottomRight",
                });
            } finally {
                setIsFetchingBranches(false);
            }
        };

        refreshBranches();
    }, [isModalVisible, repo, branches.length, api, onBranchesUpdated]);

    const handleSubmit = async () => {
        try {
            setCreating(true);
            const values = await form.validateFields();

            const request: CreateProjectRequest = {
                name: values.name,
                visibility: SessionVisibility.PRIVATE,
                ref: values.ref,
            };

            await onProjectCreated(request);
            setIsModalVisible(false);
            form.resetFields();
        } catch (error) {
            api.error({
                message: "Failed to create project",
                description: error instanceof Error ? error.message : "An error occurred",
                placement: "bottomRight",
            });
        } finally {
            setCreating(false);
        }
    };

    return (
        <>
            {contextHolder}
            <Tooltip title="Create new project" arrow={false} placement="right">
                <Button
                    className="new-project-button"
                    icon={<PlusCircleOutlined />}
                    onClick={() => {
                        form.resetFields(); // Clear previous values
                        setSelectedBranch(repo?.default_branch || "");
                        form.setFieldsValue({
                            visibility: SessionVisibility.PRIVATE,
                        });
                        setIsModalVisible(true);
                    }}
                    disabled={disabled}
                />
            </Tooltip>
            <Modal
                title="Create New Project"
                open={isModalVisible}
                onOk={handleSubmit}
                okButtonProps={{
                    disabled:
                        creating ||
                        form.getFieldValue("name")?.trim().length === 0 ||
                        form.getFieldValue("ref")?.trim().length === 0,
                    loading: creating,
                }}
                onCancel={() => setIsModalVisible(false)}
                confirmLoading={creating}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[
                            { required: true, message: "Please enter a project name" },
                            { whitespace: true, message: "Name cannot be empty" },
                        ]}
                    >
                        <Input placeholder="Enter project name" />
                    </Form.Item>
                    <Form.Item
                        name="ref"
                        label="Branch"
                        initialValue={repo?.default_branch}
                        rules={[
                            { required: true, message: "Please select a branch" },
                            {
                                validator: async () => {
                                    if (!selectedBranch && !repo?.default_branch) {
                                        throw new Error("Please select a valid branch");
                                    }
                                },
                            },
                        ]}
                    >
                        <BranchDropdownSelector
                            repo={repo}
                            branches={branches}
                            isLoading={isLoading}
                            onBranchSelect={(branch: string) => {
                                setSelectedBranch(branch);
                                form.setFieldValue("ref", branch);
                            }}
                            instructions="Select a branch to use as the base revision of the new project"
                            value={selectedBranch || repo?.default_branch}
                            isFetchingBranches={isFetchingBranches}
                            branchFetchComplete={branchFetchComplete}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default NewProjectButton;
