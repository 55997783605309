import { solverInterfaceApiAxios } from "./SolverInterfaceConstants";
import axios from "axios";
import React, { createContext, useEffect, useState } from "react";

import { useSolverInterfaceContext } from "./SolverInterface";

interface DockerExecutionSettings {
    name: string;
    description?: string;
    is_public?: boolean;
    docker_registry_url?: string;
    docker_image?: string;
    docker_tag?: string;
    repo_mount_point?: string;
    docker_username?: string;
    docker_password?: string;
    shell_executable?: string;
}

export type ImageResponse = {
    image_type: string;
    image_id?: string;
    name?: string;
    description?: string;
    user_id?: string;
    user_name?: string;
    user_avatar_url?: string;
    is_public?: boolean;
    created?: string;
    docker_registry_url?: string;
    docker_image?: string;
    docker_tag?: string;
    repo_mount_point?: string;
    shell_executable?: string;
    docker_username?: string;
    docker_password?: string;
    is_ready?: boolean;
};

export interface ListDefaultExecutionImagesResult {
    defaultExecutionSettings: CombinedImageSettings[];
    error: boolean;
}

export type DefaultExecutionSettings = {
    image_id: string;
    title: string;
    detail: string;
    image_type: "default";
};

export type ExportedExecutionSettings = {
    image_id: string;
    name: string;
    description?: string;
    user_id: string;
    user_name: string;
    user_avatar_url: string;
    is_public: boolean;
    created: string;
    is_ready: boolean;
};

export type ExportedImageSettings = Omit<ExportedExecutionSettings, "description"> & {
    title: string;
    detail: string;
    image_type: "exported";
    description?: string;
};

export type CombinedImageSettings = DefaultExecutionSettings | ExportedImageSettings;

type ExecutionEnvironmentVariable = {
    name: string;
    value: string | null;
    secret: boolean;
};

enum ExecutionImageType {
    Default = "default",
    Exported = "exported",
    Custom = "custom",
    None = "none",
}

export type ConfiguredExecutionImage =
    | CustomConfiguredExecutionImage
    | DefaultConfiguredExecutionImage
    | ExportedConfiguredExecutionImage
    | NoneConfiguredExecutionImage;

export type CustomConfiguredExecutionImage = {
    type: ExecutionImageType.Custom;
    settings: DockerExecutionSettings;
};

export type DefaultConfiguredExecutionImage = {
    type: ExecutionImageType.Default;
    settings: DefaultExecutionSettings;
};

export type ExportedConfiguredExecutionImage = {
    type: ExecutionImageType.Exported;
    settings: ExportedExecutionSettings;
};

export type NoneConfiguredExecutionImage = {
    type: ExecutionImageType.None;
};

export type ExecutionSettingsContextType = {
    currentExecutionImage: ConfiguredExecutionImage;
    updateExecutionImage: (image: ConfiguredExecutionImage) => Promise<void>;
    updatingImage: boolean;
    setUpdatingImage: (value: boolean) => void;
    resetExecutionImage: () => Promise<void>;
    imageError: string | undefined;
    loadingImage: boolean;
    availableImages: CombinedImageSettings[];
    loadingAvailableImages: boolean;
    availableImagesError: string | undefined;
    refreshAvailableImages: () => Promise<void>;
    executionEnvironment: ExecutionEnvironmentVariable[];
    addEnvironmentVariable: (name: string, value: string | null, secret: boolean) => Promise<void>;
    deleteEnvironmentVariable: (name: string) => Promise<void>;
    resetExecutionEnvironment: () => Promise<void>;
    environmentError: string | undefined;
    loadingEnvironment: boolean;
};

const ExecutionSettingsContext = createContext<ExecutionSettingsContextType | undefined>(undefined);

const ExecutionSettingsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { activeRepo } = useSolverInterfaceContext();
    const [currentExecutionImage, setCurrentExecutionImage] = useState<ConfiguredExecutionImage>({
        type: ExecutionImageType.None,
    });
    const [loadingImage, setLoadingImage] = useState<boolean>(false);
    const [imageError, setImageError] = useState<string | undefined>(undefined);

    const [availableImages, setAvailableImages] = useState<CombinedImageSettings[]>([]);
    const [loadingAvailableImages, setLoadingAvailableImages] = useState<boolean>(false);
    const [availableImagesError, setAvailableImagesError] = useState<string | undefined>(undefined);

    const [updatingImage, setUpdatingImage] = useState<boolean>(false);

    const [executionEnvironment, setExecutionEnvironment] = useState<ExecutionEnvironmentVariable[]>([]);
    const [loadingEnvironment, setLoadingEnvironment] = useState<boolean>(false);
    const [environmentError, setEnvironmentError] = useState<string | undefined>(undefined);

    useEffect(() => {
        const refreshExecutionImage = async () => {
            if (!activeRepo) {
                setCurrentExecutionImage({ type: ExecutionImageType.None });
            } else {
                setLoadingImage(true);
                setImageError(undefined);
                try {
                    const image = await getConfiguredExecutionImage(activeRepo.org, activeRepo.name);
                    setCurrentExecutionImage(image);
                } catch (error) {
                    console.log(error);
                    setImageError("Failed to load execution image settings");
                    setCurrentExecutionImage({ type: ExecutionImageType.None });
                }
                setLoadingImage(false);
            }
        };

        refreshExecutionImage();
    }, [activeRepo]);

    const refreshAvailableImages = async () => {
        if (!activeRepo) {
            setAvailableImages([]);
            return;
        }
        setLoadingAvailableImages(true);
        setAvailableImagesError(undefined);
        try {
            const { defaultExecutionSettings, error } = await listAvailableExecutionImages(
                activeRepo.org,
                activeRepo.name
            );

            if (!error) {
                setAvailableImages(defaultExecutionSettings);
            } else {
                setAvailableImagesError("Failed to load available execution images");
            }
        } catch (error) {
            console.log(error);
            setAvailableImagesError("Failed to load available execution images");
        }
        setLoadingAvailableImages(false);
    };

    useEffect(() => {
        refreshAvailableImages();
    }, [activeRepo]);

    useEffect(() => {
        const refreshExecutionEnvironment = async () => {
            if (!activeRepo) {
                return;
            }

            setLoadingEnvironment(true);
            const getEnvironmentResult = await getExecutionEnvironment(activeRepo.org, activeRepo.name);
            setExecutionEnvironment(getEnvironmentResult.environment);
            setEnvironmentError(getEnvironmentResult.error ? "Failed to load environment variables" : undefined);
            setLoadingEnvironment(false);
        };

        refreshExecutionEnvironment();
    }, [activeRepo]);

    const updateExecutionImage = async (newImage: ConfiguredExecutionImage) => {
        if (!activeRepo) {
            console.warn("No active repo");
            return Promise.resolve();
        }

        setUpdatingImage(true);
        const result = await setExecutionImage(activeRepo.org, activeRepo.name, newImage);

        if (result.success) {
            setCurrentExecutionImage(newImage);
            setImageError(undefined);
        } else {
            setImageError(result.message);
        }
        setUpdatingImage(false);
    };

    const resetExecutionImage = async () => {
        if (!activeRepo) {
            console.warn("No active repo");
            return Promise.resolve();
        }

        if (await deleteExecutionImage(activeRepo.org, activeRepo.name)) {
            setCurrentExecutionImage({ type: ExecutionImageType.None });
            setImageError(undefined);
        } else {
            setImageError("Failed to delete image setting");
        }
    };

    const addEnvironmentVariable = async (name: string, value: string | null, secret: boolean) => {
        if (!activeRepo) {
            console.warn("No active repo");
            return Promise.resolve();
        }

        const success = await updateExecutionEnvironment(
            activeRepo.org,
            activeRepo.name,
            [{ name, value, secret }],
            []
        );
        if (success) {
            setExecutionEnvironment((prev) => {
                return [...prev.filter((variable) => variable.name !== name), { name, value, secret }];
            });
            setEnvironmentError(undefined);
        } else {
            setEnvironmentError("Failed to add environment variable");
        }
    };

    const deleteEnvironmentVariable = async (name: string) => {
        if (!activeRepo) {
            console.warn("No active repo");
            return Promise.resolve();
        }

        const success = await updateExecutionEnvironment(activeRepo.org, activeRepo.name, [], [name]);
        if (success) {
            setExecutionEnvironment((prev) => prev.filter((variable) => variable.name !== name));
            setEnvironmentError(undefined);
        } else {
            setEnvironmentError("Failed to delete environment variable");
        }
    };

    const resetExecutionEnvironment = async () => {
        if (!activeRepo) {
            console.warn("No active repo");
            return Promise.resolve();
        }

        if (await deleteExecutionEnvironment(activeRepo.org, activeRepo.name)) {
            setExecutionEnvironment([]);
            setEnvironmentError(undefined);
        } else {
            setEnvironmentError("Failed to reset environment variables");
        }
    };

    return (
        <ExecutionSettingsContext.Provider
            value={{
                currentExecutionImage,
                updateExecutionImage,
                updatingImage,
                setUpdatingImage,
                resetExecutionImage,
                imageError,
                loadingImage,
                availableImages,
                loadingAvailableImages,
                availableImagesError,
                refreshAvailableImages,
                executionEnvironment,
                addEnvironmentVariable,
                deleteEnvironmentVariable,
                resetExecutionEnvironment,
                environmentError,
                loadingEnvironment,
            }}
        >
            {children}
        </ExecutionSettingsContext.Provider>
    );
};

const useExecutionSettingsContext = () => {
    const ctx = React.useContext(ExecutionSettingsContext);
    if (!ctx) {
        throw new Error("useExecutionSettingsContext must be used within a ExecutionSettingsContextProvider");
    }
    return ctx;
};

type GetCustomExecutionImageResult = {
    dockerExecutionSettings?: DockerExecutionSettings;
    error: boolean;
};

const getConfiguredExecutionImage = async (org: string, repo: string): Promise<ConfiguredExecutionImage> => {
    try {
        const response = await solverInterfaceApiAxios.get<ImageResponse>(`${org}/${repo}/execution/image`);
        if (response.status === 204) {
            return { type: ExecutionImageType.None };
        }

        switch (response.data.image_type) {
            case "custom":
                return {
                    type: ExecutionImageType.Custom,
                    settings: {
                        name: response.data.name!,
                        description: response.data.description,
                        is_public: response.data.is_public || false,
                        docker_registry_url: response.data.docker_registry_url,
                        docker_image: response.data.docker_image,
                        docker_tag: response.data.docker_tag,
                        repo_mount_point: response.data.repo_mount_point,
                        shell_executable: response.data.shell_executable,
                        docker_username: response.data.docker_username,
                        docker_password: response.data.docker_password,
                    },
                };
            case "default":
                return {
                    type: ExecutionImageType.Default,
                    settings: {
                        image_id: response.data.image_id!,
                        title: response.data.name!,
                        detail: response.data.description || "",
                        image_type: "default",
                    } as DefaultExecutionSettings,
                };
            case "exported":
                return {
                    type: ExecutionImageType.Exported,
                    settings: {
                        image_id: response.data.image_id!,
                        name: response.data.name!,
                        title: response.data.name!,
                        detail: response.data.description || "",
                        image_type: "exported",
                        description: response.data.description,
                        user_id: response.data.user_id!,
                        user_name: response.data.user_name!,
                        user_avatar_url: response.data.user_avatar_url!,
                        is_public: response.data.is_public!,
                        created: response.data.created!,
                    } as ExportedImageSettings,
                };
            default:
                return { type: ExecutionImageType.None };
        }
    } catch (error) {
        console.log(error);
        return { type: ExecutionImageType.None };
    }
};

const listAvailableExecutionImages = async (org: string, repo: string): Promise<ListDefaultExecutionImagesResult> => {
    return await solverInterfaceApiAxios
        .get<ImageResponse[]>(`${org}/${repo}/execution/image/list`)
        .then((response) => {
            const images = (response.data || []).map((img) => {
                const base = {
                    image_id: img.image_id!,
                    title: img.name!,
                    detail: img.description || "",
                };

                if (img.image_type === "exported") {
                    return {
                        ...base,
                        image_type: "exported" as const,
                        user_id: img.user_id!,
                        user_name: img.user_name!,
                        user_avatar_url: img.user_avatar_url!,
                        is_public: img.is_public!,
                        created: img.created!,
                        name: img.name!,
                        is_ready: img.is_ready ?? true, // Default to true for backward compatibility
                    } satisfies ExportedImageSettings;
                }

                return {
                    ...base,
                    image_type: "default" as const,
                } satisfies DefaultExecutionSettings;
            });
            return { defaultExecutionSettings: images, error: false };
        })
        .catch((error) => {
            console.log(error);
            return { defaultExecutionSettings: [], error: true };
        });
};

const setExecutionImage = async (
    org: string,
    repo: string,
    image: ConfiguredExecutionImage
): Promise<{ success: boolean; message?: string }> => {
    try {
        let payload;
        switch (image.type) {
            case ExecutionImageType.Custom:
                payload = {
                    image_type: "custom",
                    ...image.settings,
                };
                break;
            case ExecutionImageType.Default:
                payload = {
                    image_type: "default",
                    image_id: image.settings.image_id,
                };
                break;
            case ExecutionImageType.Exported:
                payload = {
                    image_type: "exported",
                    image_id: image.settings.image_id,
                };
                break;
            default:
                throw new Error("Invalid image type");
        }
        await solverInterfaceApiAxios.post(`${org}/${repo}/execution/image`, payload);
        return { success: true };
    } catch (error) {
        console.log(error);
        return { success: false, message: "Failed to update execution image" };
    }
};

const deleteExecutionImage = async (org: string, repo: string): Promise<boolean> => {
    try {
        await solverInterfaceApiAxios.delete(`${org}/${repo}/execution/image`);
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
};

type GetExecutionEnvironmentResult = {
    environment: ExecutionEnvironmentVariable[];
    error: boolean;
};

const getExecutionEnvironment = async (org: string, repo: string): Promise<GetExecutionEnvironmentResult> => {
    return await solverInterfaceApiAxios
        .get<ExecutionEnvironmentVariable[]>(`${org}/${repo}/execution/environment`)
        .then((response) => {
            if (response.status === 204) {
                return { environment: [], error: false };
            }

            return { environment: response.data, error: false };
        })
        .catch((error) => {
            console.log(error);
            return { environment: [], error: true };
        });
};

const updateExecutionEnvironment = async (
    org: string,
    repo: string,
    variablesToSet: ExecutionEnvironmentVariable[],
    variablesToDelete: string[]
): Promise<boolean> => {
    const variables = [...variablesToSet, ...variablesToDelete.map((name) => ({ name, value: null, secret: false }))];

    try {
        await solverInterfaceApiAxios.post(`${org}/${repo}/execution/environment`, variables);
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
};

const deleteExecutionEnvironment = async (org: string, repo: string): Promise<boolean> => {
    try {
        await solverInterfaceApiAxios.delete(`${org}/${repo}/execution/environment`);
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export { ExecutionImageType, ExecutionSettingsProvider, useExecutionSettingsContext };

export type { DockerExecutionSettings, ExecutionEnvironmentVariable };
