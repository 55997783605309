import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Drawer, Layout, notification, Tabs, Tag } from "antd";
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";

import { NavigationBehavior, navigateToProjects, navigateToSessions } from "../data/Navigation";
import "./SharedSider.scss";
import "./Sider.scss";
import NewSessionButton from "./NewSessionButton";
import { useSolverInterfaceContext, ViewMode } from "../data/SolverInterface";
import { deleteSession, Session, useSession, useLoadSession } from "../data/SolverSession";
import {
    Project,
    createProject,
    deleteProject,
    useProject,
    useLoadProject,
    CreateProjectRequest,
} from "../data/SolverProjects";
import ProjectsList from "./ProjectsList";
import SessionsList from "./SessionsList";
import { getBranches } from "../data/Repos";

import styleConstants from "../constants.module.scss";
import NewProjectButton from "./NewProjectButton";
import { TutorialAction } from "../data/SolverInterfaceEvent";
import GlowingTooltip from "./GlowingTooltip";

interface SiderProps {
    drawerOpen: boolean;
    onCloseDrawer: () => void;
}

const Sider: React.FC<SiderProps> = ({ drawerOpen, onCloseDrawer }) => {
    const activeSession = useSession();
    const loadSession = useLoadSession();
    const activeProject = useProject();
    const loadProject = useLoadProject();

    const { currentUser, activeRepo, viewMode, setViewMode } = useSolverInterfaceContext();

    const [api, contextHolder] = notification.useNotification();
    const [collapsed, setCollapsed] = useState(false);
    const [branches, setBranches] = useState<string[]>([]);
    const [loadingBranches, setLoadingBranches] = useState<boolean>(false);

    const [needsDrawerLayout, setNeedsDrawerLayout] = useState<boolean>(false);

    useLayoutEffect(() => {
        const updateLayoutPosition = () => {
            setNeedsDrawerLayout(window.innerWidth < parseInt(styleConstants.SMALL_SCREEN_WIDTH));
            onCloseDrawer();
        };

        updateLayoutPosition();

        window.addEventListener("resize", updateLayoutPosition);

        return () => {
            window.removeEventListener("resize", updateLayoutPosition);
        };
    }, []);

    const fetchBranches = useCallback(async () => {
        if (!activeRepo) return;

        setLoadingBranches(true);
        try {
            const fetchedBranches = await getBranches(activeRepo.org, activeRepo.name, 250);
            setBranches(fetchedBranches);
        } catch (error) {
            console.error(error);
            api.error({
                key: "load-branches-error",
                message: "Failed to load branches",
                placement: "bottomRight",
            });
        } finally {
            setLoadingBranches(false);
        }
    }, [activeRepo, api]);

    // Initial fetch when repo changes
    useEffect(() => {
        if (activeRepo) {
            fetchBranches();
        }
    }, [activeRepo, fetchBranches]);

    const handleBranchesUpdated = useCallback((newBranches: string[]) => {
        setBranches(newBranches);
    }, []);

    const buildList = () => {
        if (!activeRepo) {
            return null;
        } else {
            switch (viewMode) {
                case ViewMode.SESSIONS:
                    return buildSessionsList();
                case ViewMode.PROJECTS:
                    return buildProjectsList();
            }
        }
    };

    const buildSessionsList = () => {
        if (!activeRepo) {
            return null;
        }

        return (
            <SessionsList
                currentUser={currentUser}
                repo={activeRepo}
                activeSession={activeSession}
                onSwitchSession={async (s: Session) => {
                    onCloseDrawer();

                    if (s && s.session_id !== activeSession?.session_id) {
                        await loadSession(s.getInfo(), NavigationBehavior.PUSH);
                    }
                }}
                onDeleteSession={async (sessionToDelete: Session) => {
                    if (currentUser?.id !== sessionToDelete.user_id) return;

                    let deleted = false;
                    try {
                        const sessionInfo = sessionToDelete.getInfo();
                        deleted = await deleteSession(sessionInfo);
                    } catch (error) {
                        console.error("Failed to delete session:", error);
                    }

                    if (deleted && sessionToDelete.session_id === activeSession?.session_id) {
                        await loadSession(undefined, NavigationBehavior.PUSH);
                    }

                    if (!deleted) {
                        api.error({
                            message: "Failed to delete Session",
                            placement: "bottomRight",
                        });
                        return;
                    }
                }}
                notification={api}
            />
        );
    };

    const buildProjectsList = () => {
        if (!activeRepo) return null;

        return (
            <ProjectsList
                currentUser={currentUser}
                repo={activeRepo}
                activeProject={activeProject}
                onSwitchProject={(p: Project) => {
                    onCloseDrawer();

                    if (p.project_id !== activeProject?.project_id) {
                        return loadProject(p.getInfo(), NavigationBehavior.PUSH);
                    }
                    return Promise.resolve();
                }}
                onDeleteProject={async (projectToDelete: Project) => {
                    if (currentUser?.id !== projectToDelete.owner) return;

                    const deleted = await deleteProject(projectToDelete.getInfo());

                    if (deleted && projectToDelete.project_id === activeProject?.project_id) {
                        await loadProject(undefined, NavigationBehavior.PUSH);
                    }

                    if (!deleted) {
                        api.error({
                            message: "Failed to delete Project",
                            placement: "bottomRight",
                        });
                        return;
                    }
                }}
                notification={api}
            />
        );
    };

    const buildNewButton = () => {
        if (!activeRepo) {
            return null;
        }

        return (
            <div
                className={`sider-new-button-container ${collapsed ? "collapsed" : ""}`}
                onClick={(e) => e.stopPropagation()}
            >
                {buildNewButtonInternal()}
            </div>
        );
    };

    const buildNewButtonInternal = () => {
        switch (viewMode) {
            case ViewMode.SESSIONS:
                return buildNewSessionButton();
            case ViewMode.PROJECTS:
                return buildNewProjectButton();
        }
    };

    const buildNewSessionButton = () => {
        if (!activeRepo) {
            return null;
        }

        return (
            <GlowingTooltip
                configs={[
                    {
                        action: TutorialAction.HIGHLIGHT_CREATE_SESSION,
                        title: "Start a fresh conversation with Solver from any branch. Each session keeps its own changes separate, so you can explore different ideas safely.",
                        suppress: collapsed || needsDrawerLayout,
                    },
                ]}
                placement="bottomRight"
            >
                <NewSessionButton
                    repoOrg={activeRepo.org}
                    repoName={activeRepo.name}
                    repo={activeRepo}
                    onSessionCreated={async (sessionInfo) => {
                        onCloseDrawer();

                        await loadSession(sessionInfo, NavigationBehavior.PUSH);
                    }}
                    notification={api}
                    branches={branches}
                    isLoading={loadingBranches}
                    onBranchesUpdated={handleBranchesUpdated}
                />
            </GlowingTooltip>
        );
    };

    const buildNewProjectButton = () => {
        if (!activeRepo) {
            return null;
        }

        return (
            <NewProjectButton
                onProjectCreated={async (request: CreateProjectRequest) => {
                    const newProject = await createProject(activeRepo.org, activeRepo.name, request);
                    await loadProject(
                        { org: activeRepo.org, repo: activeRepo.name, project_id: newProject.project_id },
                        NavigationBehavior.PUSH
                    );
                    return newProject;
                }}
                branches={branches}
                isLoading={loadingBranches}
                repo={activeRepo}
                onBranchesUpdated={handleBranchesUpdated}
            />
        );
    };

    const buildViewModeTabs = () => {
        if (!activeRepo) {
            return null;
        }

        return (
            <Tabs
                className="sider-view-mode-tabs"
                tabBarGutter={16}
                activeKey={viewMode}
                items={[
                    { key: ViewMode.SESSIONS, label: "Sessions" },
                    {
                        key: ViewMode.PROJECTS,
                        label: (
                            <GlowingTooltip
                                configs={[
                                    {
                                        action: TutorialAction.HIGHLIGHT_PROJECTS,
                                        title: "Check out the Projects feature! Create a project to have Solver help you work on a multi-step task.",
                                        suppress: collapsed || needsDrawerLayout,
                                    },
                                ]}
                                placement="bottomLeft"
                            >
                                <span style={{ display: "flex", alignItems: "center" }}>
                                    Projects
                                    <Tag className="preview-badge sider-view-mode-tabs-badge">Preview</Tag>
                                </span>
                            </GlowingTooltip>
                        ),
                    },
                ]}
                onChange={(key: string) => {
                    const newViewMode = key as ViewMode;

                    setViewMode(key as ViewMode);

                    switch (newViewMode) {
                        case ViewMode.SESSIONS:
                            navigateToSessions(activeRepo.full_name, NavigationBehavior.PUSH);
                            break;
                        case ViewMode.PROJECTS:
                            navigateToProjects(activeRepo.full_name, NavigationBehavior.PUSH);
                            break;
                    }

                    // TODO: this may not be the right place for this.
                    loadSession(undefined, NavigationBehavior.NONE);
                    loadProject(undefined, NavigationBehavior.NONE);
                }}
            />
        );
    };

    const buildSider = () => {
        return (
            <>
                <div className="sider-container">
                    {(needsDrawerLayout || !collapsed) && buildViewModeTabs()}
                    <Layout.Sider
                        collapsible={!needsDrawerLayout}
                        collapsedWidth={needsDrawerLayout ? undefined : 40}
                        collapsed={collapsed && !needsDrawerLayout}
                        trigger={
                            needsDrawerLayout ? undefined : collapsed ? (
                                <RightOutlined className="sider-trigger-icon" />
                            ) : (
                                <LeftOutlined className="sider-trigger-icon" />
                            )
                        }
                        onCollapse={(value) => setCollapsed(value)}
                        width={320}
                        className={`sider ${collapsed && !needsDrawerLayout ? "sider-collapsed" : "sider-expanded"}`}
                    >
                        {(needsDrawerLayout || !collapsed) && buildList()}
                    </Layout.Sider>
                    {buildNewButton()}
                </div>
                {contextHolder}
            </>
        );
    };

    if (needsDrawerLayout) {
        return (
            <Drawer
                placement="left"
                open={drawerOpen}
                width={320}
                headerStyle={{ height: styleConstants.APP_HEADER_HEIGHT, minHeight: styleConstants.APP_HEADER_HEIGHT }}
                bodyStyle={{ padding: 0, overflow: "hidden" }}
                className="sider-drawer"
                onClose={onCloseDrawer}
            >
                {buildSider()}
            </Drawer>
        );
    } else {
        return buildSider();
    }
};

export default Sider;
