import { AudioOutlined, CloseOutlined, FunctionOutlined, LoadingOutlined } from "@ant-design/icons";
import { notification } from "antd";
import React, { useCallback, useRef, useState } from "react";
import { useLocalSettings } from "../data/LocalSettingsContext";
import { solverInterfaceApiAxios } from "../data/SolverInterfaceConstants";
import { AudioVisualizer } from "./AudioVisualizer";
import "./CustomAudioRecorder.scss";

interface CustomAudioRecorderProps {
    onTranscriptionComplete: (text: string) => void;
}

export const CustomAudioRecorder: React.FC<CustomAudioRecorderProps> = ({ onTranscriptionComplete }) => {
    const { settings } = useLocalSettings();
    const [isRecording, setIsRecording] = useState(false);
    const [isTranscribing, setIsTranscribing] = useState(false);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const chunksRef = useRef<Blob[]>([]);
    const streamRef = useRef<MediaStream | null>(null);

    // Use a memoized value to determine if recording is enabled
    const isEnabled = settings.voiceInputEnabled;

    const startRecording = useCallback(async () => {
        try {
            // Get audio stream with Safari-compatible settings
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    sampleRate: 44100,
                    channelCount: 1,
                    echoCancellation: true,
                    noiseSuppression: true,
                },
            });

            // Determine browser-appropriate MIME type
            const mimeType = MediaRecorder.isTypeSupported("audio/mp4") ? "audio/mp4" : "audio/webm;codecs=opus";

            // Create MediaRecorder with optimized settings
            streamRef.current = stream;
            const mediaRecorder = new MediaRecorder(stream, {
                mimeType,
                audioBitsPerSecond: 128000,
            });

            // Handle data as it becomes available
            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    chunksRef.current.push(event.data);
                }
            };

            // Use smaller timeslice for more frequent chunks (200ms)
            mediaRecorder.start(200);
            mediaRecorderRef.current = mediaRecorder;
            setIsRecording(true);
        } catch (error) {
            console.error("Error starting recording:", error);
            notification.error({
                message: "Recording Error",
                description: "Failed to start recording. Please ensure microphone permissions are granted.",
                placement: "bottomRight",
            });
        }
    }, []);

    const stopRecording = useCallback(async () => {
        if (!mediaRecorderRef.current) return;

        return new Promise<void>((resolve) => {
            const mediaRecorder = mediaRecorderRef.current!;

            mediaRecorder.onstop = async () => {
                try {
                    setIsTranscribing(true);

                    // Create final audio blob
                    const audioBlob = new Blob(chunksRef.current, {
                        type: mediaRecorder.mimeType,
                    });

                    // Prepare form data for upload
                    const formData = new FormData();
                    formData.append(
                        "audio",
                        audioBlob,
                        `audio.${mediaRecorder.mimeType.includes("mp4") ? "mp4" : "webm"}`
                    );

                    // Send to backend for transcription
                    const response = await solverInterfaceApiAxios.post("/transcribe", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });

                    if (response.data && response.data.transcription) {
                        onTranscriptionComplete(response.data.transcription);
                    } else {
                        throw new Error("No transcription in response");
                    }
                } catch (error) {
                    console.error("Transcription error:", error);
                    notification.error({
                        message: "Transcription Failed",
                        description: error instanceof Error ? error.message : "Failed to transcribe audio",
                        placement: "bottomRight",
                    });
                } finally {
                    // Cleanup
                    setIsTranscribing(false);
                    setIsRecording(false);
                    chunksRef.current = [];
                    mediaRecorderRef.current = null;
                    // Stop all tracks
                    mediaRecorder.stream.getTracks().forEach((track) => track.stop());
                }
                resolve();
            };

            mediaRecorder.stop();
        });
    }, [onTranscriptionComplete]);

    // Early return if voice input is disabled
    if (!isEnabled) return null;

    return (
        <div
            className={`safari-compatible-recorder ${isTranscribing ? "disabled" : ""} ${
                isRecording ? "recording" : ""
            }`}
            onClick={(e) => e.stopPropagation()}
        >
            <div className="recorder-container">
                {isTranscribing ? (
                    <LoadingOutlined className="transcribing-loader" />
                ) : (
                    <>
                        <div className={`record-button-container ${isRecording ? "recording" : ""}`}>
                            {isRecording && (
                                <button
                                    className="record-button discard"
                                    onClick={() => {
                                        if (mediaRecorderRef.current) {
                                            mediaRecorderRef.current.stream
                                                .getTracks()
                                                .forEach((track) => track.stop());
                                            mediaRecorderRef.current = null;
                                            streamRef.current = null;
                                            chunksRef.current = [];
                                            setIsRecording(false);
                                        }
                                    }}
                                >
                                    <CloseOutlined />
                                </button>
                            )}
                            {isRecording && streamRef.current && (
                                <AudioVisualizer stream={streamRef.current} isRecording={isRecording} />
                            )}
                            <button
                                className="record-button start-save"
                                onClick={isRecording ? stopRecording : startRecording}
                                disabled={isTranscribing}
                            >
                                {isRecording ? <FunctionOutlined /> : <AudioOutlined />}
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default CustomAudioRecorder;
