import axios, { AxiosError, AxiosResponse } from "axios";

// TODO: every API endpoint interface should probably live here
// because they're shared between SolverSession and SessionBrowsing.

const DEV_URL = "http://localhost:11933";
const ENG_URL = "https://interface.eng.laredolabs.com";
const PROD_URL = "https://interface.solverai.com";

const getRemoteUrl = (hostname: string) => {
    // We're in a development environment.
    if (hostname === "localhost") return DEV_URL;
    // app.eng.laredolabs.com => ENG_URL
    else if (hostname === "app.eng.laredolabs.com") return ENG_URL;
    // app.solverai.com => PROD_URL
    else if (hostname === "app.solverai.com") return PROD_URL;
    // Fall back to development.
    else return DEV_URL;
};

export const SOLVER_INTERFACE_URL_BASE = getRemoteUrl(window.location.hostname);
// An instance of axios to use with all /api endpoints.
export const solverInterfaceApiAxios = axios.create({
    baseURL: `${SOLVER_INTERFACE_URL_BASE}/api`,
    withCredentials: true,
});

axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
        if (error.response && error.response.status >= 500) {
            // Without this, axios will eat any 5XX, making us unable to handle them ourselves.
            return Promise.reject(error);
        }

        return Promise.reject(error);
    }
);
