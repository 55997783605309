export enum SessionVisibility {
    PRIVATE = "private",
    PUBLIC_READ_ONLY = "public_read_only",
    PUBLIC_READ_WRITE = "public_read_write",
}

export type User = {
    id: string;
    auth_type: AuthType;
    name: string;
    avatar_url: string;
    allowlisted: boolean;
    accepted_terms_and_conditions: boolean;
    default_session_visibility: SessionVisibility;
};

export enum AuthType {
    GitHub = "github",
    GitLab = "gitlab",
}

export const authTypeDisplayName = (authType: AuthType) => {
    switch (authType) {
        case AuthType.GitHub:
            return "GitHub";
        case AuthType.GitLab:
            return "GitLab";
    }
};

export const branchUrl = (authType: AuthType, repo: string, branch: string) => {
    switch (authType) {
        case AuthType.GitHub:
            return `https://github.com/${repo}/tree/${branch}`;
        case AuthType.GitLab:
            return `https://gitlab.com/${repo}/-/tree/${branch}`;
    }
};

const PROD_GITHUB_APP_URL = "https://github.com/apps/solver-app/installations/new";
const NON_PROD_GITHUB_APP_URL = "https://github.com/apps/solver-internal/installations/new";

export const vcsProviderRepoConfigUrl = (authType: AuthType) => {
    switch (authType) {
        case AuthType.GitHub:
            if (window.location.hostname === "app.solverai.com") return PROD_GITHUB_APP_URL;
            return NON_PROD_GITHUB_APP_URL;
        case AuthType.GitLab:
            return "";
    }
};
