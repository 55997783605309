import React, { createContext, useContext, useState } from "react";

export enum AppMenuState {
    None = "none",
    Execution = "execution",
    Subscription = "subscription",
    ApiIntegrations = "api-integrations",
    Settings = "settings",
}

export interface AppMenuStateContextType {
    isAppMenuOpen: boolean;
    appMenuState: AppMenuState;
    setIsAppMenuOpen: (open: boolean) => void;
    setAppMenuState: (state: AppMenuState) => void;
    openExecutionSettings: () => void;
}

const AppMenuStateContext = createContext<AppMenuStateContextType | undefined>(undefined);

export const AppMenuStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isAppMenuOpen, setIsAppMenuOpen] = useState(false);
    const [appMenuState, setAppMenuState] = useState(AppMenuState.None);

    const openExecutionSettings = () => {
        smoothlyTransitionToMenuState(AppMenuState.Execution);
    };

    // Differently sized menu content can cause a layout shift when the menu
    // opens, so we do a quick close/open to force a re-layout.
    const smoothlyTransitionToMenuState = (state: AppMenuState) => {
        setIsAppMenuOpen(false);

        setTimeout(() => {
            setAppMenuState(state);
            setIsAppMenuOpen(true);
        }, 100);
    };

    // When the menu closes, we wait a bit before resetting the state to
    // prevent the menu from jumping back to the default layout.
    // This avoids a layout shift when the menu closes.
    const smoothlyResetMenu = () => {
        setTimeout(() => {
            setAppMenuState(AppMenuState.None);
        }, 300);
    };

    return (
        <AppMenuStateContext.Provider
            value={{
                isAppMenuOpen,
                appMenuState,
                setIsAppMenuOpen: (open) => {
                    setIsAppMenuOpen(open);

                    if (!open) smoothlyResetMenu();
                },
                setAppMenuState: (state) => {
                    smoothlyTransitionToMenuState(state);
                },
                openExecutionSettings,
            }}
        >
            {children}
        </AppMenuStateContext.Provider>
    );
};

export const useAppMenuState = () => {
    const context = useContext(AppMenuStateContext);
    if (context === undefined) {
        throw new Error("useAppMenuState must be used within a AppMenuStateProvider");
    }
    return context;
};
