import React, { useState } from "react";
import { Tag, Card, Button } from "antd";
import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloudUploadOutlined,
    DeleteOutlined,
    LoadingOutlined,
} from "@ant-design/icons";

import { Session, SessionInfo, SessionStatus, sessionIsLoading } from "../data/SolverSession";
import { ExecutionGraph, Task, TaskEvaluation } from "../data/SolverProjects";
import { useExecutionGraphMapping } from "../data/useExecutionGraphMapping";

import { PushToRemoteButton, pushToRemote } from "./ActiveSessionControls";

import "./ProjectExecutionView.scss";
import classNames from "classnames";
import { NotificationInstance } from "antd/es/notification/interface";
import { ModalStaticFunctions } from "antd/es/modal/confirm";
import ResponsiveTooltip from "./ResponsiveTooltip";
import { AuthType, authTypeDisplayName } from "../data/User";

interface TaskCardProps {
    task: Task;
    taskIsDone: boolean;
    taskNeedsManualVerification: boolean;
    taskSession: Session | undefined;
    taskEvaluation: TaskEvaluation | undefined;
    onClickTaskSession: (sessionInfo: SessionInfo) => void;
    onEvaluateTaskSession: (projectTaskId: string, sessionId: string) => Promise<boolean>;
}

const TaskCard: React.FC<TaskCardProps> = ({
    task,
    taskIsDone,
    taskNeedsManualVerification,
    taskSession,
    taskEvaluation,
    onClickTaskSession,
    onEvaluateTaskSession,
}) => {
    const [isSubmittingEvaluation, setIsSubmittingEvaluation] = React.useState(false);

    const onSubmitEvaluation = async () => {
        if (!taskSession) return;
        if (!taskEvaluation) return;

        setIsSubmittingEvaluation(true);
        try {
            await onEvaluateTaskSession(task.project_task_id, taskSession.session_id);
        } finally {
            setIsSubmittingEvaluation(false);
        }
    };

    const getStatusIcon = (status: SessionStatus) => {
        switch (status) {
            case SessionStatus.READY:
                return <CheckCircleOutlined style={{ color: "#52c41a" }} />;
            case SessionStatus.PENDING:
                return <ClockCircleOutlined />;
            case SessionStatus.SOLVING:
            case SessionStatus.SUBMITTING_CANCEL:
            case SessionStatus.SUBMITTING_SOLVE:
            default:
                // TODO: a blue loading icon would be nice, but the one in
                // SessionCard is the default color, so for now we keep it consistent.
                return <LoadingOutlined />;
        }
    };

    const buildSessionTag = () => {
        if (!taskSession) {
            return <Tag>None</Tag>;
        }

        return (
            <Tag
                className="task-status-tag linkable-tag"
                icon={getStatusIcon(taskSession.status)}
                onClick={() => onClickTaskSession(taskSession.getInfo())}
            >
                {taskSession.title}
            </Tag>
        );
    };

    const buildTaskEvaluationTag = () => {
        if (taskIsDone) {
            return <Tag color="green">Completed</Tag>;
        } else if (taskNeedsManualVerification) {
            return <Tag color="orange">Needs verification</Tag>;
        } else if (taskSession && sessionIsLoading(taskSession.status)) {
            return <Tag color="blue">Task in progress</Tag>;
        }

        return <Tag>None</Tag>;
    };

    const buildTaskEvaluationButton = () => {
        if (!taskEvaluation || !taskNeedsManualVerification) {
            return null;
        }

        return (
            <Button
                className="active-session-controls-button"
                disabled={isSubmittingEvaluation}
                onClick={onSubmitEvaluation}
                icon={isSubmittingEvaluation ? <LoadingOutlined /> : <CheckCircleOutlined />}
            >
                Mark as verified
            </Button>
        );
    };

    let taskCardDecorationClassName = "";
    if (taskIsDone) {
        taskCardDecorationClassName = "completed";
    } else if (taskSession) {
        taskCardDecorationClassName = "has-session";
    } else {
        taskCardDecorationClassName = "no-session";
    }

    const taskCardClassName = classNames("task-card", taskCardDecorationClassName);

    return (
        <Card className={taskCardClassName}>
            <h4 className="task-card-summary">{task.summary}</h4>
            {(taskSession || taskEvaluation) && <div className="task-card-divider" />}
            <div className="task-status">Session: {buildSessionTag()}</div>
            {taskEvaluation && <div className="task-status">Task Evaluation: {buildTaskEvaluationTag()}</div>}
            {buildTaskEvaluationButton()}
        </Card>
    );
};

interface ProjectExecutionViewProps {
    executionGraph: ExecutionGraph;
    canDeleteProjectExecution: boolean;
    isDeletingProjectExecution: boolean;
    onDeleteProjectExecution: () => Promise<boolean>;
    onClickTaskSession: (sessionInfo: SessionInfo) => void;
    onEvaluateTaskSession: (projectTaskId: string, sessionId: string) => Promise<boolean>;
    notification: NotificationInstance;
    modal: Omit<ModalStaticFunctions, "warn">;
}

const ProjectExecutionView: React.FC<ProjectExecutionViewProps> = ({
    executionGraph,
    canDeleteProjectExecution,
    isDeletingProjectExecution,
    onDeleteProjectExecution,
    onClickTaskSession,
    onEvaluateTaskSession,
    notification,
    modal,
}) => {
    const {
        taskSessionsByTaskId,
        taskEvaluationsByTaskId,
        taskIsDone,
        taskNeedsManualVerification,
        exportableSession,
    } = useExecutionGraphMapping(executionGraph);

    const [isPushing, setIsPushing] = useState(false);

    const handlePush = () => {
        if (!exportableSession) return;
        setIsPushing(true);
        pushToRemote(exportableSession, notification, modal).finally(() => setIsPushing(false));
    };

    const buildPushToRemoteButton = () => {
        if (!exportableSession) {
            return buildDisabledPushToRemoteButtonForUncompletedTasks();
        }

        if (exportableSession.remote_branch_name) {
            return buildDisabledPushToRemoteButtonForAlreadyPushed();
        }

        return (
            <PushToRemoteButton
                session={exportableSession}
                disabled={false}
                onPush={handlePush}
                isPushing={isPushing}
            />
        );
    };

    const buildDisabledPushToRemoteButton = (tooltip: string) => {
        return (
            <ResponsiveTooltip title={tooltip} arrow={false}>
                <Button className="active-session-controls-button" icon={<CloudUploadOutlined />} disabled={true}>
                    Push to {authTypeDisplayName(AuthType.GitHub)}
                </Button>
            </ResponsiveTooltip>
        );
    };

    const buildDisabledPushToRemoteButtonForUncompletedTasks = () => {
        return buildDisabledPushToRemoteButton("All tasks must be completed first");
    };

    const buildDisabledPushToRemoteButtonForAlreadyPushed = () => {
        return buildDisabledPushToRemoteButton("Already pushed to remote");
    };

    return (
        <div className="project-execution-view">
            <div className="project-execution-header">
                {buildPushToRemoteButton()}
                <Button
                    className="active-session-controls-button"
                    disabled={!canDeleteProjectExecution}
                    onClick={onDeleteProjectExecution}
                    icon={isDeletingProjectExecution ? <LoadingOutlined /> : <DeleteOutlined />}
                >
                    Delete
                </Button>
            </div>
            <div className="task-list">
                {executionGraph.tasks.map((task) => (
                    <TaskCard
                        key={task.project_task_id}
                        task={task}
                        taskIsDone={taskIsDone(task)}
                        taskNeedsManualVerification={taskNeedsManualVerification(task)}
                        taskSession={taskSessionsByTaskId.get(task.project_task_id)}
                        taskEvaluation={taskEvaluationsByTaskId.get(task.project_task_id)}
                        onClickTaskSession={onClickTaskSession}
                        onEvaluateTaskSession={onEvaluateTaskSession}
                    />
                ))}
            </div>
        </div>
    );
};

export default ProjectExecutionView;
