import { ExecutionGraph, Task, TaskEvaluation } from "./SolverProjects";
import { Session, SessionStatus, sessionStubToSession } from "./SolverSession";

// Organizes task sessions and evaluations by task ID.

// TODO FIXME: This is used independently by ProjectExecutionView and ProjectProvider.
// It should be moved to a more general location.
export const useExecutionGraphMapping = (
    executionGraph: ExecutionGraph | undefined
): {
    taskSessionsByTaskId: Map<string, Session | undefined>;
    taskEvaluationsByTaskId: Map<string, TaskEvaluation | undefined>;
    taskIsDone: (task: Task) => boolean;
    taskNeedsManualVerification: (task: Task) => boolean;
    allTasksDone: boolean;
    anyTaskNeedsManualVerification: boolean;
    exportableSession: Session | undefined;
} => {
    if (!executionGraph) {
        return {
            taskSessionsByTaskId: new Map<string, Session | undefined>(),
            taskEvaluationsByTaskId: new Map<string, TaskEvaluation | undefined>(),
            taskIsDone: () => false,
            taskNeedsManualVerification: () => false,
            allTasksDone: false,
            anyTaskNeedsManualVerification: false,
            exportableSession: undefined,
        };
    }

    const taskSessionsByTaskId: Map<string, Session | undefined> = executionGraph.tasks.reduce((acc, task) => {
        const sessionStub = executionGraph.task_sessions.find((session) => session.session_id === task.session_id);

        acc.set(task.project_task_id, sessionStub ? sessionStubToSession(sessionStub) : undefined);

        return acc;
    }, new Map<string, Session | undefined>());

    const taskEvaluationsByTaskId: Map<string, TaskEvaluation | undefined> = executionGraph.tasks.reduce(
        (acc, task) => {
            acc.set(
                task.project_task_id,
                executionGraph.task_evaluations.find((evaluation) => evaluation.task_id === task.project_task_id)
            );

            return acc;
        },
        new Map<string, TaskEvaluation | undefined>()
    );

    const taskIsDone = (task: Task) => {
        const session = taskSessionsByTaskId.get(task.project_task_id);
        const evaluation = taskEvaluationsByTaskId.get(task.project_task_id);

        if (!session || session.status !== SessionStatus.READY) return false;

        if (!evaluation) return false;

        return evaluation.success_criteria_met && evaluation.verification_plan_met && !evaluation.indeterminate;
    };

    const taskNeedsManualVerification = (task: Task) => {
        const session = taskSessionsByTaskId.get(task.project_task_id);
        const evaluation = taskEvaluationsByTaskId.get(task.project_task_id);

        if (!session || session.status !== SessionStatus.READY) return false;

        if (!evaluation) return false;

        return !evaluation.success_criteria_met || !evaluation.verification_plan_met || evaluation.indeterminate;
    };

    const allTasksDone = executionGraph.tasks.every((task) => taskIsDone(task));
    const anyTaskNeedsManualVerification = executionGraph.tasks.some((task) => taskNeedsManualVerification(task));

    const findExportableSession = () => {
        if (!allTasksDone) return undefined;
        if (!executionGraph.tasks.length) return undefined;

        const finalTask = executionGraph.tasks[executionGraph.tasks.length - 1];
        if (!finalTask) return undefined;
        if (!finalTask.session_id) return undefined;

        return taskSessionsByTaskId.get(finalTask.project_task_id);
    };

    return {
        taskSessionsByTaskId,
        taskEvaluationsByTaskId,
        taskIsDone,
        taskNeedsManualVerification,
        allTasksDone,
        anyTaskNeedsManualVerification,
        exportableSession: findExportableSession(),
    };
};
