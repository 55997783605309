"use client";
import React from "react";

import Convert from "ansi-to-html";
import { Typography, Button, message } from "antd";
import { CopyOutlined } from "@ant-design/icons";

import "./Terminal.scss";
import classNames from "classnames";

const convert = new Convert({ newline: true, escapeXML: true });

// Remove ANSI control sequences from text
const cleanAnsiText = (text: string): string => {
    // Remove ANSI escape sequences
    return text.replace(/\u001b\[\d+m/g, "");
};

const CopyButton: React.FC<{ content: string }> = ({ content }) => {
    const [messageApi, contextHolder] = message.useMessage();

    const handleCopy = async (e: React.MouseEvent) => {
        // Prevent event propagation to parent elements
        e.stopPropagation();

        try {
            // Clean the text before copying
            const cleanedContent = cleanAnsiText(content);
            await navigator.clipboard.writeText(cleanedContent);
            messageApi.success("Copied to clipboard");
        } catch (err) {
            messageApi.error("Failed to copy to clipboard");
        }
    };

    return (
        <>
            {contextHolder}
            <Button type="text" icon={<CopyOutlined />} onClick={handleCopy} className="terminal-copy-button" />
        </>
    );
};
interface TerminalProps {
    command?: string;
    stdout?: string;
    stderr?: string;
    exitCode: number;
    completed: boolean;
    error_msg?: string;
}

const Terminal: React.FC<TerminalProps> = ({ command, stdout, stderr, exitCode, completed, error_msg }) => {
    const stdoutRef = React.useRef<HTMLPreElement>(null);
    const stderrRef = React.useRef<HTMLPreElement>(null);
    const [shouldAutoScrollStdout, setShouldAutoScrollStdout] = React.useState(true);
    const [shouldAutoScrollStderr, setShouldAutoScrollStderr] = React.useState(true);

    const isNearBottom = (element: HTMLElement) => {
        const threshold = 30; // pixels from bottom to consider "near bottom"
        return element.scrollHeight - element.scrollTop - element.clientHeight < threshold;
    };

    const handleScroll = (element: HTMLPreElement | null, setShouldAutoScroll: (value: boolean) => void) => {
        if (element) {
            setShouldAutoScroll(isNearBottom(element));
        }
    };

    React.useEffect(() => {
        if (stdoutRef.current && shouldAutoScrollStdout) {
            stdoutRef.current.scrollTop = stdoutRef.current.scrollHeight;
        }
    }, [stdout, shouldAutoScrollStdout]);

    React.useEffect(() => {
        if (stderrRef.current && shouldAutoScrollStderr) {
            stderrRef.current.scrollTop = stderrRef.current.scrollHeight;
        }
    }, [stderr, shouldAutoScrollStderr]);

    const stderrTerminalClasses = classNames("terminal", {
        "terminal-error": exitCode !== 0,
    });

    return (
        <div>
            {command && (
                <div className="terminal-wrapper">
                    <pre
                        ref={stdoutRef}
                        className="terminal"
                        onScroll={(e) => handleScroll(e.currentTarget as HTMLPreElement, setShouldAutoScrollStdout)}
                    >
                        $ {command}
                        <br />
                        <span dangerouslySetInnerHTML={{ __html: stdout ? convert.toHtml(stdout) : "" }} />
                    </pre>
                    <CopyButton content={`$ ${command}\n${stdout || ""}`} />
                </div>
            )}
            {stderr && (
                <div>
                    <Typography.Text className="terminal-label">Standard error stream output:</Typography.Text>
                    <div className="terminal-wrapper">
                        <pre
                            ref={stderrRef}
                            className={stderrTerminalClasses}
                            onScroll={(e) => handleScroll(e.currentTarget as HTMLPreElement, setShouldAutoScrollStderr)}
                        >
                            <span dangerouslySetInnerHTML={{ __html: convert.toHtml(stderr) }} />
                        </pre>
                        <CopyButton content={stderr} />
                    </div>
                </div>
            )}
            {completed && (
                <Typography.Text className="terminal-label">
                    {error_msg ? <span style={{ fontStyle: "italic" }}>{error_msg}</span> : `Exit Code: ${exitCode}`}
                </Typography.Text>
            )}
        </div>
    );
};

export default Terminal;
