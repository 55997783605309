import { SessionVisibility } from "./User";

export enum SolverInterfaceEventType {
    DISCONNECT = "disconnected",
    SESSION_CREATED = "session_created",
    SESSION_DELETED = "session_deleted",
    SESSION_STATUS = "session_status",
    SESSION_UPDATED = "session_updated",
    SESSION_VISIBILITY = "session_visibility",
    TRACE_EVENT = "trace_event",
    PROJECT_CREATED = "project_created",
    PROJECT_DELETED = "project_deleted",
    PROJECT_CHANGED = "project_changed",
    PROJECT_VISIBILITY = "project_visibility",
    TUTORIAL_NUGGET = "tutorial_nugget",
}

interface SolverInterfaceEventBase {
    msg_type: SolverInterfaceEventType;
    repo_name: string;
}

export enum TutorialAction {
    EXPLAIN_FOLLOWUP = "explain_followup",
    EXPLAIN_CREDITS = "explain_credits",
    EXPLAIN_SURCHARGE = "explain_surcharge",
    EXPLAIN_SYNC = "explain_sync",

    HIGHLIGHT_SETTINGS = "highlight_settings",
    HIGHLIGHT_SUBSCRIPTION = "highlight_subscription",
    HIGHLIGHT_GITHUB = "highlight_github",
    HIGHLIGHT_PROJECTS = "highlight_projects",
    HIGHLIGHT_CHANGES = "highlight_changes",
    HIGHLIGHT_CREATE_SESSION = "highlight_create_session",
    CONNECT_YOUR_REPO = "connect_your_repo",

    // Webapp-only action to highlight the subscription menu option after
    // HIGHLIGHT_SUBSCRIPTION.
    HIGHLIGHT_SUBSCRIPTION_MENU_OPTION = "highlight_subscription_menu_option",
}

export interface TutorialNuggetEvent extends SolverInterfaceEventBase {
    msg_type: SolverInterfaceEventType.TUTORIAL_NUGGET;
    tutorial_action: TutorialAction;
}

interface ProjectEventBase extends SolverInterfaceEventBase {
    project_id: string;
}

export interface ProjectCreatedEvent extends ProjectEventBase {
    msg_type: SolverInterfaceEventType.PROJECT_CREATED;
}

export interface ProjectDeletedEvent extends ProjectEventBase {
    msg_type: SolverInterfaceEventType.PROJECT_DELETED;
}

export interface ProjectChangedEvent extends ProjectEventBase {
    msg_type: SolverInterfaceEventType.PROJECT_CHANGED;
}

export interface ProjectVisibilityEvent extends ProjectEventBase {
    msg_type: SolverInterfaceEventType.PROJECT_VISIBILITY;
    visibility: SessionVisibility;
}

export interface SessionEventBase extends SolverInterfaceEventBase {
    session_id: string;
    project_id: string | undefined;
}

export interface SessionCreatedEvent extends SessionEventBase {
    msg_type: SolverInterfaceEventType.SESSION_CREATED;
}

export interface SessionDeletedEvent extends SessionEventBase {
    msg_type: SolverInterfaceEventType.SESSION_DELETED;
}

export interface SessionStatusEvent extends SessionEventBase {
    msg_type: SolverInterfaceEventType.SESSION_STATUS;
    status: string;
    turn_id: string | undefined;
}

export interface SessionUpdatedEvent extends SessionEventBase {
    msg_type: SolverInterfaceEventType.SESSION_UPDATED;
    turn_id: string | undefined;
}

export interface SessionVisibilityEvent extends SessionEventBase {
    msg_type: SolverInterfaceEventType.SESSION_VISIBILITY;
    visibility: SessionVisibility;
}

export interface TraceEventBase extends SessionEventBase {
    turn_id: string;
}

export enum TraceEventType {
    SOLVER_LOG = "solver_log",
    WORKSPACE_CREATION_PROGRESS = "workspace_creation_progress",
    AGENT_THOUGHT = "agent_thought",
    DOCUMENTATION = "documentation",
    IMAGE_ATTACHMENT = "image_attachment",
    CODE_COVERAGE = "coverage",
    CREATE = "create",
    EDIT = "edit",
    REVERT = "revert",
    PROFILE = "profile",
    RETRIEVAL = "retrieval",
    LINT_ERRORS = "lint_errors",
    BLAME = "blame",
    BISECT = "bisect",
    EXECUTION = "execution",
    SUBMIT = "submit",
    RESOURCES_EXHAUSTED = "resources_exhausted",
    OPEN_FILE = "open",
    TEXT_SEARCH = "text_search",
    PROJECT_TREE = "tree",
    SOLUTION_REVIEW = "solution_review",
    PLAN = "plan",
    SOLVING_STOPPED = "solving_stopped",
    REMOTE_COMMITS = "remote_commits",
    MERGE_USER_BRANCH = "merge_user_branch",
    EXECUTION_EDIT = "execution_edit",
    SUGGEST_MEMORY_STORE = "suggest_memory_store",
    CREDITS_USED = "credits_used",
    WEB_SEARCH = "web_search",
    WEB_BROWSE = "web_browse",
    MODEL_BACKOFF = "model_backoff",
}

export interface TraceEvent extends TraceEventBase {
    msg_type: SolverInterfaceEventType.TRACE_EVENT;
    id: string;
    idx: number;
    start: string;
    end: string;
    event_type: TraceEventType;
    event_data: { [key: string]: string };
}

export type SolverInterfaceEvent =
    | TutorialNuggetEvent
    | ProjectCreatedEvent
    | ProjectDeletedEvent
    | ProjectChangedEvent
    | ProjectVisibilityEvent
    | SessionCreatedEvent
    | SessionDeletedEvent
    | SessionStatusEvent
    | SessionUpdatedEvent
    | SessionVisibilityEvent
    | TraceEvent;
