import React, { useState, useEffect } from "react";
import { Tag, Typography } from "antd";
import { CaretRightOutlined, CaretDownOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { OpenQuestions } from "./OpenQuestions";
import { TechPlanTaskData } from "../../data/SolverProjects";
import "./TechPlan.scss";

import SolverMarkdown from "../SolverMarkdown";

const { Text } = Typography;

export interface TechPlanTaskProps {
    task: TechPlanTaskData;
    onQuestionAnswered: (
        taskId: string,
        questionIndex: number,
        question: string,
        answer: string,
        isUnanswering?: boolean
    ) => void;
    isCollapsed?: boolean;
    id?: string;
    taskMap?: Map<string, TechPlanTaskData>;
    onDependencyClick?: (taskId: string) => void;
    disabled?: boolean;
}

export const TechPlanTask: React.FC<TechPlanTaskProps> = ({
    task,
    onQuestionAnswered,
    isCollapsed = false,
    id,
    taskMap = new Map(),
    onDependencyClick,
    disabled = false,
}) => {
    const [collapsed, setCollapsed] = useState(isCollapsed);
    // Sync with parent's collapsed state
    useEffect(() => {
        setCollapsed(isCollapsed);
    }, [isCollapsed]);

    const toggleCollapsed = () => setCollapsed(!collapsed);

    // Keep track of answered questions
    const [answeredQuestions, setAnsweredQuestions] = useState(new Set<number>());

    const handleTaskQuestionAnswered = (
        questionIndex: number,
        question: string,
        answer: string,
        isUnanswering: boolean
    ) => {
        onQuestionAnswered(task.id, questionIndex, question, answer, isUnanswering);

        // Update answered questions and check if all are answered
        const newAnsweredQuestions = new Set(answeredQuestions);
        if (isUnanswering) {
            newAnsweredQuestions.delete(questionIndex);
        } else {
            newAnsweredQuestions.add(questionIndex);
        }
        setAnsweredQuestions(newAnsweredQuestions);

        // Auto-collapse only when all questions are answered
        if (task.open_questions === undefined || newAnsweredQuestions.size === task.open_questions.length) {
            setCollapsed(true);
        } else {
            setCollapsed(false); // Expand when not all questions are answered
        }
    };
    return (
        <div id={id} className={`tech-plan-task ${collapsed ? "tech-plan-task-collapsed" : ""}`}>
            <div className="tech-plan-task-header" onClick={toggleCollapsed}>
                {collapsed ? <CaretRightOutlined /> : <CaretDownOutlined />}
                <Text strong>{task.summary}</Text>
                {task.dependencies.length > 0 && (
                    <Tag color="blue">{`${task.dependencies.length} ${
                        task.dependencies.length === 1 ? "dependency" : "dependencies"
                    }`}</Tag>
                )}
            </div>
            <div className="tech-plan-task-body">
                <div className="tech-plan-task-section">
                    <div className="tech-plan-task-section-title">Description</div>
                    <SolverMarkdown text={task.description} />
                </div>
                <div className="tech-plan-task-section">
                    <div className="tech-plan-task-section-title">Success Criteria</div>
                    <SolverMarkdown text={task.success_criteria} />
                </div>
                <div className="tech-plan-task-section">
                    <div className="tech-plan-task-section-title">Verification Plan</div>
                    <SolverMarkdown text={task.verification_plan} />
                </div>
                {task.dependencies.length > 0 && (
                    <div className="tech-plan-task-section">
                        <div className="tech-plan-task-section-title">Dependencies</div>
                        {task.dependencies.length > 0 && (
                            <div className="tech-plan-dependencies">
                                {task.dependencies.map((dep) => {
                                    const depTask = taskMap.get(dep);

                                    return (
                                        <Tag className="tech-plan-dependency" onClick={() => onDependencyClick?.(dep)}>
                                            {depTask?.summary || dep}
                                        </Tag>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                )}
                {task.open_questions && task.open_questions.length > 0 && (
                    <div className="tech-plan-task-section">
                        <OpenQuestions
                            questions={task.open_questions}
                            onQuestionAnswered={handleTaskQuestionAnswered}
                            title="Open Questions"
                            disabled={disabled}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
