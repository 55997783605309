"use client";
import { CloseOutlined, DeleteOutlined, EditOutlined, LoadingOutlined, WarningOutlined } from "@ant-design/icons";
import type { NotificationInstance } from "antd/es/notification/interface";
import { solverInterfaceApiAxios } from "../data/SolverInterfaceConstants";
import React, { forwardRef, MutableRefObject, useEffect, useLayoutEffect, useRef, useState } from "react";

import AvatarVariantFactory from "../data/AvatarVariantFactory";
import { useSolverInterfaceContext } from "../data/SolverInterface";
import { useTechPlanOpenQuestionsCount } from "../data/SolverProjects";
import { TraceEventType, TutorialAction } from "../data/SolverInterfaceEvent";
import {
    AgentThoughtEvent,
    BisectEvent,
    BlameEvent,
    CodeCoverageEvent,
    CreditsUsedEvent,
    DocumentationEvent,
    EventChangeSetState,
    ExecutionEvent,
    LinterErrorsEvent,
    MergeUserBranchEvent,
    OpenFileEvent,
    ProfileEvent,
    ProjectTreeEvent,
    RetrievalEvent,
    RemoteCommitsEvent,
    sessionIsLoading,
    SessionStatus,
    SolutionReviewEvent,
    SolvingStoppedEvent,
    SuggestMemoryStoreEvent,
    TextSearchEvent,
    Turn,
    TraceEvent,
    useAddChatComment,
    useCanModifySession,
    useChatComments,
    useEventChangeSets,
    useFetchChangeSet,
    useRemoveChatComment,
    useRevertToTurn,
    useSession,
    useSessionStatus,
    useSolve,
    useTurns,
    WebBrowseEvent,
    WebSearchEvent,
    WorkspaceCreationProgressEvent,
    turnEventIsFetchable,
    ModelBackoffEvent,
} from "../data/SolverSession";
import { useSubscriptionData } from "../data/SubscriptionContext";
import ImmutableDiffCard from "./ImmutableDiffCard";
import {
    DATA_ATTRIBUTE_CHANGES_END,
    DATA_ATTRIBUTE_CHANGES_START,
    DATA_ATTRIBUTE_EVENT_ID,
    DATA_ATTRIBUTE_EVENT_TYPE,
    MessageProps,
    MessageRefT,
} from "./Message";
import MessageGroup from "./MessageGroup";
import { MessageType } from "./MessageType";
import ResponsiveTooltip from "./ResponsiveTooltip";
import SolverMarkdown from "./SolverMarkdown";
import { Comment, FileInfo, getRelevantPath } from "./Utils";

import Bisect from "./messages/Bisect";
import Blame from "./messages/Blame";
import CodeCoverage from "./messages/CodeCoverage";
import Execution from "./messages/Execution";
import {
    InlineMessage,
    InlineMessageAction,
    OpenFileMessage,
    ProjectTreeMessage,
    RetrievalQueryMessage,
    SessionPendingMessage,
    TextSearchMessage,
    WebBrowseMessage,
    WebSearchMessage,
    ModelBackoffMessage,
    TechPlanEditMessage,
} from "./messages/InlineMessage";
import LinterCard from "./messages/LinterCard";
import Profile from "./messages/Profile";
import RetrievalCard from "./messages/RetrievalCard";
import SolutionReview from "./messages/SolutionReview";
import WorkspaceProgress from "./messages/WorkspaceProgress";

import { round } from "lodash";
import { ImageAttachmentContent, PlanEvent, WorkspaceCreationProgress } from "../data/TurnEventContent";
import solverAvatar from "../images/solver_icon_reverse_borderless.png";
import "../responsive.scss";
import "./Chat.scss";
import Plan from "./messages/Plan";
import RemoteCommits from "./messages/RemoteCommits";
import SuggestMemoryStore from "./messages/SuggestMemoryStore";
import UserBranchMerge from "./messages/UserBranchMerge";
import GlowingTooltip from "./GlowingTooltip";
import ImageAttachment from "./messages/ImageAttachment";
import ReportButton from "./ReportButton";

interface ChatProps {
    restoreScrollPosition: () => void;
    onShowChangesView: () => void;
    editingTurn: Turn | null;
    setEditingTurn: (turn: Turn | null) => void;
    notification: NotificationInstance;
}

export type ContainerRefT = HTMLElement;

const canModifyTurn = (turn: Turn, turns: Turn[], sessionStatus: SessionStatus): boolean => {
    // Only allow modifications when session is ready
    if (sessionStatus !== SessionStatus.READY) {
        return false;
    }
    if (!turn.allow_undo) {
        return false;
    }

    for (let i = turns.length - 1; i >= 0; i--) {
        const t: Turn = turns[i];
        if (!t.allow_undo) {
            return turn.idx > t.idx;
        }
    }
    return true;
};

const buildMessageButtons = (
    turn: Turn,
    turns: Turn[],
    editingTurn: Turn | null,
    startEditingTurn: (turn: Turn) => void,
    cancelEditingTurn: () => void,
    revertToTurn: (turnId: string | null) => void,
    sessionStatus: SessionStatus
) => {
    const canModify = canModifyTurn(turn, turns, sessionStatus);
    return (
        <div className="message-buttons">
            <ResponsiveTooltip title={!canModify ? "Cannot modify synced messages" : "Delete message"} placement="top">
                <button
                    className="message-button delete-button"
                    onClick={() => {
                        const prevTurn = turns.find((t) => t.idx === turn.idx - 1);
                        revertToTurn(prevTurn?.id ?? null);
                    }}
                    disabled={!canModify}
                >
                    <DeleteOutlined />
                    <span>Delete</span>
                </button>
            </ResponsiveTooltip>
            <ResponsiveTooltip
                title={
                    !canModify
                        ? "Cannot modify synced messages"
                        : editingTurn === turn
                        ? "Cancel Edit"
                        : "Edit and Solve from this point"
                }
                placement="top"
            >
                <button
                    className={`message-button edit-button ${editingTurn === turn ? "cancel-mode" : ""}`}
                    onMouseDown={(e) => {
                        e.preventDefault();
                        editingTurn === turn ? cancelEditingTurn() : startEditingTurn(turn);
                    }}
                    disabled={!canModify}
                >
                    {editingTurn === turn ? <CloseOutlined /> : <EditOutlined />}
                    <span>{editingTurn === turn ? "Cancel Edit" : "Edit"}</span>
                </button>
            </ResponsiveTooltip>
        </div>
    );
};

const Chat = forwardRef<ContainerRefT, ChatProps>(
    ({ restoreScrollPosition, onShowChangesView, editingTurn, setEditingTurn, notification }, containerRef) => {
        const turns = useTurns();
        const eventChangeSets = useEventChangeSets();
        const session = useSession();
        const sessionStatus = useSessionStatus();
        const canModifySession = useCanModifySession();
        const fetchChangeSet = useFetchChangeSet();
        const revertToTurn = useRevertToTurn();
        const solve = useSolve();

        const startEditingTurn = (turn: Turn) => setEditingTurn(turn);
        const cancelEditingTurn = () => setEditingTurn(null);
        const chatComments = useChatComments();
        const addChatComment = useAddChatComment();
        const removeChatComment = useRemoveChatComment();

        const techPlanOpenQuestionsCount = useTechPlanOpenQuestionsCount();

        const { activeRepo } = useSolverInterfaceContext();

        const [userDidScroll, setUserDidScroll] = useState(false);
        const messageRefs = useRef<(MessageRefT | null)[][]>([]);

        const elementIsFullyScrolled = (element: HTMLElement) => {
            return Math.abs(element.scrollHeight - element.clientHeight - element.scrollTop) < 5;
        };

        const getContainerElement = (): HTMLElement | undefined => {
            const containerRefAsRef = containerRef as MutableRefObject<ContainerRefT | null>;
            if (!containerRefAsRef.current) return undefined;
            return containerRefAsRef.current;
        };

        const scrollToBottom = () => {
            const containerElement = getContainerElement();
            if (!containerElement) return;

            // If the user scrolled up, don't scroll to the bottom.
            if (userDidScroll) return;

            containerElement.scrollTop = containerElement.scrollHeight;
        };

        const { numSteps } = useSubscriptionData();

        const handleSuggestionClick = (suggestion: string) => {
            if (!canModifySession) return;
            solve(suggestion, [], numSteps);
        };

        const handleIntersection = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
            entries.forEach((entry) => {
                if (!entry.isIntersecting) return;

                const eventId = entry.target.getAttribute(DATA_ATTRIBUTE_EVENT_ID);
                const eventType = entry.target.getAttribute(DATA_ATTRIBUTE_EVENT_TYPE);
                const eventStart = entry.target.getAttribute(DATA_ATTRIBUTE_CHANGES_START);
                const eventEnd = entry.target.getAttribute(DATA_ATTRIBUTE_CHANGES_END);

                if (eventId && eventType && eventStart && eventEnd && turnEventIsFetchable(eventType)) {
                    fetchChangeSet(eventId, eventStart, eventEnd);
                    observer.unobserve(entry.target);
                }
            });
        };

        useLayoutEffect(() => {
            restoreScrollPosition();
        }, []);

        useEffect(() => {
            const containerElement = getContainerElement();
            if (!containerElement) return;

            const onScroll = () => {
                setTimeout(() => {
                    setUserDidScroll(!elementIsFullyScrolled(containerElement));
                }, 100);
            };

            containerElement.addEventListener("scroll", onScroll);

            return () => containerElement.removeEventListener("scroll", onScroll);
        }, []);

        useEffect(() => {
            if (sessionStatus === SessionStatus.SOLVING) {
                scrollToBottom();
            }
        }, [sessionStatus, turns, eventChangeSets, userDidScroll]);

        useEffect(() => {
            const containerElement = getContainerElement();
            if (!containerElement) return;

            const observer = new IntersectionObserver(handleIntersection, {
                root: containerElement,
                rootMargin: "0px",
                threshold: 0.1,
            });

            const eventElements = document.querySelectorAll("[data-event-id]");
            eventElements.forEach((element) => {
                observer.observe(element);
            });

            return () => observer.disconnect();
        }, []);

        // Handle clicks on grayed-out elements when editing
        useEffect(() => {
            const handleClickOutside = (event: MouseEvent) => {
                if (editingTurn) {
                    const target = event.target as HTMLElement;
                    // Ignore clicks on the cancel button
                    if (target.closest(".edit-button.cancel-mode")) {
                        return;
                    }
                    // Check if click is on a grayed-out message group or the chat container
                    const isClickOnGrayedOut = target.closest(".grayed-out") !== null;
                    const isClickOnContainer = getContainerElement() === target;
                    // Cancel if clicking on grayed-out elements or directly on the session content
                    if (isClickOnGrayedOut || isClickOnContainer) {
                        setEditingTurn(null);
                    }
                }
            };

            document.addEventListener("click", handleClickOutside);
            return () => {
                document.removeEventListener("click", handleClickOutside);
            };
        }, [editingTurn]);

        // Global escape key handler
        useEffect(() => {
            function handleKeyDown(e: KeyboardEvent) {
                // If ESC is pressed and we are in edit mode, exit edit mode
                if (e.key === "Escape" && editingTurn) {
                    setEditingTurn(null);
                }
            }

            window.addEventListener("keydown", handleKeyDown);
            return () => {
                window.removeEventListener("keydown", handleKeyDown);
            };
        }, [editingTurn]);

        const submitActions: InlineMessageAction[] = [];
        if (session) {
            if (session.planning_session_for_project_id) {
                let showTechPlanButtonText: string;
                if (techPlanOpenQuestionsCount !== undefined && techPlanOpenQuestionsCount > 0) {
                    showTechPlanButtonText = `View tech plan (${techPlanOpenQuestionsCount} open question${
                        techPlanOpenQuestionsCount > 1 ? "s" : ""
                    })`;
                } else {
                    showTechPlanButtonText = "View tech plan";
                }

                submitActions.push({
                    text: showTechPlanButtonText,
                    onClick: onShowChangesView,
                });

                submitActions.push({
                    text: "Continue Planning",
                    onClick: () => solve("You're on the right track; please continue", [], numSteps),
                });
            } else {
                submitActions.push({
                    text: "View repository changes",
                    onClick: onShowChangesView,
                });

                submitActions.push({
                    text: "Continue Solving",
                    onClick: () => solve("You're on the right track; please continue", [], numSteps),
                });
            }
        }

        const turnThread = (turn: Turn) => {
            return (
                <React.Fragment key={turn.id}>
                    {promptMessage(turn)}
                    {eventMessages(turn)}
                </React.Fragment>
            );
        };

        const eventMessages = (turn: Turn) => {
            const isLatestTurn = turn.idx === turns.length - 1;

            // Handle credits used event separately
            const creditsEvent = isLatestTurn
                ? turn.events.find((e) => e.event_type === TraceEventType.CREDITS_USED)
                : null;

            const events = turn.events.filter((turnEvent) => {
                if (turnEvent.event_type === TraceEventType.CREDITS_USED) {
                    return false; // Filter out credits event from regular messages
                }
                if (!isLatestTurn && turnEvent.event_type === TraceEventType.SUBMIT) {
                    return false; // Only show submit events for the latest turn
                }
                return !shouldExcludeMessage(turnEvent);
            });

            const messageProps: MessageProps[][] = events.map((turnEvent: TraceEvent) => {
                const contents = eventMessageContent(turnEvent, turn);

                return (Array.isArray(contents) ? contents : [contents]).map((content, idx) => {
                    const key = `${turnEvent.id}-${idx}`;
                    const messageProps: MessageProps = {
                        key,
                        content,
                        messageType: MessageType.AGENT,
                        eventId: turnEvent.id,
                        eventType: turnEvent.event_type,
                        ...eventMessageCollapseProps(turnEvent),
                    };

                    // Only pass start/end if we need to fetch the changes
                    if (turnEventIsFetchable(turnEvent.event_type)) {
                        const changeSet = eventChangeSets.get(turnEvent.id);
                        if (!changeSet || changeSet.state !== EventChangeSetState.FETCHED) {
                            messageProps.start = turnEvent.start;
                            messageProps.end = turnEvent.end;
                        }
                    }

                    return messageProps;
                });
            });

            const messageGroupProps = {
                messages: messageProps.flat(),
                collapsible: turn.idx < turns.length - 1 || !sessionIsLoading(sessionStatus),
                collapsed: turn.idx < turns.length - 1,
                avatar: solverAvatar,
                avatarName: "Solver",
                isGrayedOut: !!editingTurn && turn.idx >= editingTurn.idx,
            };

            return (
                <>
                    <MessageGroup
                        {...messageGroupProps}
                        ref={(msg) => {
                            if (!msg) return;
                            messageRefs.current[turn.idx] = msg;
                        }}
                    />
                    {creditsEvent && renderCreditsUsed(creditsEvent as CreditsUsedEvent)}
                </>
            );
        };

        const shouldExcludeMessage = (turnEvent: TraceEvent): boolean => {
            if (turnEventIsFetchable(turnEvent.event_type)) return false;
            if (turnEvent.event_type === TraceEventType.SOLVER_LOG) return true;
            if (!turnEvent.event_data) return true;

            if (turnEvent.event_type === TraceEventType.AGENT_THOUGHT) {
                const agentThought = turnEvent as AgentThoughtEvent;
                return !agentThought.event_data.message || agentThought.event_data.message === "";
            } else if (turnEvent.event_type === TraceEventType.WORKSPACE_CREATION_PROGRESS) {
                const workspaceCreationProgress = (turnEvent as WorkspaceCreationProgressEvent).event_data.status;
                return workspaceCreationProgress === WorkspaceCreationProgress.DONE;
            } else if (turnEvent.event_type === TraceEventType.SOLVING_STOPPED) {
                const solvingStopped = turnEvent as SolvingStoppedEvent;
                return !solvingStopped.event_data.solving_error || solvingStopped.event_data.solving_error === "";
            } else if (turnEvent.event_type === TraceEventType.MODEL_BACKOFF) {
                const modelBackoff = turnEvent as ModelBackoffEvent;
                return modelBackoff.event_data.failed !== null;
            } else if (turnEvent.event_type === TraceEventType.IMAGE_ATTACHMENT) {
                return true;
            }

            return false;
        };

        const promptMessage = (turn: Turn) => {
            if (!session) return undefined;
            if (turn.nl_text === "") return undefined;

            scrollToBottom();

            const avatarUrl = AvatarVariantFactory.createURLVariant(session.user_avatar_url, session.auth_type, 48);

            let messages: MessageProps[] = [];
            messages.push({
                content: <SolverMarkdown text={turn.nl_text} />,
                messageType: MessageType.USER,
                collapsible: true,
                collapsedThresholdPx: 400,
                defaultExpanded: false,
                key: "turn-prompt",
            });

            turn.events.forEach((turnEvent) => {
                if (turnEvent.event_type === TraceEventType.IMAGE_ATTACHMENT) {
                    const attachmentId = (turnEvent.event_data as ImageAttachmentContent).attachment_id;

                    messages.push({
                        content: <ImageAttachment attachmentId={attachmentId} />,
                        messageType: MessageType.USER,
                        collapsible: false,
                        key: attachmentId,
                    });
                }
            });

            if (turn.idx === turns.length - 1 && sessionStatus === SessionStatus.PENDING) {
                messages.push({
                    content: <SessionPendingMessage />,
                    messageType: MessageType.USER,
                    collapsible: false,
                    key: "session-pending",
                });
            }

            return (
                <MessageGroup
                    messages={messages}
                    collapsible={false}
                    collapsed={false}
                    avatar={avatarUrl}
                    avatarName={session.user_name}
                    isGrayedOut={!!editingTurn && turn.idx >= editingTurn.idx}
                    avatarAreaButtons={
                        canModifySession &&
                        buildMessageButtons(
                            turn,
                            turns,
                            editingTurn,
                            startEditingTurn,
                            cancelEditingTurn,
                            revertToTurn,
                            sessionStatus
                        )
                    }
                />
            );
        };

        const eventMessageCollapseProps = (turnEvent: TraceEvent) => {
            if (turnEvent.event_type === TraceEventType.RETRIEVAL) {
                return {
                    collapsible: true,
                    defaultExpanded: false,
                };
            } else if (turnEvent.event_type === TraceEventType.LINT_ERRORS) {
                return {
                    collapsible: false,
                };
            } else if (turnEvent.event_type === TraceEventType.SOLUTION_REVIEW) {
                return {
                    collapsible: false,
                };
            } else if (turnEvent.event_type === TraceEventType.SUGGEST_MEMORY_STORE) {
                return {
                    collapsible: false,
                };
            } else if (turnEvent.event_type === TraceEventType.REMOTE_COMMITS) {
                return {
                    collapsible: false,
                };
            } else if (
                turnEvent.event_type === TraceEventType.MERGE_USER_BRANCH ||
                turnEvent.event_type === TraceEventType.IMAGE_ATTACHMENT
            ) {
                return {
                    collapsible: false,
                };
            } else if (turnEventIsFetchable(turnEvent.event_type)) {
                return {
                    collapsible: true,
                    defaultExpanded: false,
                    collapsedThresholdPx: 400,
                };
            }

            return {
                collapsible: true,
                defaultExpanded: true,
            };
        };

        const renderCreditsUsed = (creditsUsedEvent: CreditsUsedEvent) => {
            const credits = round(creditsUsedEvent.event_data.credits_used / 100, 2);
            return (
                <div className="credits-message">
                    <GlowingTooltip
                        configs={[
                            {
                                action: TutorialAction.EXPLAIN_CREDITS,
                                title: "Credits track your Solver usage. Each command costs a small amount - shown here after each interaction.",
                            },
                        ]}
                        placement="topLeft"
                    >
                        <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                            <span>Used </span>
                            <span style={{ fontSize: "80%" }}>ⓢ</span>
                            {"\u2009"}
                            {credits} {credits === 1 ? "credit" : "credits"}
                        </span>
                    </GlowingTooltip>
                </div>
            );
        };

        const eventMessageContent = (turnEvent: TraceEvent, turn: Turn) => {
            switch (turnEvent.event_type) {
                case TraceEventType.MODEL_BACKOFF:
                    return <ModelBackoffMessage modelBackoffContent={(turnEvent as ModelBackoffEvent).event_data} />;
                case TraceEventType.CREDITS_USED:
                    return renderCreditsUsed(turnEvent as CreditsUsedEvent);
                case TraceEventType.AGENT_THOUGHT:
                    return <SolverMarkdown text={(turnEvent as AgentThoughtEvent).event_data.message} />;
                case TraceEventType.OPEN_FILE:
                    return <OpenFileMessage openFileContent={(turnEvent as OpenFileEvent).event_data} />;
                case TraceEventType.TEXT_SEARCH:
                    return <TextSearchMessage textSearchContent={(turnEvent as TextSearchEvent).event_data} />;
                case TraceEventType.WEB_SEARCH:
                    return <WebSearchMessage webSearchContent={(turnEvent as WebSearchEvent).event_data} />;
                case TraceEventType.WEB_BROWSE:
                    return <WebBrowseMessage webBrowseContent={(turnEvent as WebBrowseEvent).event_data} />;
                case TraceEventType.REMOTE_COMMITS:
                    if (!activeRepo) {
                        return null;
                    }

                    return (
                        <RemoteCommits
                            content={(turnEvent as RemoteCommitsEvent).event_data}
                            fullRepoName={activeRepo.full_name}
                        />
                    );
                case TraceEventType.MERGE_USER_BRANCH:
                    if (!session) {
                        return null;
                    }

                    return (
                        <UserBranchMerge
                            content={(turnEvent as MergeUserBranchEvent).event_data}
                            userBranchName={session.branch_name}
                        />
                    );
                case TraceEventType.PROJECT_TREE:
                    return <ProjectTreeMessage projectTreeContent={(turnEvent as ProjectTreeEvent).event_data} />;
                case TraceEventType.DOCUMENTATION:
                    return <SolverMarkdown text={(turnEvent as DocumentationEvent).event_data.summary} />;
                case TraceEventType.CODE_COVERAGE:
                    return <CodeCoverage codeCoverageContent={(turnEvent as CodeCoverageEvent).event_data} />;
                case TraceEventType.SUGGEST_MEMORY_STORE:
                    const content = (turnEvent as SuggestMemoryStoreEvent).event_data;

                    return (
                        <SuggestMemoryStore
                            context={content.context}
                            memory={content.memory}
                            priority={content.priority}
                        />
                    );
                case TraceEventType.EXECUTION:
                    if (!session) return null;
                    const sessionInfo = session.getInfo();
                    const handleInterrupt = async () => {
                        try {
                            await solverInterfaceApiAxios.post(
                                `/${sessionInfo.org}/${sessionInfo.repo}/${sessionInfo.session_id}/execution/interrupt`
                            );
                            return true;
                        } catch (error) {
                            console.error("Failed to interrupt execution:", error);
                            return false;
                        }
                    };

                    return (
                        <Execution
                            executionContent={(turnEvent as ExecutionEvent).event_data}
                            onInterrupt={handleInterrupt}
                        />
                    );
                case TraceEventType.CREATE:
                case TraceEventType.EDIT:
                case TraceEventType.REVERT:
                case TraceEventType.EXECUTION_EDIT: {
                    if (session?.planning_session_for_project_id) {
                        return <TechPlanEditMessage />;
                    }

                    const editChangeSet = eventChangeSets.get(turnEvent.id);

                    if (!editChangeSet || editChangeSet.state === EventChangeSetState.LOADING) {
                        return (
                            <div className="message-loading">
                                <LoadingOutlined className="message-loading-icon" />
                            </div>
                        );
                    } else if (editChangeSet.state === EventChangeSetState.ERROR) {
                        const changesContent = {
                            start: turnEvent.start,
                            end: turnEvent.end,
                        };

                        return (
                            <InlineMessage
                                text="**Failed to load changes**"
                                actions={[
                                    {
                                        text: "Retry",
                                        onClick: () =>
                                            fetchChangeSet(turnEvent.id, changesContent.start, changesContent.end),
                                    },
                                ]}
                            />
                        );
                    }

                    return (
                        <div>
                            {editChangeSet.changeSet.file_infos.map((fileInfo: FileInfo) => {
                                const fileData = fileInfo.fileData;
                                const diffCardKey = `${fileData.oldRevision}-${fileData.newRevision}`;
                                return (
                                    <ImmutableDiffCard
                                        key={diffCardKey}
                                        fileInfo={fileInfo}
                                        collapsible={true}
                                        onSubmitComment={
                                            turn.idx === turns.length - 1 && sessionStatus === SessionStatus.READY
                                                ? (comment: Comment) => {
                                                      addChatComment(comment);
                                                  }
                                                : undefined
                                        }
                                        onRetractComment={(comment) => removeChatComment(comment)}
                                        submittedComments={chatComments.filter(
                                            (comment) => getRelevantPath(comment.fileData) === getRelevantPath(fileData)
                                        )}
                                    />
                                );
                            })}
                        </div>
                    );
                }
                case TraceEventType.PLAN: {
                    const planEvent = turnEvent as unknown as PlanEvent;
                    return <Plan planContent={planEvent.content} />;
                }
                case TraceEventType.PROFILE:
                    return <Profile profileContent={(turnEvent as ProfileEvent).event_data} />;
                case TraceEventType.SOLUTION_REVIEW:
                    return (
                        <SolutionReview
                            solutionReview={(turnEvent as SolutionReviewEvent).event_data}
                            onSuggestionClick={handleSuggestionClick}
                        />
                    );
                case TraceEventType.RETRIEVAL: {
                    const retrievalContent = (turnEvent as RetrievalEvent).event_data;

                    if (retrievalContent.pending) {
                        return <RetrievalQueryMessage retrievalContent={retrievalContent} />;
                    }

                    return [
                        <RetrievalQueryMessage retrievalContent={retrievalContent} />,
                        <RetrievalCard retrievalContent={retrievalContent} />,
                    ];
                }
                case TraceEventType.LINT_ERRORS:
                    return <LinterCard content={(turnEvent as LinterErrorsEvent).event_data} />;
                case TraceEventType.SUBMIT:
                    return (
                        <InlineMessage
                            text="**Solver finished**"
                            actions={turn.idx === turns.length - 1 ? submitActions : []}
                        />
                    );
                case TraceEventType.RESOURCES_EXHAUSTED:
                    return (
                        <InlineMessage
                            text="**Solver paused**"
                            actions={turn.idx === turns.length - 1 ? submitActions : []}
                        />
                    );
                case TraceEventType.BLAME:
                    if (!activeRepo) {
                        return null;
                    }

                    return (
                        <Blame
                            blameContent={(turnEvent as BlameEvent).event_data}
                            fullRepoName={activeRepo.full_name}
                        />
                    );
                case TraceEventType.BISECT:
                    return <Bisect bisectContent={(turnEvent as BisectEvent).event_data} />;
                case TraceEventType.WORKSPACE_CREATION_PROGRESS:
                    return (
                        <WorkspaceProgress
                            workspaceCreationProgressContent={(turnEvent as WorkspaceCreationProgressEvent).event_data}
                        />
                    );
                case TraceEventType.SOLVING_STOPPED:
                    if (!session) return null;
                    return (
                        <InlineMessage
                            icon={<WarningOutlined />}
                            text={
                                (turnEvent as SolvingStoppedEvent).event_data.solving_error ||
                                "Solver stopped due to an error"
                            }
                            customActions={[
                                {
                                    button: (
                                        <ReportButton
                                            sessionInfo={session.getInfo()}
                                            className="message-cta"
                                            notification={notification}
                                            label="Report an issue"
                                            origin="solving_stopped"
                                        />
                                    ),
                                },
                            ]}
                        />
                    );
                default:
                    return <SolverMarkdown text={`\`\`\`\n${JSON.stringify(turnEvent.event_data, null, 2)}\n\`\`\``} />;
            }
        };

        if (!activeRepo) {
            return null;
        }

        return <>{turns.map((turn: Turn) => turnThread(turn))}</>;
    }
);

Chat.displayName = "Chat";

export default Chat;
