import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";

import { FilterOutlined, LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { FixedSizeList, ListChildComponentProps, ListOnScrollProps } from "react-window";
import classNames from "classnames";

import { Project } from "../data/SolverProjects";
import { Repo } from "../data/Repos";
import { User, SessionVisibility } from "../data/User";
import { useProjectBrowsingContext } from "../data/ProjectBrowsing";

import ProjectCard from "./ProjectCard";

import globalStyleConstants from "../constants.module.scss";
import siderStyleConstants from "./Sider.module.scss";
import sessionsListStyleConstants from "./SessionsList.module.scss";

import { NotificationInstance } from "antd/es/notification/interface";
import { usePlatform } from "../data/PlatformContext";
import { Button, Input } from "antd";
import ResponsiveTooltip from "./ResponsiveTooltip";

const APP_HEADER_HEIGHT = parseInt(globalStyleConstants.APP_HEADER_HEIGHT);
const SIDER_HEADER_HEIGHT = parseInt(siderStyleConstants.SIDER_HEADER_HEIGHT);

const SESSIONS_LIST_HEADER_HEIGHT = parseInt(sessionsListStyleConstants.SESSIONS_LIST_HEADER_HEIGHT);
const SESSION_CARD_HEIGHT = parseInt(sessionsListStyleConstants.SESSION_CARD_HEIGHT);

interface ProjectListItemData {
    items: Array<Project>;
    onSwitchProject: (project: Project) => Promise<void>;
    onDeleteProject: (project: Project) => void;
    onUpdateVisibility: (project: Project, visibility: SessionVisibility) => Promise<boolean>;
    currentUser: User | undefined;
    activeProject?: Project;
}

const ProjectListRow = React.memo(({ index, style, data }: ListChildComponentProps<ProjectListItemData>) => {
    const item = data.items[index];
    const { activeProject, onSwitchProject, onDeleteProject, currentUser } = data;

    return (
        <li
            className={classNames("session-list-item", {
                "session-list-item-active": item.project_id === activeProject?.project_id,
            })}
            onClick={(e) => {
                // Don't switch if it's the active session or if user is selecting text
                if (item.project_id === activeProject?.project_id) return;
                if (document.getSelection()?.toString() !== "") return;

                onSwitchProject(item);
                e.preventDefault();
            }}
            style={{
                ...style,
                pointerEvents: item.project_id === activeProject?.project_id ? "none" : "auto",
            }}
        >
            <ProjectCard
                project={item}
                currentUser={currentUser}
                active={item.project_id === activeProject?.project_id}
                onDelete={() => onDeleteProject(item)}
            />
        </li>
    );
});

interface ProjectsListProps {
    currentUser: User | undefined;
    repo: Repo;
    activeProject: Project | undefined;
    onSwitchProject: (project: Project) => Promise<void>;
    onDeleteProject: (project: Project) => void;
    notification: NotificationInstance;
}

const ProjectsList: React.FC<ProjectsListProps> = ({
    currentUser,
    repo,
    activeProject,
    onSwitchProject,
    onDeleteProject,
    notification,
}) => {
    const { scrollbarStableClasses } = usePlatform();

    const { projects, page, haveMoreProjects, loadingProjects, loadMoreProjects } = useProjectBrowsingContext();

    const [listHeight, setListHeight] = useState<number>(
        document.body.scrollHeight - APP_HEADER_HEIGHT - SIDER_HEADER_HEIGHT - SESSIONS_LIST_HEADER_HEIGHT
    );

    useLayoutEffect(() => {
        const updateListHeight = () => {
            let height = window.innerHeight - APP_HEADER_HEIGHT - SIDER_HEADER_HEIGHT - SESSIONS_LIST_HEADER_HEIGHT;
            setListHeight(height);
        };

        updateListHeight();

        window.addEventListener("resize", updateListHeight);

        return () => {
            window.removeEventListener("resize", updateListHeight);
        };
    }, []);

    const LOAD_MORE_THRESHOLD = 100;

    const getScrollHeight = useCallback((projects: Project[]): number => {
        return projects.length * SESSION_CARD_HEIGHT;
    }, []);

    const onScrollList = useCallback(
        (scrollProps: ListOnScrollProps) => {
            if (loadingProjects || !haveMoreProjects) return;

            const { scrollOffset, scrollDirection } = scrollProps;
            const scrollHeight = getScrollHeight(projects);
            const isNearBottom = scrollHeight - scrollOffset <= listHeight + LOAD_MORE_THRESHOLD;

            if (scrollHeight > listHeight && isNearBottom && scrollDirection === "forward") {
                loadMoreProjects(repo.org, repo.name);
            }
        },
        [
            loadingProjects,
            haveMoreProjects,
            loadMoreProjects,
            repo.org,
            repo.name,
            listHeight,
            projects,
            getScrollHeight,
        ]
    );

    const listRef = useRef<FixedSizeList>(null);

    // const setSize = useCallback((index: number) => {
    //     if (listRef.current) {
    //         listRef.current.resetAfterIndex(index, false);
    //     }
    // }, []);

    const listItems = useMemo(() => {
        return projects;
    }, [projects]);

    // // Reset list when sessions change
    // useEffect(() => {
    //     if (listRef.current) {
    //         listRef.current.resetAfterIndex(0);
    //     }
    // }, [listItems]);

    const listData: ProjectListItemData = useMemo(
        () => ({
            onSwitchProject,
            onDeleteProject,
            notification,
            currentUser,
            items: listItems,
            activeProject,
            onUpdateVisibility: () => Promise.resolve(true), // TODO)
        }),
        [onSwitchProject, onDeleteProject, notification, currentUser, listItems, activeProject]
    );

    const buildList = useCallback(() => {
        if (loadingProjects && page < 1) {
            return (
                <div style={{ textAlign: "center" }}>
                    <LoadingOutlined />
                </div>
            );
        }

        if (projects.length === 0) {
            return <div style={{ textAlign: "center", marginTop: "10px" }}>No projects found</div>;
        }

        return (
            <FixedSizeList
                ref={listRef}
                className={scrollbarStableClasses}
                itemSize={SESSION_CARD_HEIGHT}
                itemCount={projects.length}
                itemData={{ ...listData, items: projects }}
                width="100%"
                height={listHeight}
                onScroll={onScrollList}
                overscanCount={10}
            >
                {ProjectListRow}
            </FixedSizeList>
        );
    }, [loadingProjects, page, projects.length, listHeight, onScrollList, listData, listItems]);

    return (
        <>
            <div className="session-list-header">
                <Input
                    disabled
                    value=""
                    placeholder="Search projects"
                    addonBefore={<SearchOutlined />}
                    addonAfter={<Button disabled type="text" size="small" icon={<FilterOutlined />} />}
                    allowClear
                />
            </div>
            {buildList()}
        </>
    );
};

export default ProjectsList;
