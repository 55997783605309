export default class Features {
    static MAINTENANCE_MODE = "MAINTENANCE_MODE";
    static INTEGRATIONS_TAB_ENABLED = "INTEGRATIONS_TAB_ENABLED";

    static isMaintenanceModeEnabled(): boolean {
        return this.isFeatureEnabled(this.MAINTENANCE_MODE);
    }

    static isIntegrationsEnabled(): boolean {
        return this.isFeatureEnabled(this.INTEGRATIONS_TAB_ENABLED);
    }

    private static getFeatureValue(feature: string): string {
        return window.env?.[`REACT_APP_${feature}`] || process.env[`REACT_APP_${feature}`] || "";
    }

    private static isFeatureEnabled(feature: string): boolean {
        const value = this.getFeatureValue(feature);

        return value.toLowerCase() === "true" || value === "1";
    }
}
