"use client";
import React, { useState } from "react";

import { DeleteOutlined, LoadingOutlined, LockOutlined, UnlockOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Typography } from "antd";

import ResponsiveTooltip from "./ResponsiveTooltip";

import { useExecutionSettingsContext } from "../data/ExecutionSettings";

import "./Settings.scss";

const ExecutionEnvironmentForm: React.FC = () => {
    const {
        executionEnvironment,
        addEnvironmentVariable,
        deleteEnvironmentVariable,
        resetExecutionEnvironment,
        environmentError,
        loadingEnvironment,
    } = useExecutionSettingsContext();

    const [submittingNewEnvironmentVariable, setSubmittingNewEnvironmentVariable] = useState<boolean>(false);

    const [newVariableName, setNewVariableName] = useState<string>("");
    const [newVariableValue, setNewVariableValue] = useState<string>("");
    const [newVariableIsSecret, setNewVariableIsSecret] = useState<boolean>(false);

    const [variableNameHasError, setVariableNameHasError] = useState<boolean>(false);

    const [modal, contextHolder] = Modal.useModal();

    const onSubmitNewEnvironmentVariable = async () => {
        if (newVariableName.length === 0) {
            setVariableNameHasError(true);
            return;
        }

        setSubmittingNewEnvironmentVariable(true);
        await addEnvironmentVariable(newVariableName, newVariableValue, newVariableIsSecret);
        setSubmittingNewEnvironmentVariable(false);

        setNewVariableName("");
        setNewVariableValue("");
        setNewVariableIsSecret(false);
        setVariableNameHasError(false);
    };

    const onDeleteAllClicked = async () => {
        if (executionEnvironment.length === 0) {
            return;
        }

        modal.confirm({
            title: "This will delete all environment variables for this repository",
            content: "This action cannot be undone.",
            onOk: async () => {
                await resetExecutionEnvironment();
            },
            zIndex: 10000,
        });
    };

    if (loadingEnvironment) {
        return (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <LoadingOutlined />
            </div>
        );
    }

    const buildTable = () => {
        return (
            <div className="execution-env-vars-table-container">
                <Button
                    className="execution-env-vars-button"
                    icon={<DeleteOutlined />}
                    disabled={executionEnvironment.length === 0}
                    danger
                    onClick={() => onDeleteAllClicked()}
                >
                    Delete all
                </Button>
                <table className="execution-env-vars-table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {executionEnvironment
                            .sort((lhs, rhs) => lhs.name.toLowerCase().localeCompare(rhs.name.toLowerCase()))
                            .map((variable) => (
                                <tr key={variable.name}>
                                    <td>
                                        <ResponsiveTooltip title={variable.name} arrow={false}>
                                            {variable.name}
                                        </ResponsiveTooltip>
                                    </td>
                                    <td>
                                        <div className="execution-env-vars-value-container">
                                            <div className="execution-env-vars-value-text">
                                                {variable.secret ? (
                                                    "••••••••"
                                                ) : (
                                                    <ResponsiveTooltip title={variable.value} arrow={false}>
                                                        {variable.value}
                                                    </ResponsiveTooltip>
                                                )}
                                            </div>
                                            <ResponsiveTooltip title="Delete" arrow={false}>
                                                <button
                                                    className="execution-env-vars-value-delete"
                                                    onClick={() => deleteEnvironmentVariable(variable.name)}
                                                >
                                                    <DeleteOutlined />
                                                </button>
                                            </ResponsiveTooltip>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        <tr>
                            <td>
                                <Input
                                    className="execution-env-vars-input"
                                    type="text"
                                    placeholder="Name"
                                    value={newVariableName}
                                    onChange={(e) => setNewVariableName(e.target.value)}
                                    status={variableNameHasError ? "error" : undefined}
                                />
                            </td>
                            <td>
                                {React.createElement(newVariableIsSecret ? Input.Password : Input, {
                                    className: "execution-env-vars-input",
                                    type: "text",
                                    placeholder: "Value",
                                    addonBefore: buildValueInputAddon(),
                                    value: newVariableValue,
                                    onChange: (e) => setNewVariableValue(e.target.value),
                                })}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Button
                    className="execution-env-vars-button execution-env-vars-add-button"
                    disabled={submittingNewEnvironmentVariable}
                    type="primary"
                    onClick={async () => onSubmitNewEnvironmentVariable()}
                    loading={submittingNewEnvironmentVariable}
                >
                    Add Variable
                </Button>
            </div>
        );
    };

    const buildValueInputAddon = () => {
        return (
            <ResponsiveTooltip title="Click here if the new environment variable is a secret. Once submitted as a secret, you will not be able to see its value again.">
                <Button
                    icon={newVariableIsSecret ? <LockOutlined /> : <UnlockOutlined />}
                    size="small"
                    onClick={() => setNewVariableIsSecret((prev) => !prev)}
                />
            </ResponsiveTooltip>
        );
    };

    return (
        <>
            <h4 className="settings-title">Image Environment</h4>
            <p className="settings-description">
                Environment variables are key-value pairs that can be used to pass configuration settings to your
                application. These variables are available within the configured Docker image.
            </p>
            {environmentError && <Typography.Text type="danger">{environmentError}</Typography.Text>}
            {buildTable()}
            {contextHolder}
        </>
    );
};

export default ExecutionEnvironmentForm;
