"use client";
import { MergeOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import React from "react";
import { FileData } from "react-diff-view";

import { MergeUserBranchContent } from "../../data/TurnEventContent";
import ImmutableDiffCard from "../ImmutableDiffCard";

import { FileInfo, patchToFileInfos } from "../Utils";
import { buildCommitLabel } from "./Common";
import { InlineMessage } from "./InlineMessage";
import "./Common.scss";

interface UserBranchMergeProps {
    content: MergeUserBranchContent;
    userBranchName: string;
}

const UserBranchMerge: React.FC<UserBranchMergeProps> = ({ content, userBranchName }) => {
    const buildDiffs = (content: MergeUserBranchContent) => {
        const fileInfos = patchToFileInfos(content.patch);

        return fileInfos.map((fileInfo: FileInfo) => {
            const diffCardKey = (fileData: FileData) => `${fileData.oldRevision}-${fileData.newRevision}`;
            return (
                <ImmutableDiffCard
                    key={diffCardKey(fileInfo.fileData)}
                    fileInfo={fileInfo}
                    expandCodeFn={() => {}}
                    collapsible={true}
                />
            );
        });
    };

    const buildContent = () => {
        return (
            <Collapse
                className="commits-collapse"
                defaultActiveKey={[]}
                accordion={true}
                items={[
                    {
                        key: "0",
                        label: buildCommitLabel("Solver", content.date, "Merged changes"),
                        children: buildDiffs(content),
                    },
                ]}
            />
        );
    };

    const messageText = content.patch
        ? `Merged changes from ${userBranchName}.`
        : `Merged changes from ${userBranchName}, but the diff was empty.`;

    return (
        <div>
            <InlineMessage icon={<MergeOutlined />} text={messageText} />
            {content.patch && buildContent()}
        </div>
    );
};

export default UserBranchMerge;
