import React, { useEffect, useState } from "react";

import { Button, Progress } from "antd";
import {
    ClockCircleOutlined,
    EditOutlined,
    FileTextOutlined,
    FolderOutlined,
    GlobalOutlined,
    SearchOutlined,
} from "@ant-design/icons";

import SolverMarkdown from "../SolverMarkdown";

import {
    OpenFileContent,
    ProjectTreeContent,
    RetrievalContent,
    TextSearchContent,
    ModelBackoffContent,
    WebBrowseContent,
    WebSearchContent,
} from "../../data/TurnEventContent";

import "./InlineMessage.scss";

export interface InlineMessageAction {
    text: React.ReactNode;
    onClick: () => void;
}

export interface CustomInlineMessageAction {
    button: React.ReactNode;
}

interface InlineMessageProps {
    icon?: React.ReactNode;
    text: string;
    actions?: InlineMessageAction[];
    customActions?: CustomInlineMessageAction[];
}

const InlineMessage: React.FC<InlineMessageProps> = ({ icon, text, actions = [], customActions = [] }) => (
    <div className="inline-message">
        <div className="inline-message-content">
            {icon && <span className="inline-message-icon">{icon}</span>}
            <span className="inline-message-text">
                <SolverMarkdown text={text} />
            </span>
        </div>
        {(actions.length > 0 || customActions.length > 0) && (
            <div className="inline-message-actions">
                {actions.map((action, index) => (
                    <Button key={index} onClick={action.onClick} className="message-cta">
                        {action.text}
                    </Button>
                ))}
                {customActions.map((customAction) => customAction.button)}
            </div>
        )}
    </div>
);

const SessionPendingMessage: React.FC = () => (
    <InlineMessage icon={<ClockCircleOutlined />} text="Request has been submitted and solving will start soon." />
);

const ModelBackoffMessage: React.FC<{ modelBackoffContent: ModelBackoffContent }> = ({ modelBackoffContent }) => {
    const [remainingBackoffSeconds, setRemainingBackoffSeconds] = useState<number>(modelBackoffContent.backoff_seconds);

    useEffect(() => {
        setRemainingBackoffSeconds(modelBackoffContent.backoff_seconds);
    }, [modelBackoffContent.backoff_seconds]);

    useEffect(() => {
        const interval = setInterval(() => {
            setRemainingBackoffSeconds((prev) => {
                if (prev <= 0) {
                    clearInterval(interval);
                    return 0;
                }

                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [remainingBackoffSeconds]);

    const progressPercent = (remainingBackoffSeconds / modelBackoffContent.backoff_seconds) * 100;

    return (
        <InlineMessage
            icon={
                <Progress
                    type="circle"
                    percent={progressPercent}
                    status="active"
                    size={30}
                    format={() => {
                        if (remainingBackoffSeconds === 0) {
                            return null;
                        }

                        return `${remainingBackoffSeconds}s`;
                    }}
                />
            }
            text="We're currently at capacity with our AI model providers. Your task is safely queued and will complete in the background, even with your browser closed. Check back later for results."
        />
    );
};

const OpenFileMessage: React.FC<{ openFileContent: OpenFileContent }> = ({ openFileContent: { path, status } }) => {
    const text = () => {
        if (path === ".solver/tech_plan.json") {
            return "Reading tech plan";
        }

        switch (status) {
            case "success":
                return `Reading \`${path}\``;
            case "file_not_found":
            case "directory":
            case "definition_not_found":
                return `Not found: \`${path}\``;
        }
    };

    return <InlineMessage icon={<FileTextOutlined />} text={text()} />;
};

const TextSearchMessage: React.FC<{ textSearchContent: TextSearchContent }> = ({
    textSearchContent: { pattern, path },
}) => {
    const text = () => {
        if (pattern !== null) {
            if (path === null) {
                return `Searching repository for \`${pattern}\``;
            } else {
                return `Searching \`${path}\` for \`${pattern}\``;
            }
        }

        return "Searching repository";
    };

    return <InlineMessage icon={<SearchOutlined />} text={text()} />;
};

const RetrievalQueryMessage: React.FC<{ retrievalContent: RetrievalContent }> = ({ retrievalContent }) => {
    const text = () => {
        if (retrievalContent.query) {
            return `Searching repository for \`${retrievalContent.query}\``;
        }

        return "Searching repository for relevant files";
    };

    return <InlineMessage icon={<SearchOutlined />} text={text()} />;
};

const ProjectTreeMessage: React.FC<{ projectTreeContent: ProjectTreeContent }> = ({ projectTreeContent }) => {
    // Don't show .solver to hide tech plan internals.
    const message =
        projectTreeContent.path === "." || projectTreeContent.path === ".solver"
            ? "Viewing project structure"
            : `Viewing project structure at \`${projectTreeContent.path}\``;

    return <InlineMessage icon={<FolderOutlined />} text={message} />;
};

const WebSearchMessage: React.FC<{ webSearchContent: WebSearchContent }> = ({ webSearchContent }) => {
    const text = () => {
        if (webSearchContent.error) {
            return "Error performing web search";
        }
        return `Searched web for "${webSearchContent.query}"`;
    };

    return <InlineMessage icon={<GlobalOutlined />} text={text()} />;
};

const WebBrowseMessage: React.FC<{ webBrowseContent: WebBrowseContent }> = ({ webBrowseContent }) => {
    const text = () => {
        if (webBrowseContent.error) {
            return `Error browsing [${webBrowseContent.url}](${webBrowseContent.url})`;
        }
        return `Browsing [${webBrowseContent.url}](${webBrowseContent.url})`;
    };

    return <InlineMessage icon={<GlobalOutlined />} text={text()} />;
};

const TechPlanEditMessage: React.FC = () => {
    return <InlineMessage icon={<EditOutlined />} text="Edited tech plan" />;
};

export {
    InlineMessage,
    ModelBackoffMessage,
    OpenFileMessage,
    ProjectTreeMessage,
    RetrievalQueryMessage,
    SessionPendingMessage,
    TechPlanEditMessage,
    TextSearchMessage,
    WebBrowseMessage,
    WebSearchMessage,
};
