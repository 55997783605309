import React, { useEffect, useRef, useState } from "react";
import { Badge, Button, Form, Input, Modal, notification, Select, Switch, Table, Tabs, Typography } from "antd";
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useSolverInterfaceContext } from "../data/SolverInterface";
import {
    Memory,
    MemoryVisibility,
    getMemories,
    createMemory,
    updateMemory,
    deleteMemory,
} from "../data/MemoryStoreInterface";

interface CustomRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    "data-row-key"?: string;
    children: React.ReactNode;
}

export enum MemoryPriority {
    LOW = "LOW",
    MEDIUM = "MEDIUM",
    HIGH = "HIGH",
}

interface MemoryStoreProps {
    open: boolean;
    onClose: () => void;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: string;
    record: Memory;
    children: React.ReactNode;
}

const MemoryStore: React.FC<MemoryStoreProps> = ({ open, onClose }) => {
    const { activeRepo, currentUser } = useSolverInterfaceContext();
    const [memories, setMemories] = useState<Memory[]>([]);
    const [loading, setLoading] = useState(false);
    const [editingKey, setEditingKey] = useState<string>("");
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const [tempMemoryId, setTempMemoryId] = useState<string | null>(null);
    const [searchText, setSearchText] = useState<string>("");
    const [activeTab, setActiveTab] = useState<MemoryVisibility>(MemoryVisibility.PERSONAL);
    const [lastRepoViewTime, setlastRepoViewTime] = useState<string>("");
    const [newRepoMemoriesCount, setNewRepoMemoriesCount] = useState<number>(0);
    const [seenMemories, setSeenMemories] = useState<Set<string>>(new Set());
    const editingRowRef = useRef<HTMLTableRowElement | null>(null);

    // Clear counts and seen memories when modal closes
    useEffect(() => {
        if (!open) {
            setNewRepoMemoriesCount(0);
            setSeenMemories(new Set());
        }
    }, [open]);

    const isEditing = (record: Memory) => record.id === editingKey;

    // Filter and sort memories based on current tab and search text
    const filteredMemories = React.useMemo(() => {
        // No need to refetch on tab change since we have all memories
        const visibilityFilter = activeTab;

        return (
            [...memories]
                .filter((memory) => {
                    // In demo repositories, only show the current user's memories
                    if (activeRepo?.is_demo && memory.user_id !== currentUser?.id) {
                        return false;
                    }

                    return (
                        // Filter by visibility (tab)
                        memory.visibility === visibilityFilter &&
                        // Filter by search text
                        (memory.context.toLowerCase().includes(searchText.toLowerCase()) ||
                            memory.memory.toLowerCase().includes(searchText.toLowerCase()))
                    );
                })
                // Sort by enabled status
                .sort((a, b) => (a.enabled === b.enabled ? 0 : a.enabled ? -1 : 1))
        );
    }, [memories, searchText, activeTab, activeRepo?.is_demo]);

    const formatDate = (timestamp: number | string) => {
        try {
            const date = new Date(Number(timestamp) * 1000);
            if (isNaN(date.getTime())) {
                console.error("Invalid timestamp:", timestamp);
                return String(timestamp);
            }
            return date.toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
            });
        } catch (error) {
            console.error("Error formatting date:", timestamp, error);
            return String(timestamp);
        }
    };

    const fetchMemories = React.useCallback(async () => {
        if (!activeRepo || !currentUser) return;

        setLoading(true);
        try {
            const allMemories = await getMemories(activeRepo.org, activeRepo.name);
            setMemories(allMemories);
        } catch (error) {
            api.error({ message: "Failed to fetch memories" });
        }
        setLoading(false);
    }, [activeRepo, api, setLoading, setMemories]);

    // Handle modal state and resets
    useEffect(() => {
        if (open) {
            setActiveTab(MemoryVisibility.PERSONAL); // Always start with personal tab
        } else {
            // Clear counts and seen memories when closing
            setNewRepoMemoriesCount(0);
            setSeenMemories(new Set());
        }
    }, [open]);

    // Update new memory count whenever memories change or modal is opened
    useEffect(() => {
        if (!activeRepo || !currentUser || editingKey) return;

        const repoMemories = memories.filter((m) => m.visibility === MemoryVisibility.REPOSITORY);
        const lastViewTimestamp = lastRepoViewTime ? Math.floor(new Date(lastRepoViewTime).getTime() / 1000) : 0;

        const newMemoryIds = repoMemories
            .filter((m) => m.user_id !== currentUser.id && parseFloat(m.created_at) > lastViewTimestamp)
            .map((m) => m.id);

        setNewRepoMemoriesCount(newMemoryIds.length);

        // Only clear badges when actually viewing the repository tab
        if (activeTab === MemoryVisibility.REPOSITORY) {
            const timer = setTimeout(() => {
                const newTime = new Date().toISOString();
                setlastRepoViewTime(newTime);
                localStorage.setItem(`memoryStoreLastRepoViewTime_${activeRepo.org}_${activeRepo.name}`, newTime);
                setNewRepoMemoriesCount(0);
                setSeenMemories(new Set(newMemoryIds));
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [memories, lastRepoViewTime, activeRepo, editingKey, activeTab]);

    // Initialize lastRepoViewTime from storage when component mounts
    useEffect(() => {
        // Reset last view time when repository changes
        if (!activeRepo) {
            setlastRepoViewTime("");
            return;
        }

        const storageKey = `memoryStoreLastRepoViewTime_${activeRepo.org}_${activeRepo.name}`;
        const savedLastRepoViewTime = localStorage.getItem(storageKey);

        if (savedLastRepoViewTime) {
            try {
                const date = new Date(savedLastRepoViewTime);
                if (!isNaN(date.getTime())) {
                    setlastRepoViewTime(savedLastRepoViewTime);
                } else {
                    throw new Error("Invalid date");
                }
            } catch (e) {
                console.error("Invalid memory timestamp:", e);
                // Set to current time if invalid
                const newTime = new Date().toISOString();
                setlastRepoViewTime(newTime);
                if (activeRepo) {
                    const storageKey = `memoryStoreLastRepoViewTime_${activeRepo.org}_${activeRepo.name}`;
                    localStorage.setItem(storageKey, newTime);
                }
            }
        }
        // Note: We don't set an initial time if none exists - this ensures new memories are shown on first visit
    }, [activeRepo]);

    // Fetch memories when modal opens
    useEffect(() => {
        if (!open || !activeRepo) return;

        fetchMemories();
    }, [open, activeRepo]);

    const handleDelete = async (memoryId: string) => {
        if (!activeRepo || !currentUser) return;

        if (memoryId === tempMemoryId) {
            setMemories(memories.filter((m) => m.id !== memoryId));
            setEditingKey("");
            setTempMemoryId(null);
            return;
        }

        try {
            await deleteMemory(activeRepo.org, activeRepo.name, memoryId);
            setMemories(memories.filter((m) => m.id !== memoryId));
            api.success({ message: "Memory deleted", placement: "bottomRight", key: "memoryNotification" });
        } catch (error) {
            api.error({ message: "Failed to delete memory", placement: "bottomRight", key: "memoryNotification" });
        }
    };

    const handleToggleEnabled = async (memory: Memory) => {
        if (!activeRepo || !currentUser) return;

        try {
            await updateMemory(activeRepo.org, activeRepo.name, memory.id, {
                enabled: !memory.enabled,
                visibility: activeTab,
            });
            setMemories(memories.map((m) => (m.id === memory.id ? { ...m, enabled: !m.enabled } : m)));
        } catch (error) {
            api.error({ message: "Failed to update memory", placement: "bottomRight", key: "memoryNotification" });
        }
    };

    const handleEdit = (record: Memory) => {
        // Set editing state first
        setEditingKey(record.id);

        // Initialize form with record values
        form.setFieldsValue({
            context: record.context || "",
            memory: record.memory || "",
            priority: record.priority || MemoryPriority.MEDIUM,
        });
    };

    const handleCancel = () => {
        // Remove temp memory if it exists
        if (tempMemoryId) {
            setMemories(memories.filter((m) => m.id !== tempMemoryId));
            setTempMemoryId(null);
        }

        // Clear edit state
        setEditingKey("");

        // Reset form to empty state
        form.setFieldsValue({
            context: "",
            memory: "",
            priority: MemoryPriority.MEDIUM,
        });
    };

    const handleSave = async (id: string) => {
        try {
            const values = await form.validateFields();
            if (!activeRepo || !currentUser) return;

            const isNewMemory = id === tempMemoryId;
            const memoryData: Partial<Memory> = {
                context: values.context || "",
                memory: values.memory || "",
                priority: values.priority || MemoryPriority.MEDIUM,
                repo_name: `${activeRepo.org}/${activeRepo.name}`,
                user_name: currentUser.name,
                visibility: activeTab,
            };

            try {
                const savedMemory = isNewMemory
                    ? await createMemory(activeRepo.org, activeRepo.name, memoryData)
                    : await updateMemory(activeRepo.org, activeRepo.name, id, memoryData);

                if (isNewMemory) {
                    setMemories(memories.map((m) => (m.id === tempMemoryId ? savedMemory : m)));
                    setTempMemoryId(null);
                } else {
                    setMemories(memories.map((m) => (m.id === id ? savedMemory : m)));
                }
                // Clear edit state before form reset to prevent resize issues
                setEditingKey("");
                setTimeout(() => {
                    form.resetFields();
                }, 0);
                api.success({
                    message: isNewMemory ? "Memory added" : "Memory updated",
                    placement: "bottomRight",
                    key: "memoryNotification",
                });
            } catch (error) {
                api.error({
                    message: isNewMemory ? "Failed to create memory" : "Failed to save memory",
                    placement: "bottomRight",
                    key: "memoryNotification",
                });
            }
        } catch (error) {
            // Form validation error
        }
    };

    const handleAdd = () => {
        if (!activeRepo) return; // Guard against undefined activeRepo

        // Create new memory with empty values
        const tempMemory: Memory = {
            id: `temp-${Date.now()}`,
            context: "",
            memory: "",
            priority: MemoryPriority.MEDIUM,
            enabled: true,
            repo_name: `${activeRepo.org}/${activeRepo.name}`,
            created_at: (Date.now() / 1000).toString(),
            user_name: currentUser ? currentUser.name : "",
            user_id: currentUser ? currentUser.id : "",
            visibility: activeTab,
        };

        // Clear any existing temp memory
        if (tempMemoryId) {
            setMemories(memories.filter((m) => m.id !== tempMemoryId));
        }

        // Add new memory and set edit state
        setMemories([tempMemory, ...memories]);
        setEditingKey(tempMemory.id);
        setTempMemoryId(tempMemory.id);

        // Initialize form with empty values
        form.setFieldsValue({
            context: "",
            memory: "",
            priority: MemoryPriority.MEDIUM,
        });

        // Reset any form validation states
        form.setFields([
            { name: "context", errors: [] },
            { name: "memory", errors: [] },
        ]);
    };

    const EditableCell: React.FC<EditableCellProps> = ({ editing, dataIndex, title, children, ...restProps }) => {
        const handleInputChange = React.useCallback(
            (value: string) => {
                // Update the form field value without triggering validation
                form.setFields([
                    {
                        name: dataIndex,
                        value: value,
                        errors: [], // Clear any previous errors
                        touched: false, // Don't mark as touched to prevent validation
                    },
                ]);
            },
            [dataIndex]
        );

        let inputNode;

        if (dataIndex === "priority") {
            inputNode = (
                <Select onChange={handleInputChange} defaultValue={MemoryPriority.MEDIUM}>
                    <Select.Option value={MemoryPriority.LOW}>Low</Select.Option>
                    <Select.Option value={MemoryPriority.MEDIUM}>Medium</Select.Option>
                    <Select.Option value={MemoryPriority.HIGH}>High</Select.Option>
                </Select>
            );
        } else if (dataIndex === "memory" || dataIndex === "context") {
            inputNode = (
                <div
                    className="textarea-wrapper"
                    onMouseDown={(e) => {
                        // Prevent any parent handlers from stealing focus
                        e.stopPropagation();
                    }}
                >
                    <Input.TextArea
                        key={`${dataIndex}-${restProps.record.id}`}
                        placeholder={dataIndex === "memory" ? "What should Solver do?" : "When should Solver do it?"}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        defaultValue={editing ? form.getFieldValue(dataIndex) : ""}
                        onChange={(e) => {
                            const newValue = e.target.value;
                            handleInputChange(newValue);
                            form.setFieldValue(dataIndex, newValue);
                        }}
                        onBlur={() => {
                            if (!editingKey) return;

                            // Only validate if we have content
                            const currentValue = form.getFieldValue(dataIndex);
                            if (currentValue && currentValue.trim()) {
                                form.validateFields([dataIndex]).catch(() => {
                                    // Prevent field clearing on validation error
                                    form.setFields([
                                        {
                                            name: dataIndex,
                                            value: currentValue,
                                            errors: [],
                                        },
                                    ]);
                                });
                            }
                        }}
                        onKeyDown={(e) => {
                            // Prevent tab from losing focus
                            if (e.key === "Tab") {
                                e.stopPropagation();
                            }
                        }}
                        maxLength={256}
                        showCount={{
                            formatter: ({ count, maxLength }) => `${count}/${maxLength}`,
                        }}
                    />
                </div>
            );
        } else {
            inputNode = <Input onChange={(e) => handleInputChange(e.target.value)} />;
        }

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        style={{ margin: 0, marginBottom: 16 }}
                        name={dataIndex}
                        rules={[
                            {
                                required: true,
                                message: `*Required`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const columns = [
        {
            title: "Remember To",
            dataIndex: "memory",
            editable: true,
            className: "wrap-cell",
            width: "30%",
            render: (text: string, record: Memory) => (
                <div
                    className="wrapped-content"
                    style={{
                        color: record.enabled ? "#e6e6e6" : "#999999",
                    }}
                >
                    {text}
                </div>
            ),
        },
        {
            title: "When",
            dataIndex: "context",
            editable: true,
            className: "wrap-cell",
            width: "30%",
            render: (text: string, record: Memory) => {
                // Direct comparison using Unix timestamps
                return (
                    <div
                        className="wrapped-content"
                        style={{
                            color: record.enabled ? "#e6e6e6" : "#999999",
                        }}
                    >
                        {text}
                    </div>
                );
            },
        },
        {
            title: "Priority",
            dataIndex: "priority",
            editable: true,
            width: "120px",
            sorter: (a: Memory, b: Memory) => {
                const priorityOrder = {
                    [MemoryPriority.LOW]: 1,
                    [MemoryPriority.MEDIUM]: 2,
                    [MemoryPriority.HIGH]: 3,
                };
                return priorityOrder[a.priority as MemoryPriority] - priorityOrder[b.priority as MemoryPriority];
            },
            render: (priority: string, record: Memory) => (
                <span
                    style={{
                        padding: "2px 12px",
                        borderRadius: "12px",
                        fontSize: "12px",
                        background:
                            priority.toLowerCase() === "high"
                                ? "#2d1414"
                                : priority.toLowerCase() === "medium"
                                ? "#2b2613"
                                : "#142837",
                        color: record.enabled
                            ? priority.toLowerCase() === "high"
                                ? "#e6938e"
                                : priority.toLowerCase() === "medium"
                                ? "#e6ce81"
                                : "#83c0e6"
                            : "#999999",
                        opacity: record.enabled ? 1 : 0.65,
                    }}
                >
                    {priority}
                </span>
            ),
        },
        {
            title: "Author",
            dataIndex: "user_name",
            width: "200px",
            sorter: (a: Memory, b: Memory) => a.user_name.localeCompare(b.user_name),
            render: (name: string, record: Memory) => (
                <span style={{ color: record.enabled ? "#e6e6e6" : "#999999" }}>{name}</span>
            ),
        },
        {
            title: "Created",
            dataIndex: "created_at",
            width: "200px",
            sorter: (a: Memory, b: Memory) => {
                const dateA = new Date(a.created_at);
                const dateB = new Date(b.created_at);
                return dateA.getTime() - dateB.getTime();
            },
            render: (date: string, record: Memory) => (
                <span style={{ color: record.enabled ? "#e6e6e6" : "#999999" }}>{formatDate(date)}</span>
            ),
        },
        {
            title: "Enabled",
            dataIndex: "enabled",
            width: "100px",
            sorter: {
                compare: (a: Memory, b: Memory) => (a.enabled === b.enabled ? 0 : a.enabled ? -1 : 1),
                multiple: 1,
            },
            render: (_: unknown, record: Memory) => (
                <Switch
                    checked={record.enabled}
                    onChange={() => handleToggleEnabled(record)}
                    disabled={isEditing(record)}
                    style={{ opacity: record.enabled ? 1 : 0.65 }}
                />
            ),
        },
        {
            title: "",
            dataIndex: "operation",
            width: "140px",
            render: (_: unknown, record: Memory) => {
                const editable = isEditing(record);
                const isOwner = record.user_id === currentUser?.id;

                // Check for new memory badge using lastRepoViewTime
                const memoryTimestamp = parseInt(record.created_at, 10);
                const lastViewTimestamp = lastRepoViewTime
                    ? Math.floor(new Date(lastRepoViewTime).getTime() / 1000)
                    : 0;

                const isNewRepoMemory =
                    record.visibility === MemoryVisibility.REPOSITORY &&
                    record.user_id !== currentUser?.id &&
                    memoryTimestamp > lastViewTimestamp &&
                    !seenMemories.has(record.id);

                return editable ? (
                    <div className="action-buttons" style={{ minWidth: "80px", justifyContent: "flex-end" }}>
                        <Button
                            type="text"
                            icon={<CheckOutlined />}
                            onClick={() => handleSave(record.id)}
                            style={{ color: "#52c41a" }}
                        />
                        <Button
                            type="text"
                            icon={<CloseOutlined />}
                            onClick={handleCancel}
                            style={{ color: "#ff4d4f" }}
                        />
                    </div>
                ) : (
                    <div className="action-buttons" style={{ minWidth: "80px", justifyContent: "flex-end" }}>
                        {isNewRepoMemory && (
                            <Badge
                                count="New"
                                style={{
                                    backgroundColor: "#52c41a",
                                    marginRight: "8px",
                                }}
                            />
                        )}
                        {isOwner && (
                            <>
                                <Button
                                    type="text"
                                    icon={<EditOutlined />}
                                    onClick={() => handleEdit(record)}
                                    disabled={editingKey !== "" && editingKey !== record.id}
                                    style={{ opacity: record.enabled ? 1 : 0.65 }}
                                />
                                <Button
                                    type="text"
                                    danger
                                    icon={<DeleteOutlined />}
                                    onClick={() => handleDelete(record.id)}
                                    disabled={editingKey !== "" && editingKey !== record.id}
                                    style={{ opacity: record.enabled ? 1 : 0.65 }}
                                />
                            </>
                        )}
                    </div>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: Memory) => ({
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <>
            {contextHolder}
            <Modal
                title={
                    <>
                        <div className="memory-title" style={{ fontSize: "24px" }}>
                            Memories
                        </div>
                        <Typography.Text
                            type="secondary"
                            style={{ fontSize: "14px", marginBottom: "32px", display: "block" }}
                        >
                            Add special instructions and preferences for Solver to remember and use for this repo
                        </Typography.Text>
                    </>
                }
                open={open}
                onCancel={() => {
                    if (editingKey) {
                        // Cancel any ongoing edits
                        handleCancel();
                    }
                    onClose();
                }}
                footer={null}
                width="90%"
                className="memory-store-modal"
            >
                <div style={{ display: "flex", alignItems: "center", gap: "16px", marginBottom: "16px" }}>
                    <Tabs
                        activeKey={activeTab}
                        onChange={(newTab) => {
                            if (editingKey) {
                                // Cancel any ongoing edits when switching tabs
                                handleCancel();
                            }
                            setActiveTab(newTab as MemoryVisibility);
                        }}
                        style={{ flex: 1, marginBottom: 0 }}
                    >
                        <Tabs.TabPane tab="Personal" key={MemoryVisibility.PERSONAL} />
                        <Tabs.TabPane
                            tab={
                                <Badge count={newRepoMemoriesCount} offset={[10, 0]}>
                                    <span>Repository</span>
                                </Badge>
                            }
                            key={MemoryVisibility.REPOSITORY}
                        />
                    </Tabs>
                    <div style={{ display: "flex", gap: "16px" }}>
                        <Input.Search
                            placeholder="Search memories"
                            allowClear
                            onChange={(e) => setSearchText(e.target.value)}
                            style={{ width: 250 }}
                        />
                        <Button type="primary" icon={<PlusOutlined />} onClick={handleAdd} disabled={editingKey !== ""}>
                            Add Memory
                        </Button>
                    </div>
                </div>
                <Form form={form} component={false}>
                    <Tabs activeKey={activeTab} tabBarStyle={{ display: "none" }}>
                        <Tabs.TabPane tab="Personal" key={MemoryVisibility.PERSONAL}>
                            <Table
                                pagination={false}
                                components={{
                                    body: {
                                        cell: EditableCell,
                                        row: ({ children, ...props }: CustomRowProps) => (
                                            <tr
                                                {...props}
                                                ref={
                                                    isEditing(
                                                        props["data-row-key"]
                                                            ? ({ id: props["data-row-key"] } as Memory)
                                                            : ({} as Memory)
                                                    )
                                                        ? editingRowRef
                                                        : null
                                                }
                                            >
                                                {children}
                                            </tr>
                                        ),
                                    },
                                }}
                                dataSource={filteredMemories}
                                columns={mergedColumns}
                                rowKey="id"
                                loading={loading}
                                onRow={(record) => ({
                                    style: {
                                        ...(record.enabled
                                            ? {}
                                            : {
                                                  backgroundColor: "rgba(0, 0, 0, 0.15)",
                                                  opacity: 0.65,
                                              }),
                                    },
                                })}
                                rowClassName={(record) => (!record.enabled ? "disabled-row" : "")}
                                locale={{
                                    emptyText: `No memories exist for ${
                                        activeRepo ? `${activeRepo.org}/${activeRepo.name}` : "this repo"
                                    }`,
                                }}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Repository" key={MemoryVisibility.REPOSITORY}>
                            <Table
                                pagination={false}
                                components={{
                                    body: {
                                        cell: EditableCell,
                                        row: ({ children, ...props }: CustomRowProps) => (
                                            <tr
                                                {...props}
                                                ref={
                                                    isEditing(
                                                        props["data-row-key"]
                                                            ? ({ id: props["data-row-key"] } as Memory)
                                                            : ({} as Memory)
                                                    )
                                                        ? editingRowRef
                                                        : null
                                                }
                                            >
                                                {children}
                                            </tr>
                                        ),
                                    },
                                }}
                                dataSource={filteredMemories}
                                columns={mergedColumns}
                                rowKey="id"
                                loading={loading}
                                sortDirections={["descend", "ascend"]}
                                onRow={(record) => ({
                                    style: {
                                        ...(record.enabled
                                            ? {}
                                            : {
                                                  backgroundColor: "rgba(0, 0, 0, 0.15)",
                                                  opacity: 0.65,
                                              }),
                                    },
                                })}
                                rowClassName={(record) => (!record.enabled ? "disabled-row" : "")}
                                locale={{
                                    emptyText: `No memories exist for ${
                                        activeRepo ? `${activeRepo.org}/${activeRepo.name}` : "this repo"
                                    }`,
                                }}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </Form>
            </Modal>
        </>
    );
};

export default MemoryStore;
