import React from "react";

import { Button } from "antd";
import { EditOutlined, FunctionOutlined } from "@ant-design/icons";

import { PromptSuggestion } from "../data/Repos";

import "./PromptSuggestionChips.scss";

interface PromptSuggestionChipsProps {
    promptSuggestions: PromptSuggestion[];
    onClick: (suggestion: string | null) => void;
    isDemo?: boolean;
}

const PromptSuggestionChips: React.FC<PromptSuggestionChipsProps> = ({
    promptSuggestions,
    onClick,
    isDemo = false,
}) => {
    const buildSuggestion = (promptSuggestion: PromptSuggestion, index: number) => {
        return (
            <Button key={index} className="prompt-suggestion" onClick={() => onClick(promptSuggestion.full_prompt)}>
                <FunctionOutlined />
                <div className="prompt-suggestion-text">{promptSuggestion.summary}</div>
            </Button>
        );
    };

    if (promptSuggestions.length === 0) {
        return null;
    }

    return (
        <div className="prompt-suggestions-container">
            {promptSuggestions.map((promptSuggestion, index) => buildSuggestion(promptSuggestion, index))}
            {isDemo && (
                <Button className="prompt-suggestion own-idea" onClick={() => onClick(null)}>
                    <EditOutlined />
                    <div className="prompt-suggestion-text">I have my own idea</div>
                </Button>
            )}
        </div>
    );
};

export default PromptSuggestionChips;
