import React from "react";
import { Badge, Button, Modal } from "antd";
import ReactMarkdown from "react-markdown";

import { Notification, NotificationSeverity } from "../data/SolverInterface";

interface NotificationModalProps {
    open: boolean;
    onClose: () => void;
    notifications: Notification[];
}

const notificationSeverityToColor = (severity: NotificationSeverity) => {
    switch (severity) {
        case NotificationSeverity.INFO:
            return "green";
        case NotificationSeverity.WARNING:
            return "yellow";
        case NotificationSeverity.ERROR:
            return "red";
    }
};

const NotificationModal: React.FC<NotificationModalProps> = ({ open, onClose, notifications }) => {
    const formattedDate = (timestamp: number) => {
        const date = new Date(timestamp * 1000);

        const intlFormat = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            weekday: "short",
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
            timeZoneName: "short",
        });

        return intlFormat.format(date);
    };

    const buildNotificationListItem = (notification: Notification) => {
        return (
            <li key={notification.id} className="notification-list-item">
                <label className="notification-list-item-label">
                    <Badge color={notificationSeverityToColor(notification.severity)} />
                    <small>{formattedDate(notification.timestamp)}</small>
                </label>
                <ReactMarkdown
                    components={{
                        a: ({ children, ...props }) => {
                            return (
                                <a href={props.href} target="_blank" rel="noopener">
                                    {children}
                                </a>
                            );
                        },
                    }}
                >
                    {notification.message}
                </ReactMarkdown>
            </li>
        );
    };

    return (
        <Modal
            open={open}
            onCancel={onClose}
            footer={
                <Button type="primary" onClick={onClose}>
                    Done
                </Button>
            }
            className="faq-modal notification-modal"
            width={"80%"}
            bodyStyle={{ maxHeight: "70vh", overflowY: "auto", marginTop: "20px" }}
        >
            <h3>Platform updates from Solver</h3>
            {notifications.length > 0 ? (
                <ul className="notification-list">
                    {notifications.map((notification) => buildNotificationListItem(notification))}
                </ul>
            ) : (
                "No current updates"
            )}
        </Modal>
    );
};

export default NotificationModal;
