import React, { useState } from "react";
import { Button, Progress, theme, Typography, Tooltip } from "antd";
import { InfoCircleOutlined, SettingOutlined } from "@ant-design/icons";

import { useAppMenuState } from "../../data/AppMenuState";
import { ExecutionContent } from "../../data/TurnEventContent";

import Terminal from "./Terminal";

import { ReactComponent as StopButton } from "../../images/stop_button.svg";
import "./Execution.scss";

interface ExecutionProps {
    executionContent: ExecutionContent;
    onInterrupt: () => Promise<boolean>;
}

const Execution: React.FC<ExecutionProps> = ({ executionContent, onInterrupt }) => {
    const { openExecutionSettings } = useAppMenuState();
    const { token } = theme.useToken();
    const [isInterrupting, setIsInterrupting] = useState(false);

    const handleInterrupt = async (e: React.MouseEvent) => {
        e.stopPropagation();
        if (isInterrupting) return;
        setIsInterrupting(true);
        try {
            await onInterrupt();
        } catch (error) {
            console.error("Failed to interrupt execution:", error);
        } finally {
            setIsInterrupting(false);
        }
    };

    const buildSetupState = () => {
        const setup = executionContent.setup;
        if (!setup || executionContent.completed || setup.completed) return null;

        if (setup.step === "image_pushing" || setup.step === "repo_pulling") {
            return (
                <div className="setup-state-container">
                    <Typography.Text>
                        <span>
                            {setup.step === "image_pushing"
                                ? "Waiting for the latest image to be available"
                                : "Getting the latest code"}
                        </span>
                        <span className="animated-ellipsis"></span>
                    </Typography.Text>
                </div>
            );
        }

        if (setup.step === "image_pulling" && setup.progress !== null) {
            return (
                <div className="setup-state-container">
                    <Typography.Text>Getting latest image</Typography.Text>
                    <Progress
                        percent={setup.progress}
                        showInfo={false}
                        strokeColor={token.colorPrimary}
                        status="active"
                    />
                </div>
            );
        }
    };

    const buildExecutionControls = () => {
        return (
            <div className="execution-controls">
                {executionContent.exec_enabled ? (
                    <>
                        <Tooltip
                            title="Immediately terminates Solver's current command execution. Useful for stopping processes that won't complete on their own (like npm start or dev servers). Solver will adapt its approach based on this interruption."
                            placement="top"
                        >
                            <InfoCircleOutlined style={{ color: "#999" }} />
                        </Tooltip>
                        <Button
                            className="message-cta"
                            style={{ display: "flex", alignItems: "center" }}
                            icon={<StopButton width={15} height={15} style={{ fill: "currentColor" }} />}
                            onClick={handleInterrupt}
                            disabled={isInterrupting || executionContent.completed}
                            loading={isInterrupting}
                        >
                            Interrupt Execution
                        </Button>
                    </>
                ) : (
                    <Button
                        icon={<SettingOutlined />}
                        onClick={(e) => {
                            e.stopPropagation();
                            openExecutionSettings();
                        }}
                        className="message-cta"
                    >
                        Configure Execution Settings
                    </Button>
                )}
            </div>
        );
    };

    return (
        <div className="execution-message-container">
            {buildSetupState()}
            <Terminal
                command={executionContent.command}
                stdout={executionContent.stdout}
                stderr={executionContent.stderr}
                exitCode={executionContent.exit_code}
                completed={executionContent.completed}
                error_msg={executionContent.error_msg}
            />
            {buildExecutionControls()}
        </div>
    );
};

export default Execution;
