import { InfoCircleOutlined, LoadingOutlined, WarningOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import {
    PlanningAnswer,
    useTechPlanData,
    useTechPlanOpenQuestionsCount,
    useTechPlanProblem,
} from "../../data/SolverProjects";
import "./PlanningView.scss";
import { TechPlan } from "./TechPlan";

const TECH_PLAN_OPEN_QUESTIONS_REMAIN = "open questions remain";

interface PlanningViewProps {
    isLoading?: boolean;
    onAnswersChange?: (answers: PlanningAnswer[]) => void;
}

const PlanningView: React.FC<PlanningViewProps> = ({ isLoading = false, onAnswersChange }) => {
    const techPlanData = useTechPlanData();
    const techPlanOpenQuestionsCount = useTechPlanOpenQuestionsCount();
    const techPlanProblem = useTechPlanProblem();
    const [answers, setAnswers] = useState<PlanningAnswer[]>([]);

    // Clear answers when planning data updates and notify parent
    useEffect(() => {
        if (techPlanData) {
            setAnswers([]);
            onAnswersChange?.([]);
        }
    }, [techPlanData, onAnswersChange]);

    // Notify parent component when answers change
    useEffect(() => {
        onAnswersChange?.(answers);
    }, [answers.length, onAnswersChange]);

    const buildTechPlanHeader = () => {
        if (isLoading) {
            return (
                <div className="planning-view-loading">
                    <LoadingOutlined />
                    Loading updates...
                </div>
            );
        } else if (techPlanProblem) {
            if (techPlanProblem === TECH_PLAN_OPEN_QUESTIONS_REMAIN) {
                let techPlanProblemText = "";
                if (techPlanOpenQuestionsCount !== undefined && techPlanOpenQuestionsCount > 0) {
                    techPlanProblemText = `${techPlanOpenQuestionsCount} open question${
                        techPlanOpenQuestionsCount > 1 ? "s" : ""
                    } remain in this tech plan. Resolve them to continue.`;
                } else {
                    techPlanProblemText = "Open questions remain in this tech plan. Resolve them to continue.";
                }

                return (
                    <div className="planning-view-problem">
                        <InfoCircleOutlined />
                        {techPlanProblemText}
                    </div>
                );
            } else {
                return (
                    <div className="planning-view-problem">
                        <WarningOutlined />
                        {techPlanProblem}
                    </div>
                );
            }
        }

        return null;
    };

    const buildTechPlan = () => {
        if (!techPlanData) return null;

        return (
            <TechPlan
                data={techPlanData}
                isLoading={isLoading}
                onQuestionAnswered={(taskId, questionIndex, question, answer, isUnanswering) => {
                    if (isUnanswering) {
                        setAnswers((prev) =>
                            prev.filter((a) => !(a.task_id === taskId && a.question_idx === questionIndex))
                        );
                    } else {
                        setAnswers((prev) => [
                            ...prev.filter((a) => !(a.task_id === taskId && a.question_idx === questionIndex)),
                            { task_id: taskId, question_idx: questionIndex, question, answer },
                        ]);
                    }
                }}
            />
        );
    };

    return (
        <>
            {buildTechPlanHeader()}
            {buildTechPlan()}
        </>
    );
};

export { PlanningView };
