import React, { useCallback, useState, useEffect, useMemo } from "react";
import { notification } from "antd";
import { isAxiosError } from "axios";
import { solverInterfaceApiAxios } from "./SolverInterfaceConstants";
import { navigateToProject, NavigationBehavior } from "./Navigation";
import {
    Session,
    sessionIsLoading,
    SessionStatus,
    useLoadSession,
    useSession,
    useSessionStatus,
    useTurns,
} from "../data/SolverSession";
import { useSolverInterfaceContext } from "./SolverInterface";
import {
    CreateProjectAndPlanRequest,
    evaluateProjectTaskSession,
    ExecutionGraph,
    ExecutionStatus,
    getProjectBase,
    getProjectExecution,
    getProjectTechPlan,
    GetTechPlanResponse,
    LoadingProjectState,
    PlanningAnswer,
    Project,
    ProjectContext,
    ProjectContextType,
    ProjectInfo,
    projectStubToProject,
} from "./SolverProjects";
import {
    SessionCreatedEvent,
    SessionEventBase,
    SessionStatusEvent,
    SolverInterfaceEvent,
    SolverInterfaceEventType,
    TraceEventType,
} from "./SolverInterfaceEvent";
import { SolverInterfaceEventObserverHandle } from "../hooks/useStreamConnection";
import { useExecutionGraphMapping } from "./useExecutionGraphMapping";

interface TimestampedTechPlanData extends GetTechPlanResponse {
    timestamp: number;
}

// TODO: might be more clear to control the timestamp in the ProjectProvider.
// We need to be careful to generate the timestamp before the /changes API call.
const fetchPlanningData = async (projectInfo: ProjectInfo): Promise<TimestampedTechPlanData> => {
    const techPlanResponse = await getProjectTechPlan(projectInfo);

    const timestamp = Math.floor(Date.now() / 1000);

    return {
        ...techPlanResponse,
        timestamp,
    };
};

export const ProjectProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const currentSession = useSession();
    const currentSessionStatus = useSessionStatus();
    const currentSessionTurns = useTurns();
    const loadSession = useLoadSession();

    const [project, setProject] = useState<Project | undefined>(undefined);
    const [executionGraph, setExecutionGraph] = useState<ExecutionGraph | undefined>(undefined);
    const [executionStatus, setExecutionStatus] = useState<ExecutionStatus | undefined>(undefined);
    const [planningSession, setPlanningSession] = useState<Session | undefined>(undefined);
    const [planningSessionStatus, setPlanningSessionStatus] = useState<SessionStatus | undefined>(undefined);
    const [timestampedTechPlanData, setTimestamptedTechPlanData] = useState<TimestampedTechPlanData | undefined>(
        undefined
    );
    const [loadingProjectState, setLoadingProjectState] = useState<LoadingProjectState>(LoadingProjectState.DONE);
    const [api, contextHolder] = notification.useNotification();
    const { currentUser, addSolverInterfaceEventObserver, removeSolverInterfaceEventObserver } =
        useSolverInterfaceContext();

    const { taskSessionsByTaskId, taskEvaluationsByTaskId, allTasksDone, anyTaskNeedsManualVerification } =
        useExecutionGraphMapping(executionGraph);

    useEffect(() => {
        if (currentSession && currentSession.session_id === project?.planning_session_id) {
            setPlanningSession(currentSession);
            setPlanningSessionStatus(currentSessionStatus);
        } else {
            setPlanningSession(undefined);
            setPlanningSessionStatus(undefined);
        }
    }, [project, currentSession, currentSessionStatus]);

    useEffect(() => {
        if (!project || !currentSessionTurns || !planningSession) {
            return;
        }

        const hasNewerChanges = currentSessionTurns.some((turn) =>
            turn.events.some((event) => {
                // Only for EDITs.
                // TODO: What is this and does it apply for create?
                if (event.event_type !== TraceEventType.EDIT) {
                    return false;
                }

                // If for some reason don't have a tech plan yet, we definitely want to fetch it.
                if (!timestampedTechPlanData) {
                    return true;
                }

                // If the event is newer than the last tech plan fetch, we need to fetch it.
                return event.created > timestampedTechPlanData.timestamp;
            })
        );

        if (hasNewerChanges) {
            fetchPlanningData(project.getInfo()).then(setTimestamptedTechPlanData);
        }
    }, [project, timestampedTechPlanData, planningSession, currentSessionTurns]);

    useEffect(() => {
        if (executionGraph) {
            setExecutionStatus(executionGraph.status);
        }
    }, [executionGraph]);

    // Listen for session events that should trigger execution graph updates
    useEffect(() => {
        if (!project) return;

        const handleSessionEvent = async (event: SessionEventBase) => {
            if (!executionGraph) return;

            // Check if this session is part of our project
            if (!event.project_id || project.project_id !== event.project_id) return;

            // Refresh the execution graph since a relevant session changed
            const updatedGraph = await getProjectExecution(project.getInfo());
            setExecutionGraph(updatedGraph);
        };

        const sessionCreatedHandle: SolverInterfaceEventObserverHandle = addSolverInterfaceEventObserver(
            SolverInterfaceEventType.SESSION_CREATED,
            (e: SolverInterfaceEvent) => handleSessionEvent(e as SessionCreatedEvent)
        );

        const sessionStatusHandle: SolverInterfaceEventObserverHandle = addSolverInterfaceEventObserver(
            SolverInterfaceEventType.SESSION_STATUS,
            (e: SolverInterfaceEvent) => handleSessionEvent(e as SessionStatusEvent)
        );

        const sessionUpdatedHandle: SolverInterfaceEventObserverHandle = addSolverInterfaceEventObserver(
            SolverInterfaceEventType.SESSION_UPDATED,
            (e: SolverInterfaceEvent) => handleSessionEvent(e as SessionStatusEvent)
        );

        return () => {
            removeSolverInterfaceEventObserver(sessionCreatedHandle);
            removeSolverInterfaceEventObserver(sessionStatusHandle);
            removeSolverInterfaceEventObserver(sessionUpdatedHandle);
        };
    }, [project, executionGraph]);

    const canModifyProject = useCallback(() => {
        if (!project) {
            return false;
        }
        return project.allowModification(currentUser?.id);
    }, [project, currentUser?.id]);

    const canPlan = useCallback(
        (nlText: string, answers: PlanningAnswer[]) => {
            if (!project || !planningSession) {
                return false;
            }
            return nlText || answers.length > 0;
        },
        [project, planningSession]
    );

    const techPlanOpenQuestionsCount = useMemo(() => {
        if (!timestampedTechPlanData?.plan) return undefined;
        return (
            timestampedTechPlanData.plan.open_questions.length +
            timestampedTechPlanData.plan.tasks.reduce((acc, task) => acc + task.open_questions.length, 0)
        );
    }, [timestampedTechPlanData]);

    const [canExecute, executionDisabledMessage] = useMemo(() => {
        if (!project) return [false, ""];

        // Need a tech plan.
        if (!timestampedTechPlanData || !timestampedTechPlanData.plan) return [false, ""];

        // Tech plan must have no open questions.
        if (techPlanOpenQuestionsCount) return [false, 'Answer open questions in the tech plan and click "Plan"'];
        // And be executable.
        if (!timestampedTechPlanData.is_executable) return [false, "Tech plan is not valid"];

        // Need a planning session...
        if (!planningSessionStatus) return [false, ""];

        // ...which is not pending/solving.
        if (sessionIsLoading(planningSessionStatus)) return [false, ""];

        // When there's an execution graph, no task can need manual verification.
        if (executionGraph && anyTaskNeedsManualVerification)
            return [false, "Some tasks need manual verification to continue execution"];
        // Can't execute on a finished project.
        if (executionGraph && allTasksDone) return [false, "Project is already finished"];
        // When there's an execution graph, it must be ready.
        if (executionGraph && executionStatus !== ExecutionStatus.READY) return [false, ""];

        return [true, ""];
    }, [
        project,
        timestampedTechPlanData,
        techPlanOpenQuestionsCount,
        planningSessionStatus,
        executionStatus,
        executionGraph,
        anyTaskNeedsManualVerification,
        allTasksDone,
    ]);

    const canCancelProjectExecution = useMemo(() => {
        return executionStatus === ExecutionStatus.RUNNING;
    }, [executionStatus]);

    const canDeleteProjectExecution = useMemo(() => {
        return executionStatus === ExecutionStatus.READY;
    }, [executionStatus]);

    const value: ProjectContextType = {
        project,
        techPlanData: timestampedTechPlanData?.plan,
        techPlanOpenQuestionsCount,
        techPlanProblem: timestampedTechPlanData?.problem,
        executionGraph,
        executionStatus,
        planningSession,
        planningSessionStatus,
        loadingProjectState,
        loadProject: useCallback(
            async (projectInfo: ProjectInfo | undefined, navigationBehavior: NavigationBehavior) => {
                if (!projectInfo) {
                    setProject(undefined);
                    navigateToProject(undefined, navigationBehavior);
                    return;
                }

                navigateToProject(projectInfo.project_id, navigationBehavior);

                setLoadingProjectState(LoadingProjectState.LOADING);

                try {
                    const [loadedProject, projectExecution] = await Promise.all([
                        getProjectBase(projectInfo),
                        getProjectExecution(projectInfo),
                    ]);

                    const planningSessionInfo = {
                        repo: projectInfo.repo,
                        org: projectInfo.org,
                        session_id: loadedProject.planning_session_id,
                    };

                    // We load the session so we can stream turn events and know
                    // when the planning session is done and when to fetch its
                    // tech plan.
                    const loadSessionResult = await loadSession(planningSessionInfo, NavigationBehavior.NONE);

                    setTimestamptedTechPlanData(await fetchPlanningData(loadedProject.getInfo()));

                    if (!loadSessionResult) {
                        setLoadingProjectState(LoadingProjectState.ERROR);
                    }

                    setProject(loadedProject);
                    setExecutionGraph(projectExecution);
                    setLoadingProjectState(LoadingProjectState.DONE);
                } catch (error) {
                    if (isAxiosError(error) && error.response?.status === 404) {
                        setLoadingProjectState(LoadingProjectState.NOT_FOUND);
                    } else {
                        setLoadingProjectState(LoadingProjectState.ERROR);
                    }
                }
            },
            []
        ),
        updateProject: useCallback(
            async (project: Project, planningSession: Session, executionGraph: ExecutionGraph) => {
                setProject(project);
                setPlanningSession(planningSession);
                setPlanningSessionStatus(planningSession.status);
                setExecutionGraph(executionGraph);
            },
            []
        ),
        createProjectAndPlan: useCallback(
            async (org: string, repo: string, request: CreateProjectAndPlanRequest): Promise<Project> => {
                if (!canPlan(request.nl_text, [])) {
                    throw new Error("No planning data provided");
                }
                try {
                    const response = await solverInterfaceApiAxios.post(`/repos/${org}/${repo}/projects`, request);
                    return projectStubToProject(response.data);
                } catch (error) {
                    if (isAxiosError(error)) {
                        api.error({
                            message: "Failed to create project and plan",
                            description: error.response?.data?.message || "An error occurred",
                            placement: "bottomRight",
                        });
                    }
                    throw error;
                }
            },
            [api, canPlan]
        ),
        evaluateTaskSession: useCallback(
            async (projectTaskId: string, sessionId: string) => {
                if (!project) {
                    throw new Error("No project loaded");
                }

                return await evaluateProjectTaskSession(project.getInfo(), projectTaskId, sessionId);
            },
            [project]
        ),
        canExecuteProject: canExecute,
        executionDisabledMessage,
        executeProject: useCallback(
            async (numSteps: number): Promise<boolean> => {
                // TODO: need to have a plan with no open questions.
                if (!project) {
                    throw new Error("No project loaded");
                }
                if (!canModifyProject()) {
                    throw new Error("You don't have permission to execute this project");
                }
                if (!canExecute) {
                    throw new Error("Project is not ready to execute");
                }

                setExecutionStatus(ExecutionStatus.SUBMITTING_EXECUTION);

                const projectInfo = project.getInfo();
                try {
                    await solverInterfaceApiAxios.post(
                        `/repos/${projectInfo.org}/${projectInfo.repo}/projects/${projectInfo.project_id}/execute`,
                        {
                            num_steps: numSteps,
                        }
                    );

                    return true;
                } catch (error) {
                    console.error("Failed to execute project", error);

                    setExecutionStatus(ExecutionStatus.READY);
                    return false;
                }
            },
            [project, canModifyProject, canExecute]
        ),
        canCancelProjectExecution,
        cancelProjectExecution: useCallback(async (): Promise<boolean> => {
            if (!project) {
                throw new Error("No project loaded");
            }
            if (!canModifyProject()) {
                throw new Error("You don't have permission to cancel this project's execution");
            }

            if (!canCancelProjectExecution) {
                throw new Error("Project execution is not running");
            }

            const projectInfo = project.getInfo();
            try {
                await solverInterfaceApiAxios.post(
                    `/repos/${projectInfo.org}/${projectInfo.repo}/projects/${projectInfo.project_id}/execution/cancel`
                );

                return true;
            } catch (error) {
                console.error("Failed to cancel project execution", error);

                return false;
            }
        }, [project, canModifyProject, canCancelProjectExecution]),
        canDeleteProjectExecution,
        deleteProjectExecution: useCallback(async (): Promise<boolean> => {
            if (!project) {
                throw new Error("No project loaded");
            }
            if (!canModifyProject()) {
                throw new Error("You don't have permission to delete this project's execution");
            }

            if (!canDeleteProjectExecution) {
                throw new Error("Project execution is not ready");
            }

            const projectInfo = project.getInfo();
            try {
                await solverInterfaceApiAxios.delete(
                    `/repos/${projectInfo.org}/${projectInfo.repo}/projects/${projectInfo.project_id}/execution`
                );

                return true;
            } catch (error) {
                console.error("Failed to delete project execution", error);

                return false;
            }
        }, [project, canModifyProject, canDeleteProjectExecution]),
    };

    return (
        <ProjectContext.Provider value={value}>
            {contextHolder}
            {children}
        </ProjectContext.Provider>
    );
};
